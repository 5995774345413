import axios from 'axios'

export default {
  getByMac: (mac) => axios.get(`/api/v2/cms/device/${mac}`),
  updateByMac: (mac, data) => axios.patch(`/api/v2/cms/device/${mac}`, data),
  removeDevice: (mac) => axios.delete(`/api/v2/cms/device/${mac}`),
  getAllDevices: (localisationId) => axios.get(`/api/v2/cms/device/all/${localisationId}`),
  sendAction: (mac, action, data) => axios.put(`/api/v1/bhs/device-management?mac=${mac}&action=${action}`, data),
  changeDevicePlace: (mac, newPlaceId) => axios.put(`/api/v2/cms/device/${mac}/place/${newPlaceId}`),
  getExternalDevices: (mac) => axios.get(`/api/v1/bhs/device/external/${mac}`),
  addExternalDevice: (mac, data) => axios.post(`/api/v1/bhs/device/external/${mac}`, data),
  updateExternalDevice: (mac, id, data) => axios.put(`/api/v1/bhs/device/external/${mac}?id=${id}`, data),
  deleteExternalDevice: (mac, id) => axios.delete(`/api/v1/bhs/device/external/${mac}?id=${id}`),
  getScreenshot: (mac) => axios.get(`/api/v2/bhs/cms/init/signage/${mac}/screenshot`)
}
