import axios from 'axios'

export default {
  getByQuery: (placeId, description, name, type, mediaType) => axios.get(`/api/v2/media/${placeId}/query?description=${description}&name=${name}&type=${type}&mediaType=${mediaType}`),
  getByFolder: (placeId, folderId, mediaType) => axios.get(`/api/v2/media/${placeId}/folder?folderId=${folderId}&mediaType=${mediaType}`),
  getUnsortedByFolder: (placeId, mediaType) => axios.get(`/api/v2/media/${placeId}/folder?mediaType=${mediaType}`),
  getById: id => axios.get(`/api/v2/media/${id}`),
  createVimeoMediaObject: (placeId, formData, config) => axios.post(`/api/v2/media/${placeId}/video`, formData, config),
  createMediaObject: (placeId, data, config) => axios.post(`/api/v2/media/${placeId}`, data, config),
  createMediaObjects: (placeId, data, config) => axios.post(`/api/v2/media/${placeId}/multiple`, data, config),
  updateImageMediaObject: (id, data) => axios.patch(`/api/v2/media/${id}`, data),
  updateMediaObjectDuration: (playlistId, mediaId, duration) => axios.put(`api/v2/media/playlist/${playlistId}/${mediaId}/duration/${duration}`),
  updateVimeoMediaObject: (id, data) => axios.patch(`/api/v2/media/video/${id}`, data),
  assignMediaToFolder: (mediumId, folderId) => axios.put(`/api/v2/media/folder/${mediumId}${folderId ? `?folderId=${folderId}` : ''}`),
  assignPlaylistToFolder: (playlistId, folderId) => axios.put(`/api/v2/media/playlist/folder/${playlistId}${folderId ? `?folderId=${folderId}` : ''}`),
  deleteMediaObject: id => axios.delete(`/api/v2/media/${id}`),
  deleteMediaObjects: data => axios.delete(`/api/v2/media/multiple`, { data }),
  deletePlayLists: data => axios.delete(`/api/v2/media/playlist/multiple`, { data }),
  getAllPlaylists: placeId => axios.get(`/api/v2/media/playlist/${placeId}`),
  getPlaylistById: playlistId => axios.get(`/api/v2/media/playlist/${playlistId}`),
  createPlayList: (placeId, data) => axios.post(`/api/v2/media/playlist/${placeId}`, data),
  updatePlayList: (playlistId, data) => axios.patch(`/api/v2/media/playlist/${playlistId}`, data),
  deletePlayList: playlistId => axios.delete(`/api/v2/media/playlist/${playlistId}`),
  approvePlayList: playlistId => axios.put(`api/v2/media/playlist/${playlistId}/approve`),
  updatePlayListContentOrder: (playlistId, medias) => axios.put(`/api/v2/media/playlist/content/${playlistId}`, medias),
  addContentToPlaylist: (playlistId, mediaId) => axios.put(`/api/v2/media/playlist/content/${playlistId}/${mediaId}`),
  deleteContentFromPlaylist: (playlistId, mediaId) => axios.delete(`/api/v2/media/playlist/content/${playlistId}/${mediaId}`)
}
