const createApp = [
  {
    name: 'CreateApp',
    path: '/create-app/:workspaceId',
    redirect: '/create-app/:workspaceId/choose',
    meta: {
      auth: true,
      permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_CREATE_EDIT']
    },
    component: () => import(/* webpackChunkName: "CreateAppIndex" */ '@/views/create-app/Index'),
    children: [
      {
        name: 'CreateAppChoose',
        path: 'choose',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_CREATE_EDIT']
        },
        component: () => import(/* webpackChunkName: "CreateAppChoose" */ '@/views/create-app/Choose')
      },
      {
        name: 'CreateAppHospitality',
        path: 'hospitality',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_CREATE_EDIT']
        },
        component: () => import(/* webpackChunkName: "CreateAppHospitality" */ '@/views/create-app/Hospitality')
      },
      {
        name: 'CreateAppTouristTouch',
        path: 'tourist-touch',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_CREATE_EDIT']
        },
        component: () => import(/* webpackChunkName: "CreateAppTouristTouch" */ '@/views/create-app/TouristTouch')
      },
      {
        name: 'CreateAppKeyCabinet',
        path: 'key-locker',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_CREATE_EDIT']
        },
        component: () => import(/* webpackChunkName: "CreateAppKeyCabinet" */ '@/views/create-app/KeyCabinet')
      },
      {
        name: 'CreateAppPayBox',
        path: 'paybox',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_CREATE_EDIT']
        },
        component: () => import(/* webpackChunkName: "CreateAppPayBox" */ '@/views/create-app/PayBox')
      },
      {
        name: 'CreateAppQsr',
        path: 'paybox',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_CREATE_EDIT']
        },
        component: () => import(/* webpackChunkName: "CreateAppQsr" */ '@/views/create-app/Qsr')
      }
    ]
  }
]

export default createApp
