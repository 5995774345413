const app = [
  {
    name: 'App',
    path: 'app',
    components: {
      default: () => import(/* webpackChunkName: "AppBtRouterView" */ '@/components/BtRouterView'),
      navigation: () => import(/* webpackChunkName: "AppNavigation" */ '@/views/app/Navigation')
    },
    children: [
      {
        name: 'AppDashboard',
        path: ':appId/dashboard',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_VIEW']
        },
        component: () => import(/* webpackChunkName: "AppDashboard" */ '@/views/app/Dashboard')
      },
      {
        path: ':appId/channels-manager',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_VIEW']
        },
        component: () => import(/* webpackChunkName: "AppChannelsManagerBtRouterView" */ '@/components/BtRouterView'),
        children: [
          {
            name: 'AppChannelsManager',
            path: '',
            meta: {
              auth: true,
              permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_VIEW']
            },
            component: () => import(/* webpackChunkName: "AppChannelsManager" */ '@/views/app/ChannelsManager')
          },
          {
            name: 'AppChannelsManagerEditList',
            path: ':listId',
            meta: {
              auth: true,
              permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_VIEW', 'ROLE_TV_APP_EDIT', 'ROLE_TV_APP_CREATE']
            },
            component: () => import(/* webpackChunkName: "AppChannelsManagerEditList" */ '@/views/app/ChannelsManagerItem')
          }
        ]
      },
      {
        name: 'AppPublications',
        path: ':appId/publications',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_VIEW', 'ROLE_TV_APP_PUBLICATION']
        },
        component: () => import(/* webpackChunkName: "AppPublications" */ '@/views/app/Publications')
      },
      {
        path: ':appId/rooms',
        component: () => import(/* webpackChunkName: "AppChannelsManagerBtRouterView" */ '@/components/BtRouterView'),
        children: [
          {
            name: 'AppRooms',
            path: '',
            meta: {
              auth: true,
              permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_VIEW', 'ROLE_TV_APP_ROOMS', 'ROLE_TV_APP_ROOMS']
            },
            component: () => import(/* webpackChunkName: "AppRooms" */ '@/views/app/Rooms')
          },
          {
            name: 'AppRoomSettings',
            path: ':number',
            meta: {
              auth: true,
              permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_VIEW', 'ROLE_TV_APP_ROOMS', 'ROLE_TV_APP_ROOMS']
            },
            component: () => import(/* webpackChunkName: "AppRoomSettings" */ '@/views/app/RoomSettings')
          }
        ]
      },
      {
        name: 'AppEntertainment',
        path: ':appId/entertainment',
        component: () => import(/* webpackChunkName: "AppEntertainment" */ '@/views/app/Entertainment'),
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_ENTERTAIMENT', 'ROLE_TV_APP_VIEW']
        }
      },
      {
        name: 'AppHospitalitySettings',
        path: ':appId/settings-hospitality',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_SETTINGS', 'ROLE_TV_APP_SETTINGS_GENERAL', 'ROLE_TV_APP_SETTINGS_APPEARANCE', 'ROLE_TV_APP_SETTINGS_TV', 'ROLE_TV_APP_VIEW', 'ROLE_TV_APP_EDIT', 'ROLE_TV_APP_CREATE']
        },
        component: () => import(/* webpackChunkName: "AppSettingsHospitality" */ '@/views/app/SettingsHospitality')
      },
      {
        name: 'AppToursitTouchSettings',
        path: ':appId/settings-tourist-touch',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_SETTINGS', 'ROLE_TV_APP_SETTINGS_GENERAL', 'ROLE_TV_APP_SETTINGS_APPEARANCE', 'ROLE_TV_APP_SETTINGS_TV', 'ROLE_TV_APP_VIEW', 'ROLE_TV_APP_EDIT', 'ROLE_TV_APP_CREATE']
        },
        component: () => import(/* webpackChunkName: "AppSettingsToursitTouch" */ '@/views/app/SettingsTouristTouch')
      },
      {
        name: 'AppKeyCabinetSettings',
        path: ':appId/settings-key-cabinet',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_SETTINGS', 'ROLE_TV_APP_SETTINGS_GENERAL', 'ROLE_TV_APP_SETTINGS_APPEARANCE', 'ROLE_TV_APP_SETTINGS_TV', 'ROLE_TV_APP_VIEW', 'ROLE_TV_APP_EDIT', 'ROLE_TV_APP_CREATE']
        },
        component: () => import(/* webpackChunkName: "AppSettingsKeyCabinet" */ '@/views/app/SettingsKeyCabinet')
      },
      {
        name: 'AppPayBoxSettings',
        path: ':appId/settings-paybox',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_SETTINGS', 'ROLE_TV_APP_SETTINGS_GENERAL', 'ROLE_TV_APP_SETTINGS_APPEARANCE', 'ROLE_TV_APP_SETTINGS_TV', 'ROLE_TV_APP_VIEW', 'ROLE_TV_APP_EDIT', 'ROLE_TV_APP_CREATE']
        },
        component: () => import(/* webpackChunkName: "AppSettingsPayBox" */ '@/views/app/SettingsPayBox')
      },
      {
        name: 'AppQsrSettings',
        path: ':appId/settings-paybox',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_SETTINGS', 'ROLE_TV_APP_SETTINGS_GENERAL', 'ROLE_TV_APP_SETTINGS_APPEARANCE', 'ROLE_TV_APP_SETTINGS_TV', 'ROLE_TV_APP_VIEW', 'ROLE_TV_APP_EDIT', 'ROLE_TV_APP_CREATE']
        },
        component: () => import(/* webpackChunkName: "AppSettingsQsr" */ '@/views/app/SettingsQsr')
      },
      {
        name: 'AppPayBoxFeedback',
        path: ':appId/feedback-paybox',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_SETTINGS', 'ROLE_TV_APP_SETTINGS_GENERAL', 'ROLE_TV_APP_SETTINGS_APPEARANCE', 'ROLE_TV_APP_SETTINGS_TV', 'ROLE_TV_APP_VIEW', 'ROLE_TV_APP_EDIT', 'ROLE_TV_APP_CREATE']
        },
        component: () => import(/* webpackChunkName: "AppSettingsFeedback" */ '@/views/app/FeedbackPayBox')
      },
      {
        name: 'AppInfoPages',
        path: ':appId/info-pages',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_INFO_PAGES', 'ROLE_TV_APP_VIEW']
        },
        component: () => import(/* webpackChunkName: "AppInfoPages" */ '@/views/app/InfoPages')
      },
      {
        name: 'AppOffers',
        path: ':appId/offers',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_FULL_MANAGEMENT', 'ROLE_TV_APP_INFO_PAGES', 'ROLE_TV_APP_VIEW']
        },
        component: () => import(/* webpackChunkName: "AppOffers" */ '@/views/app/Offers')
      }
    ]
  }
]

export default app
