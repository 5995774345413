import axios from 'axios'
import _ from 'lodash'

function stringifyWidgetsData(app) {
  delete app.id

  app.pages.forEach((page, pageIndex) => {
    delete page.id

    if (page.composition && page.composition.widgets && page.composition.widgets.length) {
      delete page.composition.id

      page.composition.widgets.forEach((widget, widgetIndex) => {
        delete widget.id

        if (app.pages[pageIndex].composition.widgets[widgetIndex].data) {
          app.pages[pageIndex].composition.widgets[widgetIndex].data = JSON.stringify(app.pages[pageIndex].composition.widgets[widgetIndex].data)
        }
      })
    }
  })

  return app
}

export default {
  getById: appId => axios.get(`/api/v1/client-app/${appId}`),
  getByAppId: appId => axios.get(`/api/v1/client-app/app/${appId}`),
  create: (tvAppId, data) => axios.post(`/api/v1/client-app/app/${tvAppId}`, stringifyWidgetsData(_.merge({}, data))),
  createDraft: (appId, data) => axios.put(`/api/v1/client-app/${appId}/edit`, stringifyWidgetsData(_.merge({}, data))),
  update: (appId, data) => axios.put(`/api/v1/client-app/${appId}`, stringifyWidgetsData(_.merge({}, data))),
  publish: appId => axios.put(`/api/v1/client-app/${appId}/publish`),
  delete: appId => axios.delete(`/api/v1/client-app/${appId}`),
  exportClientAppToHotelTvApp: (clientAppId, hotelTvAppId) => axios.put(`/api/v1/client-app/export/${clientAppId}?hotelTvAppId=${hotelTvAppId}`)
}
