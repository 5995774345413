import axios from 'axios'

export default {
  getLightModesForHotelTvApp: hotelTvAppId => axios.get(`/api/v2/bhs/light-modes/${hotelTvAppId}`),
  createLightMode: (hotelTvAppId, data) => axios.post(`/api/v2/bhs/light-modes/${hotelTvAppId}`, data),
  getLightModesForRoom: (hotelTvAppId, roomNumber) => axios.get(`/api/v2/bhs/light-modes/${hotelTvAppId}/room/${roomNumber}`),
  updateLightMode: (lightModeId, data) => axios.put(`/api/v2/bhs/light-modes/${lightModeId}`, data),
  deleteLightMode: lightModeId => axios.delete(`/api/v2/bhs/light-modes/${lightModeId}`),
  assignLightModeToRooms: (lightModeId, data) => axios.put(`/api/v2/bhs/light-modes/assign/${lightModeId}`, data),
  removeLightModeFromRooms: lightModeId => axios.put(`/api/v2/bhs/light-modes/remove/${lightModeId}`)
}
