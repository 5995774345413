import Vue from 'vue'

Vue.mixin({
  methods: {
    getImageUrl(image, size) {
      if (image) {
        if (!size || image.sizes.length === 0) return image.url
        else {
          const target = this._.find(image.sizes, { size })
          return target ? target.url : image.url
        }
      } else return
    }
  }
})
