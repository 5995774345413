import api from '@/api'

const state = {
  devices: [],
  currentDevice: JSON.parse(localStorage.getItem('currentDevice')) || null,
  showDisconnectDeviceModal: false,
  showRemoveDeviceModal: false,
  showExportDeviceModal: false,
  showDeviceIdModal: false,

  notifications: null
}

const mutations = {
  SET_DEVICES(state, payload) {
    state.devices = payload
  },

  SET_CURRENT_DEVICE(state, payload) {
    state.currentDevice = payload
    if (payload) localStorage.setItem('currentDevice', JSON.stringify(payload))
    else localStorage.removeItem('currentDevice')
  },

  SET_CURRENT_EXTERNAL_DEVICES(state, payload) {
    state.currentDevice.externalDevices = payload
  },

  TOGGLE_REMOVE_DEVICE_MODAL(state, payload) {
    state.showRemoveDeviceModal = payload
  },

  TOGGLE_EXPORT_DEVICE_MODAL(state, payload) {
    state.showExportDeviceModal = payload
  },

  TOGGLE_DISCONNECT_DEVICE_MODAL(state, payload) {
    state.showDisconnectDeviceModal = payload
  },

  TOGGLE_DEVICE_ID_MODAL(state, payload) {
    state.showDeviceIdModal = payload
  },

  TOGGLE_ADD_EXTERNAL_DEVICE_MODAL(state, payload) {
    state.showAddExternalDeviceModal = payload
  },

  TOGGLE_UPDATE_EXTERNAL_DEVICE_MODAL(state, payload) {
    state.showUpdateExternalDeviceModal = payload
  },

  TOGGLE_REMOVE_EXTERNAL_DEVICE_MODAL(state, payload) {
    state.showRemoveExternalDeviceModal = payload
  },

  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },

  SET_ENTERTAINMENT(state, { index, payload }) {
    state.currentDevice.entertainment[index].enabled = payload
  }
}

const actions = {
  fetchDevicesByPlaceId({ commit, rootState }) {
    const currentWorkspaceId = rootState.workspaces.currentWorkspace ? rootState.workspaces.currentWorkspace.id : null

    if (!currentWorkspaceId) return

    commit('appshell/startSectionLoading', null, { root: true })

    api.devices
      .getAllDevices(currentWorkspaceId)
      .then((response) => {
        commit('SET_DEVICES', response.data)
        commit('appshell/endSectionLoading', null, { root: true })

        console.log(
          response.data
            .filter((o) => o.tvAppName)
            .map((o) => `${o.tvAppName} ${o.name} ${o.mac} ${o.serial}`)
            .join('\n')
        )
      })
      .catch((error) => {
        console.log('fetchDevicesByPlaceId: ', error)
        commit('appshell/endSectionLoading', null, { root: true })
        commit('appshell/setDataFetchingState', true, { root: true })
      })
  },

  getCurrentDevice({ commit }, payload) {
    const mac = payload || state.currentDevice.mac

    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      api.devices
        .getByMac(mac)
        .then((response) => {
          const device = {
            externalDevices: [],
            ...response.data
          }
          console.log('getCurrentDevice - response: ', response.data)
          commit('SET_CURRENT_DEVICE', device)
          commit('appshell/endSectionLoading', null, { root: true })
          api.devices
            .getExternalDevices(mac)
            .then((response) => {
              commit('SET_CURRENT_EXTERNAL_DEVICES', response.data)
            })
            .catch((error) => {
              console.log('getExternalDevices: ', error)
              commit('SET_NOTIFICATIONS', { type: 'error', message: `Error while getting external devices...` })
            })
          resolve(response.data)
        })
        .catch((error) => {
          console.log('getCurrentDevice: ', error)
          commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
          reject()
        })
    })
  },

  updateDevice({ commit, dispatch }, payload) {
    const mac = payload.mac || state.currentDevice.mac

    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      api.devices
        .updateByMac(mac, payload.data)
        .then((response) => {
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: `Device has been updated!` })
          dispatch('getCurrentDevice')
          resolve()
        })
        .catch((error) => {
          commit('appshell/endSectionLoading', null, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'error', message: `Device has not been updated!` })
          reject()
        })
    })
  },

  sendAction({ commit, dispatch, state }, payload) {
    const { skipNotification } = payload
    api.devices.sendAction(payload.mac || state.currentDevice.mac, payload.type, payload.params).then(
      (response) => {
        if (!skipNotification) commit('SET_NOTIFICATIONS', { type: 'success', message: `Action has beed send!` })
        setTimeout(() => {
          if (payload.mac) {
            dispatch('fetchDevicesByPlaceId')
          } else {
            dispatch('getCurrentDevice')
          }
        }, 2000)
      },
      ({ response }) => {
        if (!skipNotification) commit('SET_NOTIFICATIONS', { type: 'error', message: response.data.message })
      }
    )
  },

  sendActionByMac({ commit, dispatch, state }, payload) {
    console.log(payload)
    return new Promise((resolve, reject) => {
      api.devices.sendAction(payload.mac, payload.action).then(
        (response) => {
          resolve()
          commit('SET_NOTIFICATIONS', { type: 'success', message: `Action has beed send!` })
        },
        ({ response }) => {
          reject()
          commit('SET_NOTIFICATIONS', { type: 'error', message: response.data.message })
        }
      )
    })
  },

  // addDevice ({ commit, rootState }, payload) {
  //   const currentWorkspaceId = rootState.workspaces.currentWorkspace ? rootState.workspaces.currentWorkspace.id : null

  //   if (!payload || !currentWorkspaceId) {
  //     commit('SET_NOTIFICATIONS', { type: 'error', message: `The new app has not been added!` })
  //     return
  //   }

  //   api.devices
  //     .createDevice(currentWorkspaceId, payload)
  //     .then(response => {
  //       console.log('addDevice: ', response.data)
  //       commit('SET_NOTIFICATIONS', { type: 'success', message: `The new app has been added!` })
  //     })
  //     .catch(error => {
  //       console.log('addDevice: ', error)
  //       commit('SET_NOTIFICATIONS', { type: 'error', message: `The new app has not been added!` })
  //     })
  // },

  // renameDevice ({ commit, dispatch }, payload) {
  //   if (!payload) return

  //   if (payload.type === 'HOTEL_TV') {
  //     api.devices
  //       .updateHotelDevice(payload.id, payload.app)
  //       .then(response => {
  //         commit('SET_CURRENT_DEVICE', response.data)
  //         commit('SET_NOTIFICATIONS', { type: 'success', message: `The new app has been renamed!` })
  //       })
  //       .catch(error => {
  //         console.log('removeDevice: ', error)
  //         commit('SET_NOTIFICATIONS', { type: 'error', message: `The new app has not been renamed!` })
  //       })
  //   } else {
  //     api.devices
  //       .updateDsDevice(payload.id, payload.app)
  //       .then(response => {
  //         commit('SET_CURRENT_DEVICE', response.data)
  //         commit('SET_NOTIFICATIONS', { type: 'success', message: `The new app has been renamed!` })
  //       })
  //       .catch(error => {
  //         console.log('removeDevice: ', error)
  //         commit('SET_NOTIFICATIONS', { type: 'error', message: `The new app has not been renamed!` })
  //       })
  //   }
  // },

  removeDevice({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload) reject()

      api.devices
        .removeDevice(payload)
        .then(() => {
          commit('TOGGLE_REMOVE_DEVICE_MODAL', false)
          commit('SET_CURRENT_DEVICE', null)
          commit('SET_NOTIFICATIONS', { type: 'success', message: `The device has been deleted!` })
          resolve()
        })
        .catch((error) => {
          console.log('removeDevice: ', error)
          commit('SET_NOTIFICATIONS', { type: 'error', message: `The device has not been deleted!` })
          reject()
        })
    })
  },

  disconnectDeviceFromRoom({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload) reject()

      api.rooms
        .removeDeviceFromRoom(payload)
        .then((response) => {
          commit('SET_NOTIFICATIONS', { type: 'success', message: `Device has been disconnected!` })
          resolve()
        })
        .catch(() => {
          commit('SET_NOTIFICATIONS', { type: 'success', message: `Device has not disconnected!` })
          reject()
        })
    })
  },

  // updateDevice ({ commit, state }, payload) {
  //   if (!payload) return

  //   api.devices.updateHotelDevice(state.currentDevice.id, payload).then((response) => {
  //     commit('SET_CURRENT_DEVICE', response.data)
  //     commit('SET_NOTIFICATIONS', { type: 'success', message: `Device settings updated!` })
  //   }).catch((error) => {
  //     commit('SET_NOTIFICATIONS', { type: 'error', message: error.response.data.message })
  //   })
  // },

  exportDevice({ commit, dispatch }, payload) {
    if (!payload) return

    commit('TOGGLE_EXPORT_DEVICE_MODAL', false)

    api.devices
      .changeDevicePlace(payload.mac, payload.newPlaceId)
      .then(() => {
        commit('SET_NOTIFICATIONS', { type: 'success', message: `The device has been exported!` })
      })
      .catch((error) => {
        console.log('exportDevice: ')
        commit('SET_NOTIFICATIONS', { type: 'error', message: error.response?.data?.message })
      })
  },

  updateDeviceId({ commit, dispatch }, payload) {
    if (!payload) return

    commit('TOGGLE_DEVICE_ID_MODAL', false)

    api.devices
      .updateByMac(payload.mac, {
        devicesIdWithinRoom: payload.id
      })
      .then(() => {
        dispatch('getCurrentDevice')
        commit('SET_NOTIFICATIONS', { type: 'success', message: `The device ID has been update!` })
      })
      .catch((error) => {
        console.log('exportDevice: ', error)
        commit('SET_NOTIFICATIONS', { type: 'error', message: `The device ID has not been update!` })
      })
  },

  addExternalDevice({ state, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload) reject()

      api.devices
        .addExternalDevice(state.currentDevice.mac, payload)
        .then(() => {
          commit('SET_NOTIFICATIONS', { type: 'success', message: `External device has been added.` })
          dispatch('getCurrentDevice')
          resolve()
        })
        .catch((error) => {
          console.log('exportDevice: ', error)
          commit('SET_NOTIFICATIONS', { type: 'error', message: `The external device has not been added!` })
        })
    })
  },

  updateExternalDevice({ state, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload) reject()

      api.devices
        .updateExternalDevice(state.currentDevice.mac, payload.id, payload.device)
        .then(() => {
          commit('SET_NOTIFICATIONS', { type: 'success', message: `External device has been updated.` })
          dispatch('getCurrentDevice')
          resolve()
        })
        .catch((error) => {
          console.log('updateExternalDevice: ', error)
          commit('SET_NOTIFICATIONS', { type: 'error', message: `The external device has not been updated!` })
        })
    })
  },

  deleteExternalDevice({ state, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload) reject()

      api.devices
        .deleteExternalDevice(state.currentDevice.mac, payload)
        .then(() => {
          commit('SET_NOTIFICATIONS', { type: 'success', message: `External device has been removed.` })
          dispatch('getCurrentDevice')
          resolve()
        })
        .catch((error) => {
          console.log('deleteExternalDevice: ', error)
          commit('SET_NOTIFICATIONS', { type: 'error', message: `The external device has not been removed!` })
        })
    })
  }
}

const getters = {
  devices(state) {
    return state.devices
  },
  currentDevice(state) {
    return state.currentDevice
  },
  showDeviceIdModal(state) {
    return state.showDeviceIdModal
  },
  showRemoveDeviceModal(state) {
    return state.showRemoveDeviceModal
  },
  showExportDeviceModal(state) {
    return state.showExportDeviceModal
  },
  showDisconnectDeviceModal(state) {
    return state.showDisconnectDeviceModal
  },
  notifications(state) {
    return state.notifications
  }
}

export default { namespaced: true, state, getters, actions, mutations }
