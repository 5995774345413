import axios from 'axios'

function stringifyWidgetsData(app) {
  delete app.id

  app.pages.forEach((page, pageIndex) => {
    delete page.id

    if (page.composition && page.composition.widgets && page.composition.widgets.length) {
      delete page.composition.id

      page.composition.widgets.forEach((widget, widgetIndex) => {
        delete widget.id

        if (app.pages[pageIndex].composition.widgets[widgetIndex].data) {
          app.pages[pageIndex].composition.widgets[widgetIndex].data = JSON.stringify(app.pages[pageIndex].composition.widgets[widgetIndex].data)
        }
      })
    }
  })

  return app
}

export default {
  getAppById: (appId) => axios.get(`/api/v1/tv-app/${appId}`),
  deleteApp: (appId) => axios.delete(`/api/v1/tv-app/${appId}`),
  archiveApp: (appId) => axios.put(`/api/v1/tv-app/${appId}/archive`),
  editTvApp: (appId) => axios.put(`/api/v1/tv-app/${appId}/edit`),
  createTvApp: (placeId, data) => axios.post(`/api/v1/tv-app/${placeId}`, data),
  getAppsInPlace: (placeId) => axios.get(`/api/v1/tv-app/all/${placeId}`),
  getDefaultAppsInPlace: (placeId) => axios.get(`/api/v1/tv-app/default/${placeId}`),
  getTvAppById: (appId) => axios.get(`/api/v1/tv-app/${appId}`),
  updateTvApp: (appId, data) => axios.patch(`/api/v1/tv-app/${appId}`, data),
  updateTvAppMenuContent: (appId, type, data) => axios.patch(`/api/v1/menu-content/${appId}?type=${type}`, data),
  updateTvAppEntertainment: (appId, data) => axios.put(`/api/v1/tv-app/${appId}/entertainment`, data),
  updateTvAppEntertainmentById: (appId, entertainmentId, data) => axios.put(`/api/v1/tv-app/${appId}/entertainment/${entertainmentId}`, data),
  updateTvAppName: (appId, name) => axios.patch(`/api/v1/tv-app/name/${appId}?name=${name}`),
  createTvAppDraft: (appId, data) => axios.put(`/api/v1/tv-app/${appId}/edit`, data),
  updateHotelTvApp: (appId, data) => axios.patch(`/api/v1/tv-app/${appId}`, data),
  publishTvApp: (appId) => axios.put(`/api/v1/tv-app/${appId}/publish`),
  getInfoPages: (appId) => axios.get(`/api/v1/client-app/app/${appId}`),
  getDevicesForApp: (appId) => axios.get(`/api/v1/tv-app/device-management/${appId}/available`),
  getDevicesWithApp: (appId) => axios.get(`/api/v1/tv-app/device-management/${appId}`),
  getFeedback: (appId) => axios.get(`/api/v1/tv-app/${appId}/survey/answers`),
  removeDeviceFromApp: (appId, mac) => axios.delete(`/api/v1/tv-app/device-management/${appId}/${mac}`),
  exportApp: (appId, newPlaceId) => axios.put(`/api/v1/tv-app/${appId}/export/${newPlaceId}`),
  getAppsWithDevices: (placeId, appType, status) => axios.get(`/api/v1/tv-app/all/${placeId}/devices?appType=${appType}&status=${status}`),
  getHub: (appId) => axios.get(`/api/v1/tv-app/${appId}/hub/`),
  deleteHub: (appId, hubId) => axios.delete(`/api/v1/tv-app/${appId}/hub/${hubId}/`),
  createHub: (appId, data) => axios.post(`/api/v1/tv-app/${appId}/hub/`, data),
  reorderHub: (appId, data) => axios.put(`/api/v1/tv-app/${appId}/hub/order`, data),
  updateHub: (appId, hubId, data) => axios.put(`/api/v1/tv-app/${appId}/hub/${hubId}/`, data)
}
