import { items } from './items'
import { playlists } from './playlists'
import { folders } from './folders'

const state = {
  ...items.state,
  ...playlists.state,
  ...folders.state,

  tabs: [
    { name: 'Images', label: 'Images' },
    { name: 'Videos', label: 'Videos' }
  ],
  sortType: 'Newest',
  sortTypes: ['Newest', 'Oldest', 'A-Z', 'Z-A'],
  dragOver: false,
  dragOverMedium: false,
  draggingStartMedium: null,
  draggingEnterMedium: null,
  selectedMedia: [],
  selectable: false,
  multiselectable: true,
  selectableMediaType: 'IMAGE',
  showCloseEditMediumModal: false,
  showEditMediumModal: false,
  notifications: null,
  itemToEdit: null
}

const mutations = {
  ...items.mutations,
  ...playlists.mutations,
  ...folders.mutations,

  /**
   * Assign selected media to the selectedMedia array
   */
  SET_SELECTED_MEDIA(state) {
    state.selectedMedia = state.selectedItems
  },

  /**
   * Change media library mode
   *
   * @param {Boolean} payload
   */
  SET_SELECTABLE_MODE(state, payload) {
    state.selectable = payload
  },

  /**
   * Change media library mode
   *
   * @param {Boolean} payload
   */
  SET_MULTISELECTABLE_MODE(state, payload) {
    state.multiselectable = payload
  },

  /**
   * Set selectableMediaType
   *
   * @param {String} payload
   */
  SET_SELECTABLE_MEDIA_TYPE(state, payload) {
    state.selectableMediaType = payload
  },

  /**
   * Clear selectedMedia
   *
   */
  CLEAR_SELECTED_MEDIA(state) {
    state.selectedMedia = []
    state.selectedItems = []
    state.itemToEdit = null
    state.showPlaylistEditor = false
  },

  /**
   * Set sort type o default value
   *
   * @param {Object} payload
   */
  SET_SORT_TYPE(state, payload) {
    state.sortType = payload
  },

  /**
   * Set drag over state on the medium state
   *
   * @param {Boolean} payload
   */
  SET_DRAGOVER(state, payload) {
    state.dragOver = payload
  },

  /**
   * Set drag over state on the medium state if you dragg a medium not a file from the PC
   *
   */
  SET_DRAGOVER_UPLOAD(state) {
    if (!state.draggingStartMedium) state.dragOver = true
  },

  /**
   * Set dragstart values
   *
   * @param {Object} payload
   */
  SET_DRAGSTART_MEDIUM(state, payload) {
    if (state.dragOver) return
    state.dragOverMedium = payload.value
    state.draggingStartMedium = payload.event
  },

  /**
   * Set dragenter values
   *
   * @param {Object} payload
   */
  SET_DRAGENTER_MEDIUM(state, payload) {
    if (state.dragOver) return
    state.dragOverMedium = payload.value
    state.draggingEnterMedium = payload.event
  },

  /**
   * Clear all of the drag states
   *
   */
  CLEAR_DRAG_MEDIUM_STUFF(state) {
    state.dragOver = false
    state.dragOverMedium = false
    state.draggingStartMedium = null
    state.draggingEnterMedium = null
  },

  /**
   * Clear all of the drag states
   *
   */
  CLEAR_DRAG_ENTER_MEDIUM(state) {
    state.dragOver = false
    state.dragOverMedium = false
    state.draggingEnterMedium = null
  },

  /**
   * Set showEditMediumModal state
   *
   * @param {Boolean} payload
   */
  TOGGLE_EDIT_MEDIUM_MODAL(state, payload) {
    state.showEditMediumModal = payload
  },

  /**
   * Set showCloseEditMediumModal state
   *
   * @param {Boolean} payload
   */
  TOGGLE_CLOSE_EDIT_MEDIUM_MODAL(state, payload) {
    state.showCloseEditMediumModal = payload
  },

  /**
   * Set notification
   *
   * @param {Object} payload
   * type - error or success
   * message
   */
  SET_NOTIFICATIONS(state, payload) {
    if (!payload || typeof payload !== 'object' || !payload.hasOwnProperty('type') || !payload.hasOwnProperty('message')) return
    state.notifications = payload
  },

  SET_ITEM_TO_EDIT(state, payload) {
    state.itemToEdit = payload
  }
}

const actions = {
  ...items.actions,
  ...playlists.actions,
  ...folders.actions,

  /**
   * Remove selected media.
   *
   * @param {Number} payload
   */
  removeMedia({ dispatch }, payload) {
    if (!payload) return

    const selectedItems = payload.filter(obj => obj.mediaType !== 'PLAYLIST')
    const selectedPlaylists = payload.filter(obj => obj.mediaType === 'PLAYLIST')

    if (selectedItems.length) dispatch('removeItems', selectedItems)
    if (selectedPlaylists.length) dispatch('removePlaylists', selectedPlaylists)
  }
}

const getters = {
  ...items.getters,
  ...playlists.getters,
  ...folders.getters,

  tabs: state => state.tabs,
  sortType: state => state.sortType,
  sortTypes: state => state.sortTypes,
  dragOver: state => state.dragOver,
  dragOverMedium: state => state.dragOverMedium,
  draggingStartMedium: state => state.draggingStartMedium,
  draggingEnterMedium: state => state.draggingEnterMedium,
  selectedMedia: state => state.selectedMedia,
  selectable: state => state.selectable,
  multiselectable: state => state.multiselectable,
  selectableMediaType: state => state.selectableMediaType,
  showEditMediumModal: state => state.showEditMediumModal,
  showCloseEditMediumModal: state => state.showCloseEditMediumModal,
  notifications: state => state.notifications,
  itemToEdit: state => state.itemToEdit
}

export default { namespaced: true, state, mutations, actions, getters }
