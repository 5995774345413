import axios from 'axios'

export default {
  assignNewUserToPlacesAndDevices: data => axios.post('/api/v1/role-in-place/assign-new-user', data),
  assignExistingUserToPlaces: data => axios.post('/api/v1/role-in-place/assign', data),
  getAvailableAuthorities: data => axios.put(`/api/v1/role-in-place/authority/`, data),
  getUsersInPlace: placeId => axios.get(`/api/v1/role-in-place/${placeId}/users`),
  getUsersInMyPlaces: () => axios.get('/api/v1/role-in-place/users'),
  assignUserAuthorities: data => axios.patch(`/api/v1/role-in-place/authority/`, data),
  getUserWorkspaces: username => axios.get(`/api/v1/place-structure/user/${username}`),
  blockUserInPlace: (placeId, username, data) => axios.put(`/api/v1/role-in-place/${placeId}/users/${username}`, data),
  removeUserFromPlace: (placeId, username) => axios.delete(`/api/v1/role-in-place/${placeId}/users/${username}`),
  activateUserAccount: (username, activationCode, password) => axios.get(`/api/v1/user/account/${username}/activate/${activationCode}?newPassword=${password}`)
}
