<template lang="pug">
.bt-select(
  :class="classes"
)
  .bt-select__toggle(
    :class="{ 'bt-select__toggle--large' : size === 'large' }"
    @click.prevent.stop="toggle"
    v-click-outside="close"
  )
    .bt-select__toggle__value {{ computedLabel }}
    svg.bt-select__toggle__icon(v-html="require('@fortawesome/fontawesome-free/svgs/solid/chevron-down.svg')")
  .bt-select__dropdown(:class="{ 'bt-select__dropdown--large' : size === 'large' }")
    slide-up-down(
      :active="isOpen"
      :duration="0"
      @open-start="isTransitioning = true"
      @open-end="isTransitioning = false"
      @close-start="isTransitioning = true"
      @close-end="isTransitioning = false"
    )
      ul.bt-select__dropdown__list
        bt-select-dropdown-item(
          v-for="(option, index) in computedOptions"
          :key="index"
          :option="option"
          :optionAdapter="optionAdapter"
          @select="(item) => change(item)"
        ) {{ option.label }}
      template(v-if="action")
        hr.bt-select__dropdown__sep
        ul.bt-select__dropdown__list
          li.bt-select__dropdown__list__item(
            @click.stop="$emit('action')"
          ) {{ action }}
</template>

<script>
import SlideUpDown from 'vue-slide-up-down'

export default {
  name: 'BtSelect',
  components: {
    SlideUpDown,
    BtSelectDropdownItem: () => import('./partials/BtSelectDropdownItem')
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    /**
     * Select value
     */
    value: {
      type: [String, Number, Object],
      required: false,
      default: null
    },
    /**
     * Options list
     */
    options: {
      type: Array,
      default: () => [],
      required: true
    },
    /**
     * Options adapter which transform the options list and value
     */
    optionAdapter: {
      type: Function,
      default: (item) => ({
        id: item,
        label: item,
        item
      })
    },
    /**
     * Optional action
     */
    action: {
      type: [String, Boolean],
      default: false
    },
    /**
     * Set to true if inline
     */
    inline: {
      type: Boolean,
      default: false
    },
    /**
     * Set to true to remove border
     */
    white: {
      type: Boolean
    },
    /**
     * Set to true to make it strrrrong
     */
    strong: {
      type: Boolean
    },
    /**
     * Set to true to make it grey
     */
    grey: {
      type: Boolean
    },
    /**
     * Use it to change size of the componenet
     */
    size: {
      type: String,
      default: 'medium',
      validate: (val) => ['medium', 'large'].indexOf(val) > -1
    },
    /**
     * Use it to change placeholder
     */
    placeholder: {
      type: String,
      default: 'Select...'
    },
    /**
     * Use it to disable selection
     */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      isTransitioning: false
    }
  },
  computed: {
    classes() {
      return {
        'bt-select--disabled': this.disabled,
        'bt-select--inline': this.inline,
        'bt-select--white': this.white,
        'bt-select--strong': this.strong,
        'bt-select--grey': this.grey,
        'bt-select--open': this.isOpen,
        'bt-select--transitioning': this.isTransitioning
      }
    },
    computedOptions() {
      return this.options.map((x) => this.optionAdapter(x))
    },
    computedLabel() {
      let result

      if (typeof this.value === 'object') result = this.options.find((item) => this._.isEqual(this.optionAdapter(item).item, this.value))
      else result = this.options.find((item) => this.optionAdapter(item).item === this.value)

      return result ? this.optionAdapter(result).label : this.placeholder
    }
  },
  created() {},
  methods: {
    toggle() {
      if (!this.disabled) this.isOpen = !this.isOpen
    },
    close() {
      this.isOpen = false
    },
    change(option) {
      this.$emit('change', this.computedOptions.find((x) => x.id === option.id).item)
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss">
.bt-select {
  $b: &;

  position: relative;
  display: block;
  font-size: 1rem;
  text-align: left;
  padding: 1px;

  &__toggle {
    position: relative;
    padding: 1rem;
    border-radius: $radius;
    z-index: 3;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: $control-height;
    background: linear-gradient(to bottom, $color--white 75%, rgba($color--white, 0.5));

    &--large {
      padding: 1rem 1.5rem;
      height: 4.2rem;
    }

    &__value {
      flex: 1 1 auto;
    }

    &__icon {
      width: 0.65rem;
      height: 1rem;
      fill: $color--primary;
      @include transition--primary;
    }

    &:hover,
    &:focus {
      &__icon {
        fill: $color--primary;
      }
    }
  }

  &#{$b}--white {
    #{$b}__dropdown {
      border-color: transparent;
    }

    &#{$b}--open {
      #{$b}__dropdown {
        box-shadow: $shadow--subtle;
      }
    }
  }

  &#{$b}--grey {
    #{$b}__dropdown {
      background-color: $color--grey--extra-light;
      border-color: transparent;
    }
  }

  &#{$b}--strong {
    @include font--global--strong;
  }

  &__dropdown {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: $radius;
    z-index: 2;
    padding: $control-height 0.5rem 0;
    background-color: $color--white;
    border: 1px solid $color--line;
    @include transition--primary;
    max-height: 400px;
    overflow: auto;

    &--large {
      padding: 4rem 0.5rem 0;
    }

    &__list {
      position: relative;
      @include reset;
      list-style: none;

      #{$b}__dropdown__list {
        padding-bottom: 0 !important;
        padding-left: $rhythm;

        &:first-child:before {
          content: none;
          display: none;
        }
      }

      &:last-child {
        padding-bottom: 0.5rem;
      }

      &__item {
        cursor: pointer;
        @include transition--primary;
      }

      &__button {
        @include reset;
        text-align: left;
        padding: 0.4rem 0.5rem;

        &:hover,
        &:focus {
          color: $color--primary;
        }
      }
    }

    &__sep {
      @include reset;
      border-top: 1px solid $color--line;
      margin: 0.5rem 0;
    }
  }

  &--inline {
    display: inline-block;
    width: 180px;
  }

  &--disabled {
    opacity: 0.5;

    #{$b}__toggle {
      cursor: not-allowed;
    }
  }

  &--open {
    /* &--transitioning { */
    z-index: 200;
  }
}
</style>
