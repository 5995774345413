import VeeValidate, { Validator } from 'vee-validate'
import PhoneNumber from 'awesome-phonenumber'
import Vue from 'vue'

Validator.extend('phone', {
  getMessage: field => `${field} is not a valid phone number`,
  validate(value) {
    return new Promise(resolve => {
      const phone = new PhoneNumber(value)
      resolve({ valid: phone.isValid() })
    })
  }
})

Vue.use(VeeValidate)
