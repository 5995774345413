const region = [
  {
    name: 'Region',
    path: 'region',
    redirect: { name: 'Location' },
    meta: {
      auth: true,
      permission: []
    },
    components: {
      default: () => import(/* webpackChunkName: "RegionBtRouterView" */ '@/components/BtRouterView'),
      navigation: () => import(/* webpackChunkName: "RegionNavigation" */ '@/views/region/Navigation')
    },
    children: [
      {
        name: 'RegionLocation',
        path: ':appId/location',
        meta: {
          auth: true,
          permission: []
        },
        component: () => import(/* webpackChunkName: "RegionLocation" */ '@/views/region/Location')
      },
      {
        name: 'RegionWeather',
        path: ':appId/weather',
        meta: {
          auth: true,
          permission: []
        },
        component: () => import(/* webpackChunkName: "RegionWeather" */ '@/views/region/Weather')
      },
      {
        name: 'RegionWebcams',
        path: ':appId/webcams',
        meta: {
          auth: true,
          permission: []
        },
        component: () => import(/* webpackChunkName: "RegionWebcams" */ '@/views/region/Webcams')
      },
      {
        name: 'RegionRailways',
        path: ':appId/railways',
        meta: {
          auth: true,
          permission: []
        },
        component: () => import(/* webpackChunkName: "RegionRailways" */ '@/views/region/Railways')
      }
    ]
  }
]

export default region
