import _ from 'lodash'

// State
const state = {
  viewportWidth: 1920,
  viewportHeight: 1080,
  viewportScale: 1,
  lang: 'en',
  app: {
    name: {},
    views: [
      {
        name: {}
      },
      {
        name: {}
      }
    ]
  },
  showOverlay: false
}

// Gettetrs
const getters = {
  getViewportWidth: state => state.viewportWidth,
  getViewportHeight: state => state.viewportHeight,
  getViewportScale: state => state.viewportScale,
  getApp: state => state.app,
  getAppName: state => state.app.name[state.lang],
  getLang: state => state.lang,
  getShowOverlay: state => state.showOverlay
}

// Actions
const actions = {
  // ...
}

// Mutations
const mutations = {
  SET_VIEWPORT_WIDTH: (state, width) => {
    state.viewportWidth = width
  },
  SET_VIEWPORT_HEIGHT: (state, height) => {
    state.viewportHeight = height
  },
  SET_VIEWPORT_SCALE: (state, scale) => {
    state.viewportScale = scale
  },
  SET_APP: (state, value) => {
    state.app = value
  },
  SET_NAME: (state, payload) => {
    state.app.name[payload.lang] = payload.value
  },
  SET_LANG: (state, value) => {
    state.lang = value
  },
  ADD_VIEW(state, view) {
    view._ref = Math.random()

    state.app.views.push(_.merge({}, { id: undefined, ...view }))
  },
  REMOVE_VIEW(state, view) {
    const index = state.app.views.findIndex(item => item === view)

    state.app.views.splice(index, 1)
  },
  SET_VIEW_NAME: (state, { index, locale }) => {
    state.app.views[index].name[locale.lang] = locale.value
  },
  ADD_WIDGET: (state, widget) => {
    if (state.app.activeSlot) {
      state.app.activeSlot.widgets.push(_.merge({}, widget))
    }
  },
  SET_SHOW_OVERLAY: (state, value) => {
    state.showOverlay = value
  }
}

export default {
  strict: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
