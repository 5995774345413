export default [
  {
    path: '/wizard-orientation',
    name: 'DsBuilderWizardOrientation',
    meta: {
      auth: true,
      permission: ['ROLE_TV_APP_VIEW', 'ROLE_DS_APP_VIEW']
    },
    component: () => import(/* webpackChunkName: "DsBuilderWizardOrientation" */ '@/views/builder/ds/WizardOrientation')
  },
  {
    path: '/wizard-layout',
    name: 'DsBuilderWizardLayout',
    meta: {
      auth: true,
      permission: ['ROLE_TV_APP_VIEW', 'ROLE_DS_APP_VIEW']
    },
    component: () => import(/* webpackChunkName: "DsBuilderWizardLayout" */ '@/views/builder/ds/WizardLayout')
  },
  {
    path: '/builder',
    name: 'DsBuilder',
    meta: {
      auth: true,
      permission: ['ROLE_TV_APP_VIEW', 'ROLE_DS_APP_VIEW', 'ROLE_APP_CREATE_EDIT']
    },
    component: () => import(/* webpackChunkName: "DsBuilder" */ '@/views/builder/ds/Builder')
  },
  {
    path: '/builder/:id',
    name: 'DsBuilderEdit',
    meta: {
      auth: true,
      permission: ['ROLE_TV_APP_VIEW', 'ROLE_DS_APP_VIEW', 'ROLE_APP_CREATE_EDIT']
    },
    props: true,
    component: () => import(/* webpackChunkName: "DsBuilderEdit" */ '@/views/builder/ds/Builder')
  }
]
