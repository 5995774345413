import _ from 'lodash'
import Vue from 'vue'
import { scaffoldStore } from 'undo-redo-vuex'
import api from '@/api'

function unstringifyWidgetsData(app) {
  if (app) {
    app.pages.forEach((page, pageIndex) => {
      if (page.composition && page.composition.widgets && page.composition.widgets.length) {
        page.composition.widgets.forEach((widget, widgetIndex) => {
          if (app.pages[pageIndex].composition.widgets[widgetIndex].data) {
            app.pages[pageIndex].composition.widgets[widgetIndex].data = JSON.parse(app.pages[pageIndex].composition.widgets[widgetIndex].data)
          }
        })
      }
    })
  }

  return app
}

// State
const state = {
  lang: 'en',
  app: {
    id: null,
    name: undefined,
    backgroundColor: null,
    backgroundImage: null,
    title: {},
    category: undefined,
    backgroundColor: '#2E2E2E',
    backgroundImage: undefined,
    draft: true,
    pages: [],
    screenshot: null
  },
  activePageId: undefined,
  activePageName: undefined,
  notifications: null,
  publishing: false,
  appToRemove: null,
  languageAvailableOptions: [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'pl', label: 'Polish' }
  ]
}

// Gettetrs
const getters = {
  getActivePage: state => state.app.pages.find(item => item.id === state.activePageId),
  languageAvailableOptions: state => state.languageAvailableOptions,
  getActivePageName: state => state.activePageName,
  getActivePageId: state => state.activePageId,
  getClientApp: state => state.app,
  getLang: state => state.lang,
  notifications: state => state.notifications,
  appToRemove: state => state.appToRemove
}

// Actions
const actions = {
  applyBackgroundToPages(context, pages) {
    console.log('Apply to other...', pages)

    const backgroundImage = context.getters['getActivePage'].backgroundImage
    const backgroundColor = context.getters['getActivePage'].backgroundColor

    _.forEach(pages, id => {
      context.commit('SET_BACKGROUND_IMAGE_FOR_PAGE', { id, backgroundImage })
      context.commit('SET_BACKGROUND_COLOR_FOR_PAGE', { id, backgroundColor })
    })
  },

  getClientApp({ commit, app }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload) {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `The info page has not been added!` })
        reject()
      }
      api.infoPages
        .getById(payload)
        .then(response => {
          commit('SET_APP', response.data)
          if (response.data.pages) {
            commit('SET_ACTIVE_PAGE', response.data.pages[0].id)
          }
          resolve()
        })
        .catch(error => {
          commit('SET_NOTIFICATIONS', { type: 'error', message: `Cannot get info page data!` })
          reject()
        })
    })
  },

  createClientApp({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const currentTvAppId = rootState.tvApps.currentApp ? rootState.tvApps.currentApp.id : null

      if (!payload || !currentTvAppId) {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `The info page has not been added!` })
        reject()
      }

      api.infoPages
        .create(currentTvAppId, payload)
        .then(response => {
          commit('SET_APP_ID', response.data.id)
          commit('SET_APP_STATUS', response.data.status)
          console.log('createClientApp: ', response.data)
          commit('SET_NOTIFICATIONS', { type: 'success', message: `The info page has been created!` })
          resolve(response.data)
        })
        .catch(error => {
          console.log('createClientApp: ', error)
          commit('SET_NOTIFICATIONS', { type: 'error', message: `The info page has not been created!` })
          reject()
        })
    })
  },

  createClientAppDraft({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload) reject()

      api.infoPages
        .createDraft(state.app.id, payload)
        .then(response => {
          commit('SET_APP_ID', response.data.id)
          commit('SET_APP_STATUS', response.data.status)
          commit('SET_NOTIFICATIONS', { type: 'success', message: `Draft info page saved and created!` })
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_NOTIFICATIONS', { type: 'error', message: error.response.data.message })
          reject()
        })
    })
  },

  updateClientApp({ commit, state }, payload) {
    if (!payload) return

    api.infoPages
      .update(state.app.id, payload)
      .then(response => {
        commit('SET_APP_ID', response.data.id)
        commit('SET_APP_STATUS', response.data.status)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `Info page updated` })
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: error.response.data.message })
      })
  },

  publishClientApp({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_PUBLISHING', true)
      api.infoPages
        .publish(state.app.id)
        .then(response => {
          commit('SET_APP_ID', response.data.id)
          commit('SET_APP_STATUS', response.data.status)
          commit('SET_NOTIFICATIONS', { type: 'success', message: `Info page published` })
          commit('SET_PUBLISHING', false)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_NOTIFICATIONS', { type: 'error', message: error.response.data.message })
          commit('SET_PUBLISHING', false)
          reject()
        })
    })
  },

  exportClientApp({ commit }, payload) {
    if (!payload) return

    api.infoPages
      .exportClientAppToHotelTvApp(payload.clientAppId, payload.hotelTvAppId)
      .then(() => {
        commit('SET_NOTIFICATIONS', { type: 'success', message: `The info page has been exported!` })
      })
      .catch(error => {
        console.log('exportClientApp: ', error)
        commit('SET_NOTIFICATIONS', { type: 'error', message: `The info page has not been exported!` })
      })
  }
}

// Mutations
const mutations = {
  EMPTY_STATE(state) {
    state.app = state.app
  },
  SET_APP(state, data) {
    // data.pages.forEach((item, key) => { data.pages[key]._ref = Math.random() })

    state.app = _.merge({}, unstringifyWidgetsData(data))
  },
  SET_APP_ID(state, id) {
    state.app.id = id
  },
  SET_APP_STATUS(state, status) {
    state.app.status = status
  },
  SET_ACTIVE_PAGE(state, id) {
    state.activePageId = id
  },
  SET_BACKGROUND_COLOR_FOR_APP(state, backgroundColor) {
    state.app.backgroundColor = backgroundColor
  },
  SET_BACKGROUND_IMAGE_FOR_APP(state, backgroundImage) {
    state.app.backgroundImage = backgroundImage
  },
  SET_BACKGROUND_COLOR_FOR_PAGE(state, { id, backgroundColor }) {
    _.find(state.app.pages, { id: id }).backgroundColor = backgroundColor
  },
  SET_BACKGROUND_IMAGE_FOR_PAGE(state, { id, backgroundImage }) {
    _.find(state.app.pages, { id: id }).backgroundImage = backgroundImage
  },
  SET_COMPOSITION_FOR_ACTIVE_PAGE(state, data) {
    const activePage = getters.getActivePage(state)

    activePage.composition = _.merge({}, data)

    state.app.pages = [...state.app.pages]
  },
  SET_BACKGROUND_COLOR_FOR_ACTIVE_PAGE(state, data) {
    const activePage = getters.getActivePage(state)

    activePage.backgroundColor = data

    state.app.pages = [...state.app.pages]
  },
  SET_BACKGROUND_IMAGE_FOR_ACTIVE_PAGE(state, data) {
    const activePage = getters.getActivePage(state)

    activePage.backgroundImage = _.merge({}, data)

    state.app.pages = [...state.app.pages]
  },
  SET_NAME_FOR_PAGE(state, data) {
    _.find(state.app.pages, { id: data.id }).name[data.locale.lang] = data.locale.data
  },
  SET_PAGES(state, pages) {
    state.app.pages = [...pages]
  },
  CREATE_PAGE(state, page) {
    state.app.pages.push(_.merge({}, page))
  },
  REMOVE_PAGE(state, id) {
    if (state.app.pages.length > 1) {
      const index = state.app.pages.findIndex(item => item.id === id)
      state.app.pages.splice(index, 1)
      state.activePageId = state.app.pages[0].id
    }
  },
  UPDATE_WIDGET(state, { widgetName, data }) {
    const activePage = getters.getActivePage(state)
    const widgetIndex = activePage.composition && activePage.composition.widgets && activePage.composition.widgets.findIndex(i => i.position === widgetName)
    const widget = activePage.composition.widgets[widgetIndex]

    if (Array.isArray(data)) Vue.set(widget, 'data', data)
    else Vue.set(widget, 'data', _.assignIn(widget.data, data))

    activePage.composition.widgets = [...activePage.composition.widgets]
  },
  SET_LANGUAGE(state, lang) {
    state.lang = lang
  },
  SET_CATEGORY(state, category) {
    console.log(category)
    state.app.category = category
  },
  RESET_APP(state) {
    const id = new Date().getTime()
    state.app = {
      name: undefined,
      title: {},
      category: undefined,
      backgroundColor: '#2E2E2E',
      backgroundImage: undefined,
      draft: true,
      pages: [
        {
          // _ref: Math.random(),
          name: { en: 'New View', de: 'New View', fr: 'New View', pl: 'New View' },
          backgroundColor: '#2E2E2E',
          id: new Date().getTime()
        }
      ],
      screenshot: null
    }
  },
  SET_NAME_FOR_APP(state, data) {
    if (!(state.app.title instanceof Object)) {
      state.app.title = {}
    }
    state.app.name = data.data

    Vue.set(state.app.title, data.lang, data.data)
  },

  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },

  SET_PUBLISHING(state, payload) {
    state.publishing = payload
  },

  SET_APP_TO_REMOVE(state, payload) {
    state.appToRemove = payload
  },

  TOGGLE_REMOVE_APP_MODAL(state, payload) {
    state.showRemoveAppModal = payload
  }
}

export default scaffoldStore({
  strict: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
})
