import api from '@/api'

const state = {
  folders: [],
  selectedFolder: null,
  currentlyEditedFolder: null,
  isFolderRenaming: false,
  showFolderPopover: false,
  showRemoveFolderModal: false
}

const mutations = {
  /**
   * Store fetched folders array to the app state.
   *
   * @param {Array} payload
   */
  SET_FOLDERS(state, payload) {
    state.folders = payload
  },

  /**
   * Add new folder.
   *
   * @param {Object} payload
   */
  ADD_FOLDER(state, payload) {
    state.folders.push(payload)
  },

  /**
   * Add medium to the folder.
   *
   * @param {Object} payload
   */
  ADD_MEDIUM_TO_FOLDER(rootState, payload) {
    const updateArray = (item, payload) => {
      if (item.id === payload.mediaId) item.mediaFolder = payload.folderId
    }

    if (rootState.items.length) rootState.items.find(item => updateArray(item, payload))
    if (rootState.filteredItems.length) rootState.filteredItems.find(item => updateArray(item, payload))
    if (rootState.selectedItems.length) rootState.selectedItems.find(item => updateArray(item, payload))
  },

  /**
   * Add new folder.
   *
   * @param {Object} payload
   */
  RENAME_FOLDER(state, payload) {
    state.folders.find(folder => {
      if (folder.id === payload.id) folder.name = payload.name
    })
  },

  /**
   * Add new folder.
   *
   * @param {Number} payload
   */
  REMOVE_FOLDER(state, payload) {
    state.folders = state.folders.filter(folder => folder.id.toString() !== payload.toString())
  },

  // /**
  //  * Filter items by folder.
  //  *
  //  * @param {String} payload
  // */
  // FILTER_IMAGES_BY_FOLDER (rootState, payload) {
  //   rootState.filteredItems = rootState.items.filter(item => item.mediaFolder.id.toString() === payload.toString())
  // },

  // /**
  //  * Filter videos by folder.
  //  *
  //  * @param {String} payload
  // */
  // FILTER_VIDEO_BY_FOLDER (rootState, payload) {
  //   rootState.filteredVideos = rootState.videos.filter(video => video.mediaFolder.id === payload)
  // },

  /**
   * Set selected folder.
   *
   * @param {Object, Null} payload
   */
  SELECT_FOLDER(state, payload) {
    state.selectedFolder = payload
  },

  /**
   * Set selected folder to default folder.
   *
   */
  SET_SELECTED_FOLDER_TO_DEFAULT(state) {
    if (state.folders.length) state.selectedFolder = { id: 0, name: 'All media' }
  },

  /**
   * Store currently edited folder to the currentlyEditedFolder state.
   *
   * @param {Object, Null} payload
   */
  SET_CURRENTLY_EDITED_FOLDER(state, payload) {
    state.currentlyEditedFolder = payload
  },

  /**
   * Set isFolderRenaming state
   *
   * @param {Boolean} payload
   */
  TOGGLE_FOLDER_RENAMIN_STATE(state, payload) {
    state.isFolderRenaming = payload
  },

  /**
   * Set showFolderPopover state
   *
   * @param {Boolean} payload
   */
  TOGGLE_FOLDER_POPOVER(state, payload) {
    state.showFolderPopover = payload
  },

  /**
   * Set showRemoveFolderModal state
   *
   * @param {Boolean} payload
   */
  TOGGLE_REMOVE_FOLDER_MODAL(state, payload) {
    console.log('TOGGLE_REMOVE_FOLDER_MODAL: ', payload)
    state.showRemoveFolderModal = payload
  }
}

const actions = {
  /**
   * Fetch folders from the server
   */
  fetchFolders({ commit, rootState }) {
    const currentWorkspaceId = rootState.workspaces.currentWorkspace ? rootState.workspaces.currentWorkspace.id : null
    api.folders
      .getFolders(currentWorkspaceId, '', '')
      .then(response => {
        commit('SET_FOLDERS', response.data)
        commit('SET_SELECTED_FOLDER_TO_DEFAULT')
      })
      .catch(error => {
        console.log('fetchFolders: ', error)
      })
  },

  /**
   * Add new folder
   *
   * @param {String} payload
   */
  addFolder({ commit, rootState }, payload) {
    commit('TOGGLE_FOLDER_RENAMIN_STATE', false)
    const currentWorkspaceId = rootState.workspaces.currentWorkspace ? rootState.workspaces.currentWorkspace.id : null

    if (!payload) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Folder needs to have a name!` })
      return
    }

    api.folders
      .createFolder(currentWorkspaceId, {
        name: payload.trim()
      })
      .then(response => {
        commit('ADD_FOLDER', response.data)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `Folder added!` })
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Folder has not been added!` })
        console.log('addFolder: ', error)
      })
  },

  /**
   * Rename folder
   *
   * @param {Object} payload
   */
  renameFolder({ commit }, payload) {
    commit('TOGGLE_FOLDER_RENAMIN_STATE', false)
    commit('TOGGLE_FOLDER_POPOVER', false)
    commit('SET_CURRENTLY_EDITED_FOLDER', null)

    if (!payload) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Folder has not been renamed!` })
      return
    }

    if (!payload.name) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Folder needs to have a name!` })
      return
    }

    api.folders
      .updateFolder(payload.id, { name: payload.name })
      .then(response => {
        commit('RENAME_FOLDER', response.data)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `Folder renamed!` })
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Folder has not been renamed!` })
        console.log('renameFolder: ', error)
      })
  },

  /**
   * Remove folder
   *
   * @param {Number} payload
   */
  removeFolder({ state, commit, dispatch }, payload) {
    if (!payload) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Folder has not been deleted!` })
      return
    }

    commit('appshell/startLoading', false, { root: true })

    api.folders
      .deleteFolder(payload)
      .then(() => {
        dispatch('fetchItems')
        commit('REMOVE_FOLDER', payload)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `Folder deleted!` })
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Folder has not been deleted!` })
        console.log('removeFolder: ', error)
      })
      .then(() => {
        if (state.selectedFolder) {
          if (state.selectedFolder.id.toString() === payload.toString()) {
            commit('SET_SELECTED_FOLDER_TO_DEFAULT')
          }
        }
        commit('SET_CURRENTLY_EDITED_FOLDER', null)
        commit('TOGGLE_FOLDER_POPOVER', false)
        commit('TOGGLE_REMOVE_FOLDER_MODAL', false)
        commit('appshell/endLoading', false, { root: true })
      })
  },

  /**
   * Remove folder and media
   *
   * @param {Number} payload
   */
  removeFolderAndMedia({ dispatch }, payload) {
    dispatch('removeFolder', payload)
    dispatch('removeItemsFromFolder', payload)
  },

  /**
   * Add medium to the folder
   *
   * @param {Object} payload
   */
  addMediumToFolder({ commit, rootState }, payload) {
    if (!payload) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... The medium has not been added to the folder!` })
      return
    }

    const medium = rootState.media.items.find(item => item.id === payload.mediaId) || rootState.media.videos.find(video => video.id === payload.mediaId)
    const endpoint = medium ? medium.mediaType === 'PLAYLIST' ? api.media.assignPlaylistToFolder(payload.mediaId, payload.folderId) : api.media.assignMediaToFolder(payload.mediaId, payload.folderId) : undefined

    if (!endpoint) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... The medium has not been added to the folder!` })
      return
    }

    endpoint
      .then(() => {
        commit('REMOVE_ITEM', payload.mediaId)
        commit('ADD_MEDIUM_TO_FOLDER', payload)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `Added to the folder!` })
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... The medium has not been added to the folder!` })
        console.log('addMediumToFolder: ', error)
      })
  },

  /**
   * Close media popover.
   */
  closeMediaPopover({ commit }) {
    // commit('TOGGLE_REMOVE_FOLDER_MODAL', false)
    commit('TOGGLE_FOLDER_POPOVER', false)
    commit('SET_CURRENTLY_EDITED_FOLDER', null)
  },

  /**
   * Toggle contextmenu.
   *
   * @param {Object} payload
   */
  addFolderContextmenu({ commit }, payload) {
    if (!payload) return

    commit('SET_CURRENTLY_EDITED_FOLDER', payload)
    commit('TOGGLE_FOLDER_POPOVER', true)
  }
}

const getters = {
  folders: state => state.folders,
  selectedFolder: state => state.selectedFolder,
  currentlyEditedFolder: state => state.currentlyEditedFolder,
  isFolderRenaming: state => state.isFolderRenaming,
  showFolderPopover: state => state.showFolderPopover,
  showRemoveFolderModal: state => state.showRemoveFolderModal
}

export const folders = { state, mutations, actions, getters }
