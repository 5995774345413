<template lang="pug">
#app
  .app__loader(:class="{ 'app__loader--visible': isLoading }")

  template(v-if="$auth.ready()")
    transition(name="route" mode="out-in")
      router-view

    vue-snotify

    portal-target(name="popups", :transition="fadeTransition", multiple)
    portal-target(name="dialogs", multiple)
  .app__version V0.00.71
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated',
      user: 'account/user',
      workspaces: 'workspaces/workspaces',
      isLoading: 'appshell/isLoading',
      isSectionLoading: 'appshell/isSectionLoading',
      version: 'appshell/version',
      isDataFetchingFailed: 'appshell/isDataFetchingFailed'
    }),
    fadeTransition() {
      return {
        functional: true,
        render(h, context) {
          return h('transition', { props: { name: 'fade', mode: 'out-in' } }, context.children)
        }
      }
    }
  },
  watch: {
    $route: {
      handler: 'updateFetchingStates',
      immediate: true
    }
  },
  methods: {
    ...mapMutations({
      endSectionLoading: 'appshell/endSectionLoading',
      setDataFetchingState: 'appshell/setDataFetchingState'
    }),
    ...mapActions({
      fetchWorkspaces: 'workspaces/fetchWorkspaces'
    }),
    updateFetchingStates() {
      if (this.isDataFetchingFailed) this.setDataFetchingState(false)
      if (this.isSectionLoading) this.endSectionLoading()
      if (!this.workspaces.length && this.isAuthenticated) this.fetchWorkspaces()
    }
  }
}
</script>

<style lang="scss">
.mce-notification-warning {
  display: none;
  visibility: hidden;
}
.app__loader {
  position: absolute;
  left: 0;
  width: 33.3333vw;
  height: 2px;
  background-color: $color--primary;
  visibility: hidden;
  opacity: 0;
  @include transition--primary;

  @keyframes appLoading {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(300%, 0, 0);
    }
  }
  animation: 1.5s appLoading infinite linear;

  &--visible {
    visibility: visible;
    opacity: 1;
  }
}

.app__version {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  font-size: 0.7rem;
  font-weight: $font--bold;
  opacity: 0.2;
}
</style>
