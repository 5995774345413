import axios from 'axios'

export default {
  getPlaceStructure: () => axios.get('/api/v1/place-structure'),
  getPlace: placeId => axios.get(`/api/v1/place-structure/${placeId}`),
  editPlaceStructure: data => axios.put('/api/v1/place-structure', data),
  addNewPlace: data => axios.post('/api/v1/place', data),
  addNewSubPlace: (parentId, data) => axios.post(`/api/v1/place/${parentId}`, data),
  updatePlace: (placeId, data) => axios.put(`/api/v1/place/${placeId}`, data),
  deletePlace: placeId => axios.delete(`/api/v1/place/${placeId}`),
  uploadLogo: data => axios.post('/api/v1/place/logo', data)
}
