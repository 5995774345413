<template>
  <div class="card">
    <div v-if="$slots.header" class="card-header">
      <slot name="header" />
    </div>
    <div class="card-content">
      <slot name="content" />
    </div>
    <div v-if="$slots.footer" class="card-footer">
      <slot name="footer" />
    </div>
    <div v-if="loader" class="card-loader" :class="{ visible: loading }">
      <svg class="loader" viewBox="25 25 50 50" xmlns="http://www.w3.org/2000/svg">
        <circle class="loader-circle" fill="none" stroke-width="3" stroke-linecap="round" cx="50" cy="50" r="20" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    loader: Boolean,
    loading: Boolean
  }
}
</script>

<style lang="scss">
.card {
  position: relative;
  background: #fff;
  border-radius: 0.4rem;

  &.card-contained {
    max-width: 700px;
    margin: 0 auto;
  }

  .card-header {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
  }

  .card-content {
    padding: 1.5rem;
  }

  .card-footer {
    margin-top: -1.5rem;
    padding: 1.5rem;
  }

  .card-loader {
    @include fill-parent;
    z-index: 50;
    background-color: rgba(#fff, 0.8);
    @include transition--primary;
    border-radius: 0.4rem;
    visibility: hidden;
    opacity: 0;

    &.visible {
      visibility: visible;
      opacity: 1;
    }

    .loader {
      position: absolute;
      top: calc(50% - 16px);
      left: calc(50% - 16px);
      height: 32px;
      width: 32px;
      stroke: $color--primary;
      animation: loaderRotate 1.4s linear infinite;

      .loader-circle {
        sstroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation: loaderTurn 1.4s ease-in-out infinite;
      }

      @keyframes loaderRotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes loaderTurn {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -35px;
        }
        100% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -124px;
        }
      }
    }
  }

  &.card-lg {
    .card-content {
      padding: 2.5rem;

      @include media('<=screen-xl') {
        padding: 2rem;
      }
      @include media('<=screen-sm') {
        padding: 1rem;
      }
    }
  }

  &.card-xl {
    .card-content {
      padding: 4rem;

      @include media('<=screen-xl') {
        padding: 3rem;
      }

      @include media('<=screen-sm') {
        padding: 2rem;
      }
    }

    .card-footer {
      margin-top: -3rem;

      @include media('<=screen-xl') {
        margin-top: -2rem;
      }
    }
  }
}
</style>
