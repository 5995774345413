import router from '@/router'
import api from '@/api'

const state = {
  users: [],
  currentUser: JSON.parse(localStorage.getItem('currentUser')) || null,
  userForm: {
    createUserRequest: {
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    },
    authority: null,
    places: {}
  },
  roles: [
    {
      id: 1,
      name: 'CONTENT_EDITOR',
      title: 'Content Editor',
      shortDescription: 'Can use Hotel marketing tools and create Digital Signage apps.',
      icon: 'content_editor'
    },
    {
      id: 2,
      name: 'MANAGER',
      title: 'Manager',
      shortDescription: 'It has full management of apps and scheduler. Basic management of devices and users (can create ContentEditor users).',
      icon: 'manager'
    },
    {
      id: 3,
      name: 'ADMINISTRATOR',
      title: 'Administrator',
      shortDescription: 'Owner of the business. It has full management of all functions in the BergOs.',
      icon: 'administrator'
    },
    {
      id: 4,
      name: 'INTEGRATOR',
      title: 'Integrator',
      shortDescription: 'Partner implementing solutions in the region. It has full management of all functions in the BergOs. Without private functions such as: Hotel and marketing messages.',
      icon: 'integrator'
    },
    {
      id: 5,
      name: 'DISTRIBUTOR',
      title: 'Distributor',
      shortDescription: 'Business and management role. Created only by the management of Berg Technologies.',
      icon: 'distributor'
    }
  ],
  availableAuthorities: [],
  currentWorkspaceAuthorities: [],
  userWorkspaces: [],
  newUserFormStep: 1,
  showAddUserModal: false,
  showAddUserConfirmationModal: false,
  showLockAccessUserModal: false,
  showUnlockAccessUserModal: false,
  showResetPassUserModal: false,
  showRemoveUserModal: false,
  notifications: null
}

const mutations = {
  SET_USERS(state, payload) {
    state.users = payload
  },

  SET_CURRENT_USER(state, payload) {
    state.currentUser = typeof payload === 'object' ? payload : state.users.find(user => user.username === payload)

    if (payload) localStorage.setItem('currentUser', JSON.stringify(payload))
    else localStorage.removeItem('currentUser')
  },

  REMOVE_USER(state, payload) {
    state.users = state.users.filter(user => user.id !== payload)
  },

  SET_USER_DETAILS(state, payload) {
    const keys = Object.keys(state.userForm.createUserRequest)
    const payloadKey = Object.keys(payload)[0]

    for (const i in keys) {
      const item = keys[i]
      if (keys[i] === payloadKey) state.userForm.createUserRequest[item] = payload[item]
    }
  },

  SET_AVAILABLE_AUTHORITIES(state, payload) {
    state.availableAuthorities = payload
  },

  SET_USER_WORKSPACES(state, payload) {
    state.userWorkspaces = payload
  },

  SET_NEW_USER_FORM_STEP(state, payload) {
    state.newUserFormStep = payload
  },

  SET_USER_FORM_PLACES(state, payload) {
    state.userForm.places = payload
  },

  SET_USER_FORM_AUTHORITIES(state, payload) {
    state.userForm.authority = payload
  },

  CLEAR_USER_FORM(state) {
    state.userForm = {
      createUserRequest: {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      },
      authority: null,
      places: {}
    }
  },

  TOGGLE_LOCK_ACCESS_USER_MODAL(state, payload) {
    state.showLockAccessUserModal = payload
  },

  TOGGLE_UNLOCK_ACCESS_USER_MODAL(state, payload) {
    state.showUnlockAccessUserModal = payload
  },

  TOGGLE_RESET_PASS_USER_MODAL(state, payload) {
    state.showResetPassUserModal = payload
  },

  TOGGLE_REMOVE_USER_MODAL(state, payload) {
    state.showRemoveUserModal = payload
  },

  TOGGLE_ADD_USER_MODAL(state, payload) {
    state.showAddUserModal = payload
  },

  TOGGLE_ADD_USER_CONFIRMATION_MODAL(state, payload) {
    state.showAddUserConfirmationModal = payload
  },

  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  }
}

const actions = {
  fetchUsers({ commit }, payload) {
    commit('appshell/startSectionLoading', null, { root: true })

    api.users
      .getUsersInMyPlaces()
      .then(response => {
        commit('SET_USERS', response.data)
        if (payload) commit('SET_CURRENT_USER', payload)
        commit('appshell/endSectionLoading', null, { root: true })
      })
      .catch(error => {
        console.log('fetchUsers: ', error)
        commit('appshell/setDataFetchingState', true, { root: true })
        commit('appshell/endSectionLoading', null, { root: true })
      })
  },

  fetchUsersByPlaceId({ commit, rootState }) {
    if (!rootState.workspaces.currentWorkspace) return

    commit('appshell/startSectionLoading', null, { root: true })

    api.users
      .getUsersInPlace(rootState.workspaces.currentWorkspace.id)
      .then(response => {
        commit('SET_USERS', response.data)
        commit('appshell/endSectionLoading', null, { root: true })
      })
      .catch(error => {
        console.log('fetchUsersByPlaceId: ', error)
        commit('appshell/setDataFetchingState', true, { root: true })
        commit('appshell/endSectionLoading', null, { root: true })
      })
  },

  fetchAvailableAuthorities({ commit }, payload) {
    if (!payload) return

    const identifiers = Object.keys(payload)
    const selectedPlacesIds = identifiers.filter(id => payload[id])

    if (!selectedPlacesIds.length) return

    api.users
      .getAvailableAuthorities(selectedPlacesIds)
      .then(response => {
        commit('SET_AVAILABLE_AUTHORITIES', response.data)
      })
      .catch(error => {
        console.log('fetchAvailableAuthorities: ', error)
      })
  },

  fetchUserWorkspaces({ commit, dispatch }, payload) {
    if (!payload) return

    commit('appshell/startSectionLoading', null, { root: true })

    api.users
      .getUserWorkspaces(payload)
      .then(response => {
        commit('SET_USER_WORKSPACES', response.data)
        dispatch('workspaces/flatObject', response.data, { root: true })
        commit('appshell/endSectionLoading', null, { root: true })
      })
      .catch(error => {
        console.log('fetchUserWorkspaces: ', error)
        commit('appshell/endSectionLoading', null, { root: true })
      })
  },

  lockAccessToPlace({ commit, dispatch, rootState }, payload) {
    const currentWorkspaceWithDevice = rootState.workspaces.currentWorkspaceWithDevice
    const currentUser = state.currentUser

    if (!payload || !currentUser || !currentWorkspaceWithDevice) {
      commit('SET_NOTIFICATIONS', {
        type: 'error',
        message: `Upsss... The user has not been blocked in this place!`
      })
      return
    }

    api.users
      .blockUserInPlace(currentWorkspaceWithDevice.id, currentUser.username, payload)
      .then(response => {
        dispatch('fetchUsers', currentUser.username)
        dispatch('fetchUsersByPlaceId')
        commit('TOGGLE_LOCK_ACCESS_USER_MODAL', false)
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: `The user has been blocked in this place!`
        })
      })
      .catch(error => {
        console.log('lockAccessToPlace: ', error)
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: `Upsss... The user has not been blocked in this place!`
        })
      })
  },

  unlockAccessToPlace({ commit, dispatch, rootState }) {
    const currentWorkspaceWithDevice = rootState.workspaces.currentWorkspaceWithDevice
    const currentUser = state.currentUser

    if (!currentUser || !currentWorkspaceWithDevice) {
      commit('SET_NOTIFICATIONS', {
        type: 'error',
        message: `Upsss... The user has not been unblocked in this place!`
      })
      return
    }

    const data = {
      blockedReason: null,
      blockedMessage: null,
      blocked: false
    }

    api.users
      .blockUserInPlace(currentWorkspaceWithDevice.id, currentUser.username, data)
      .then(response => {
        dispatch('fetchUsers', currentUser.username)
        dispatch('fetchUsersByPlaceId')
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: `The user has been unblocked in this place!`
        })
      })
      .catch(error => {
        console.log('unlockAccessToPlace: ', error)
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: `Upsss... The user has not been unblocked in this place!`
        })
      })
  },

  sendResetPassEmail({ commit }, payload) {
    if (!payload) return
    console.log('sendResetPassEmail')
  },

  addUser({ commit, dispatch, rootState }) {
    const createUserRequest = {
      ...state.userForm.createUserRequest,
      username: state.userForm.createUserRequest.email
    }

    api.users
      .assignNewUserToPlacesAndDevices({ ...state.userForm, createUserRequest })
      .then(response => {
        if (rootState.workspaces.currentWorkspace) dispatch('fetchUsersByPlaceId')
        else dispatch('fetchUsers')

        commit('TOGGLE_ADD_USER_MODAL', false)
        commit('CLEAR_USER_FORM')
        commit('SET_NEW_USER_FORM_STEP', 1)
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: `The new user has been added!`
        })
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: `Upsss... The user has not been added!`
        })
        console.log('addFolder: ', error)
      })
  },

  updateCurrentUserAuthority({ commit, dispatch }, payload) {
    if (!state.currentUser || !payload) return

    api.users
      .assignUserAuthorities({
        username: state.currentUser.username,
        authority: payload
      })
      .then(response => {
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: `The role has been changed!`
        })
        dispatch('fetchUsers', state.currentUser.username)
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: `Upsss... The role has not been changed!`
        })
        console.log('updateCurrentUserAuthority: ', error)
      })
  },

  updateUser({ rootState, commit }) {
    let places = {}
    rootState.workspaces.flatWorkspaces.forEach(element => {
      if (element.hasOwnProperty('id')) places = { ...places, [element.id]: element.selected }
    })

    api.users
      .assignExistingUserToPlaces({
        username: state.currentUser.username,
        places
      })
      .then(response => {
        console.log('updateUser: ', response.data)
        commit('SET_CURRENT_USER', response.data.username)
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: `The user has been updated!`
        })
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: `Upsss... The user has not been updated!`
        })
        console.log('updateUser: ', error)
      })
  },

  removeUserFromPlace({ commit, rootState }) {
    const currentWorkspaceWithDevice = rootState.workspaces.currentWorkspaceWithDevice
    const currentUser = state.currentUser

    if (!currentUser || !currentWorkspaceWithDevice) {
      commit('SET_NOTIFICATIONS', {
        type: 'error',
        message: `Upsss... The user has not been deleted from this place!`
      })
      return
    }

    api.users
      .removeUserFromPlace(currentWorkspaceWithDevice.id, currentUser.username)
      .then(response => {
        console.log('removeUserFromPlace: ', response.data)
        commit('REMOVE_USER', response.data)
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: `The user has been deleted from this place!`
        })
        router.go(-1)
      })
      .catch(error => {
        console.log('removeUserFromPlace: ', error)
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: `Upsss... The user has not been deleted from this place!`
        })
      })
  },

  setUserFomPlacesAndDevices({ commit, rootState }) {
    const flatWorkspaces = rootState.workspaces.flatWorkspaces
    const selectedPlacesIdsArray = []

    for (let i = 0; i < flatWorkspaces.length; i++) {
      const item = flatWorkspaces[i]

      if (item.hasOwnProperty('id')) {
        commit('SET_USER_FORM_PLACES', {
          ...state.userForm.places,
          [item.id]: item.selected
        })
        if (item.selected) selectedPlacesIdsArray.push(item.id)
      }
    }
  },

  activateUserAccount({ commit }, payload) {
    if (!payload) return

    api.users
      .activateUserAccount(payload.email, payload.activationCode, payload.password)
      .then(response => {
        console.log('activateUserAccount: ', response.data)
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: `The user has been activated!`
        })
        const timeout = setTimeout(() => {
          router.push('/signin')
          clearTimeout(timeout)
        }, 1000)
      })
      .catch(error => {
        console.log('activateUserAccount: ', error.message)
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: `Upsss... The user has not been activated!`
        })
      })
  }
}

const getters = {
  users(state) {
    return state.users
  },
  currentUser(state) {
    return state.currentUser
  },
  userForm(state) {
    return state.userForm
  },
  newUserFormStep(state) {
    return state.newUserFormStep
  },
  availableAuthorities(state) {
    return state.availableAuthorities
  },
  roles(state) {
    return state.roles
  },
  userWorkspaces(state) {
    return state.userWorkspaces
  },
  currentWorkspaceAuthorities(state) {
    return state.currentWorkspaceAuthorities
  },
  showAddUserModal(state) {
    return state.showAddUserModal
  },
  showAddUserConfirmationModal(state) {
    return state.showAddUserConfirmationModal
  },
  showLockAccessUserModal(state) {
    return state.showLockAccessUserModal
  },
  showUnlockAccessUserModal(state) {
    return state.showUnlockAccessUserModal
  },
  showResetPassUserModal(state) {
    return state.showResetPassUserModal
  },
  showRemoveUserModal(state) {
    return state.showRemoveUserModal
  },
  notifications(state) {
    return state.notifications
  }
}

export default { namespaced: true, state, getters, actions, mutations }
