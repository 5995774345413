const users = [
  {
    name: 'Users',
    path: 'users',
    meta: {
      auth: true,
      permission: ['ROLE_USER_FULL_MANAGEMENT', 'ROLE_USER_VIEW']
    },
    components: {
      default: () => import(/* webpackChunkName: "Users" */ '@/views/users/Index'),
      navigation: () => import(/* webpackChunkName: "UsersNavigation" */ '@/views/Navigation')
    }
  },
  {
    path: 'user',
    components: {
      default: () => import(/* webpackChunkName: "UsersBtRouterView" */ '@/components/BtRouterView'),
      navigation: () => import(/* webpackChunkName: "UsersNavigation" */ '@/views/users/Navigation')
    },
    children: [
      {
        name: 'UserSettings',
        path: ':username/general-settings',
        meta: {
          auth: true,
          permission: ['ROLE_USER_FULL_MANAGEMENT', 'ROLE_USER_VIEW']
        },
        component: () => import(/* webpackChunkName: "UserSettings" */ '@/views/users/Settings')
      },
      {
        name: 'UserWorkspaces',
        path: ':username/workspaces',
        meta: {
          auth: true,
          permission: ['ROLE_USER_FULL_MANAGEMENT', 'ROLE_USER_VIEW']
        },
        component: () => import(/* webpackChunkName: "UserWorkspaces" */ '@/views/users/Workspaces')
      },
      {
        name: 'UserRoles',
        path: ':username/roles',
        meta: {
          auth: true,
          permission: ['ROLE_USER_FULL_MANAGEMENT', 'ROLE_USER_VIEW']
        },
        component: () => import(/* webpackChunkName: "UserRoles" */ '@/views/users/Roles')
      }
    ]
  }
]

export default users
