import _ from 'lodash'
import { scaffoldStore } from 'undo-redo-vuex'
import Vue from 'vue'

const getDefaultState = () => {
  return {
    id: 0,
    name: null,
    theme: null,
    template: null,
    type: null,
    layout: null,
    language: 'en',
    orientation: null,
    currentViewId: 1,
    currentViewIndex: 0,
    currentDuration: 30,
    activeApp: null,
    header: [],
    views: [],
    selectedWidget: undefined,
    isPaused: false,
    buildingMode: false,
    loaded: false,
    preloadedApp: false
  }
}

// State
const state = getDefaultState()

// Gettetrs
const getters = {
  app: (state) => state,
  template: (state) => state.template,
  name: (state) => state.name,
  type: (state) => state.type,
  layout: (state) => state.layout,
  views: (state) => state.views,
  language: (state) => state.language,
  header: (state) => state.header,
  theme: (state) => state.theme,
  orientation: (state) => state.orientation,
  currentView: (state) => state.views.find((item) => item.id === state.currentViewId),
  currentDuration: (state) => state.currentDuration,
  currentViewIndex: (state) => state.currentViewIndex,
  currentViewId: (state) => state.currentViewId,
  activeApp: (state) => state.activeApp,
  isPaused: (state) => state.isPaused,
  selectedWidget: (state) => state.selectedWidget,
  loaded: (state) => state.loaded,
  status: (state) => state.status,
  preloadedApp: (state) => state.preloadedApp
}

// Actions
const actions = {
  createView({ state, commit, getters }, payload) {
    commit('CREATE_VIEW', {
      id: new Date().getTime(),
      name: {
        en: payload
      },
      background: {
        color: getters.currentView.background.color,
        media: null
      },
      widgets: [],
      duration: 40,
      composition: null
    })
  },
  applyBackgroundToViews(context, views) {
    const media = context.getters['currentView'].background.media
    const color = context.getters['currentView'].background.color

    _.forEach(views, (id) => {
      context.commit('SET_BACKGROUND_IMAGE_FOR_VIEW', { id, media })
      context.commit('SET_BACKGROUND_COLOR_FOR_VIEW', { id, color })
    })
  }
}

// Mutations
const mutations = {
  emptyState(state) {
    state.app = state.app
  },
  RESET_STATE(state) {
    state = Object.assign(state, getDefaultState())
  },
  SET_APP: (state, payload) => {
    Object.keys(payload).forEach((item) => {
      let value = payload[item]
      if ((item === 'views' || item === 'header') && (typeof value === 'string' || value instanceof String)) value = JSON.parse(payload[item])
      Vue.set(state, item, payload[item] ? value : state[item])
    })
  },
  SET_NAME: (state, payload) => {
    state.name = payload
  },
  SET_ORIENTATION: (state, orientation) => {
    state.orientation = orientation
  },
  SET_LAYOUT: (state, layout) => {
    state.layout = layout
  },
  SET_VIEWS: (state, views) => {
    state.views = views
  },
  CREATE_VIEW(state, view) {
    state.views.push(view)
  },
  REMOVE_VIEW(state, id) {
    if (state.views.length > 1) {
      const views = _.cloneDeep(state.views)
      _.remove(views, { id: id })
      Vue.set(state, 'views', views)
      state.currentViewId = state.views[0].id
    }
  },
  ADD_WIDGET: (state, widget) => {
    if (state.activeSlot) {
      state.activeSlot.widgets.push(_.merge({}, widget))
    }
  },
  SET_CURRENT_VIEW_INDEX: (state, index) => {
    state.currentViewIndex = index
  },
  SET_CURRENT_VIEW: (state, id) => {
    state.currentViewId = id
  },
  SET_BACKGROUND_IMAGE_FOR_VIEW: (state, { id, media }) => {
    state.views.find((item) => item.id === id).background.media = media
  },
  SET_BACKGROUND_COLOR_FOR_VIEW: (state, { id, color }) => {
    state.views.find((item) => item.id === id).background.color = color
  },
  SET_BACKGROUND_IMAGE_FOR_CURRENT_VIEW: (state, background) => {
    state.views.find((item) => item.id === state.currentViewId).background.media = background
  },
  SET_BACKGROUND_COLOR_FOR_CURRENT_VIEW: (state, color) => {
    state.views.find((item) => item.id === state.currentViewId).background.color = color
  },
  SET_BACKGROUND_OVERLAY_FOR_CURRENT_VIEW: (state, value) => {
    state.views.find((item) => item.id === state.currentViewId).background.addOverlay = value
  },
  SET_COMPOSITION_FOR_CURRENT_VIEW: (state, composition) => {
    const currentView = state.views.find((item) => item.id === state.currentViewId)
    currentView.composition = composition
    const widgetsCount = currentView.widgets.length
    const slotsCount = composition.slots.length
    if (widgetsCount < slotsCount) {
      var i
      for (i = 0; i <= slotsCount - widgetsCount - 1; i++) {
        currentView.widgets.push(null)
      }
    }
  },
  SET_CURRENT_VIEW_DURATION: (state, duration) => {
    state.views.find((item) => item.id === state.currentViewId).duration = duration
  },
  SET_CURRENT_VIEW_PARAMS: (state, payload) => {
    const index = state.views.findIndex((item) => item.id === state.currentViewId)
    const views = [...state.views]

    views[index] = payload

    Vue.set(state, 'views', views)
  },
  SET_GALLERY_WIDGET_ITEMS: (state, items) => {
    state.selectedWidget.data.items = items
  },
  SET_VIEW_NAME: (state, { view, value }) => {
    view.title = value
  },
  SET_NAME_FOR_VIEW: (state, { id, name }) => {
    state.views.find((view) => view.id == id).name[name.lang] = name.data
  },
  PAUSE(state, value) {
    state.isPaused = true
  },
  SET_HEADER_STATE(state, value) {
    Vue.set(state.header, 'enabled', value)
  },
  SET_HEADER_COLOR(state, value) {
    Vue.set(state.header, 'color', value)
  },
  SET_BUILDING_MODE: (state, value) => {
    state.buildingMode = value
  },
  SET_HEADER_WIDGETS: (state, payload) => {
    Vue.set(state.header, 'widgets', payload)
  },
  SET_HEADER_WIDGET: (state, { name, data }) => {
    _.find(state.header.widgets, (o) => o.name === name).data = data
  },
  REMOVE_HEADER_WIDGET: (state, name) => {
    _.find(state.header.widgets, (o) => o.name === name).enabled = false
  },
  ADD_HEADER_WIDGET: (state, name) => {
    _.find(state.header.widgets, (o) => o.name === name).enabled = true
  },
  SET_SELECTED_WIDGET: (state, widget) => {
    state.selectedWidget = widget
  },
  UPDATE_CURRENT_VIEW_WIDGET_DATA: (state, { id, data }) => {
    const currentView = state.views.find((item) => item.id === state.currentViewId)
    const widget = _.find(
      currentView.widgets.filter((o) => o),
      (o) => o.id === id
    )
    widget.id = new Date().getTime()
    widget.data = data
  },
  SET_LOADED_STATE: (state, payload) => {
    state.loaded = payload
  },
  SET_BUILDING_MODE: (state, payload) => {
    state.buildingMode = payload
  },
  SET_PRELOADED_APP: (state, payload) => {
    state.preloadedApp = payload
  },
  SET_STATUS: (state, payload) => {
    state.status = payload
  }
}

export default scaffoldStore({
  strict: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
})
