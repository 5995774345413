import Router from 'vue-router'
import store from '@/store'
import SignIn from './signIn'
import Account from './account'
import InfoPages from './infoPages'
import DsBuilder from './dsBuilder'
import Workspace from './workspace'
import Device from './device'
import Users from './users'
import App from './app'
import CreateApp from './createApp'
import Region from './region'

const beforeEnter = (to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    const matchedRole = !!store.getters['user/userRoles'].find(role => to.meta.permission.includes(role))

    if (!to.meta.permission || !to.meta.permission.length || matchedRole) {
      if (to.path === '/users' || to.path === '/account') {
        next()
      } else {
        if (store.getters['workspaces/currentWorkspace']) next()
        else next('/users')
      }
    } else {
      next('/redirect')
    }
  } else {
    next()
  }
}

const config = {
  routes: [
    {
      path: '/',
      meta: {
        auth: true,
        permission: []
      },
      beforeEnter,
      component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Index'),
      children: [
        {
          name: 'Dashboard',
          path: '/',
          redirect: '/users'
        },
        ...Account,
        ...Workspace,
        ...Device,
        ...App,
        ...Region,
        ...Users
      ]
    },
    ...InfoPages,
    ...DsBuilder,
    ...SignIn,
    ...CreateApp,
    {
      name: 'ActivateAccount',
      path: '/activate-account',
      component: () => import(/* webpackChunkName: "ActivateAccount" */ '@/views/users/ActivateAccount')
    },
    {
      name: 'Redirect',
      path: '/redirect',
      component: () => import(/* webpackChunkName: "Redirect" */ '@/views/redirect/Redirect')
    },
    {
      name: 'Error404',
      path: '*',
      component: () => import(/* webpackChunkName: "Error404" */ '@/views/errors/Error404')
    }
  ],
  linkActiveClass: 'active'
}

const router = new Router(config)

export default router
