import api from '@/api'

// State
const state = {
  rooms: [],
  currentRoom: JSON.parse(localStorage.getItem('currentRoom')) || null,
  notifications: null,
  isLoadingGeneral: false,
  isLoadingGuestInfo: false,
  isLoadingDevices: false,
  isLoadingTvChannels: false,
  isLoadingSmartRoomSettings: false,
  isLoadingLightningSettings: false,
  deviceToDisconnect: null,
  showAssignDevicesDialog: false,
  showApplyChannelListDialog: false,
  showApplyLightModesDialog: false,
  showApplyRoomSettingsDialog: false,
  showLightModeDialog: false,
  showCheckOutDialog: false,
  showIpDialog: false,
  showAuthDialog: false,
  lightModeToEdit: null,
  lightModeToRemove: null
}

// Gettetrs
const getters = {
  rooms: state => state.rooms,
  currentRoom: state => state.currentRoom,
  notifications: state => state.notifications,
  isLoadingGeneral: state => state.isLoadingGeneral,
  isLoadingGuestInfo: state => state.isLoadingGuestInfo,
  isLoadingDevices: state => state.isLoadingDevices,
  isLoadingTvChannels: state => state.isLoadingTvChannels,
  isLoadingSmartRoomSettings: state => state.isLoadingSmartRoomSettings,
  isLoadingLightningSettings: state => state.isLoadingLightningSettings,
  deviceToDisconnect: state => state.deviceToDisconnect,
  showAssignDevicesDialog: state => state.showAssignDevicesDialog,
  showApplyChannelListDialog: state => state.showApplyChannelListDialog,
  showApplyLightModesDialog: state => state.showApplyLightModesDialog,
  showApplyRoomSettingsDialog: state => state.showApplyRoomSettingsDialog,
  showLightModeDialog: state => state.showLightModeDialog,
  showIpDialog: state => state.showIpDialog,
  showAuthDialog: state => state.showAuthDialog,
  showCheckOutDialog: state => state.showCheckOutDialog,
  lightModeToEdit: state => state.lightModeToEdit,
  lightModeToRemove: state => state.lightModeToRemove
}

// Actions
const actions = {
  getCurrentRoom({ commit, state, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let roomNumber
      if (payload !== 0) {
        roomNumber = payload || state.currentRoom.number
        if (!roomNumber) reject()
        if (!state.currentRoom) commit('appshell/startSectionLoading', null, { root: true })
      } else {
        roomNumber = 0
      }

      api.rooms
        .getRoom(rootState.tvApps.currentApp.id, roomNumber)
        .then(response => {
          console.log('getCurrentRoom - response: ', response.data)
          commit('SET_CURRENT_ROOM', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          resolve(response.data)
        })
        .catch(error => {
          console.log('getCurrentRoom: ', error)
          commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
          reject()
        })
    })
  },

  updateTvChannelsListInCurrentRoom({ state, commit, dispatch }, payload) {
    commit('SET_CHANNELS_LOADING', true)

    api.channelLists
      .assignTvChannelListToRooms(payload, [state.currentRoom.number])
      .then(response => {
        dispatch('getCurrentRoom')
        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Room settings has been updated!' })

        commit('SET_CHANNELS_LOADING', false)
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'Room settings has not been updated!' })
      })
      .finally(() => {
        commit('SET_CHANNELS_LOADING', false)
      })
  },

  applyCurrentChannelsListToRooms({ state, commit, dispatch }, payload) {
    commit('SET_CHANNELS_LOADING', true)

    api.channelLists
      .assignTvChannelListToRooms(state.currentRoom.channelList.id, payload)
      .then(response => {
        dispatch('getCurrentRoom')

        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Channels List has been applied!' })
        commit('SET_CHANNELS_LOADING', false)
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'Channels List has not been applied!' })
        commit('SET_CHANNELS_LOADING', false)
      })
  },

  updateGuest({ state, rootState, commit, dispatch }, payload) {
    commit('SET_PMS_LOADING', true)

    api.rooms
      .updateGuestsInfo(rootState.tvApps.currentApp.id, state.currentRoom.number, payload)
      .then(response => {
        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Guest settings has been updated!' })
        commit('SET_PMS_LOADING', false)
      })
      .catch(() => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'Guest settings has not been updated!' })
        commit('SET_PMS_LOADING', false)
      })
  },

  checkInGuest({ state, rootState, commit, dispatch }, payload) {
    commit('SET_PMS_LOADING', true)

    api.rooms
      .checkInGuest(rootState.tvApps.currentApp.id, state.currentRoom.number, payload)
      .then(response => {
        dispatch('getCurrentRoom')
        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Guest settings has been checked in!' })
        commit('SET_PMS_LOADING', false)
      })
      .catch(() => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'Guest settings has not been checked in!' })
        commit('SET_PMS_LOADING', false)
      })
  },

  checkOutGuest({ state, rootState, commit, dispatch }) {
    commit('SET_PMS_LOADING', true)

    api.rooms
      .checkOutGuest(rootState.tvApps.currentApp.id, state.currentRoom.number)
      .then(response => {
        dispatch('getCurrentRoom')
        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Guest settings has been checked out!' })
        commit('SET_PMS_LOADING', false)
        commit('SET_CHECK_OUT_DIALOG', false)
      })
      .catch(() => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'Guest settings has not been checked out!' })
        commit('SET_PMS_LOADING', false)
      })
  },

  updateGeneral({ state, rootState, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_GENERAL_LOADING', true)

      api.rooms
        .patchRoom(rootState.tvApps.currentApp.id, state.currentRoom.number, {
          number: payload.number,
          level: payload.level
        })
        .then(response => {
          dispatch('getCurrentRoom', payload.number)
          commit('SET_NOTIFICATIONS', { type: 'success', message: 'Room settings has been updated!' })
          commit('SET_GENERAL_LOADING', false)
          resolve()
        })
        .catch(() => {
          commit('SET_GENERAL_LOADING', false)
          commit('SET_NOTIFICATIONS', { type: 'error', message: 'Room settings has not been updated!' })
          reject()
        })
    })
  },

  updateLightningSettingsEnabled({ state, rootState, commit, dispatch }, payload) {
    commit('SET_ROOM_SETTINGS_LOADING', true)

    api.rooms
      .patchRoom(rootState.tvApps.currentApp.id, state.currentRoom.number, {
        number: state.currentRoom.number,
        roomSettings: {
          lightModesSettings: {
            enabled: payload
          }
        }
      })
      .then(response => {
        dispatch('getCurrentRoom')

        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Light settings has been updated!' })
        commit('SET_ROOM_SETTINGS_LOADING', false)
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'Light settings has not been updated!' })
      })
  },

  updateTemperatureSettingsEnabled({ state, rootState, commit, dispatch }, payload) {
    commit('SET_ROOM_SETTINGS_LOADING', true)

    api.rooms
      .patchRoom(rootState.tvApps.currentApp.id, state.currentRoom.number, {
        number: state.currentRoom.number,
        roomSettings: {
          temperatureSettings: {
            enabled: payload
          }
        }
      })
      .then(response => {
        dispatch('getCurrentRoom')

        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Temperature settings has been updated!' })
        commit('SET_ROOM_SETTINGS_LOADING', false)
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'Temperature settings has not been updated!' })
        commit('SET_ROOM_SETTINGS_LOADING', false)
      })
  },

  updateAirConditioningSettingsEnabled({ state, rootState, commit, dispatch }, payload) {
    commit('SET_ROOM_SETTINGS_LOADING', true)

    api.rooms
      .patchRoom(rootState.tvApps.currentApp.id, state.currentRoom.number, {
        number: state.currentRoom.number,
        roomSettings: {
          airConditionSettings: {
            enabled: payload
          }
        }
      })
      .then(response => {
        dispatch('getCurrentRoom')

        commit('SET_NOTIFICATIONS', { type: 'success', message: 'AC settings has been updated!' })
        commit('SET_ROOM_SETTINGS_LOADING', false)
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'AC settings has not been updated!' })
        commit('SET_ROOM_SETTINGS_LOADING', false)
      })
  },

  updateRoomSettingsEnabled({ state, rootState, commit, dispatch }, payload) {
    commit('SET_ROOM_SETTINGS_LOADING', true)

    api.rooms
      .patchRoom(rootState.tvApps.currentApp.id, state.currentRoom.number, {
        number: state.currentRoom.number,
        roomSettings: {
          enabled: payload
        }
      })
      .then(response => {
        dispatch('getCurrentRoom')

        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Room settings has been updated!' })
        commit('SET_ROOM_SETTINGS_LOADING', false)
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'Room settings has not been updated!' })
        commit('SET_ROOM_SETTINGS_LOADING', false)
      })
  },

  updateAuth({ state, rootState, commit, dispatch }, payload) {
    commit('SET_ROOM_SETTINGS_LOADING', true)

    api.rooms
      .updateAuth(rootState.tvApps.currentApp.id, state.currentRoom.number, {
        login: payload.login,
        password: payload.password
      })
      .then(response => {
        commit('SET_AUTH_DIALOG', false)
        commit('SET_ROOM_SETTINGS_LOADING', false)
        dispatch('getCurrentRoom')
        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Room settings has been updated!' })
      })
      .catch(() => {
        commit('SET_ROOM_SETTINGS_LOADING', false)
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'Room settings has not been updated!' })
      })
  },

  updateIpAddress({ state, rootState, commit, dispatch }, payload) {
    commit('SET_ROOM_SETTINGS_LOADING', true)

    api.rooms
      .patchRoom(rootState.tvApps.currentApp.id, state.currentRoom.number, {
        ipAddress: payload
      })
      .then(response => {
        commit('SET_IP_DIALOG', false)
        commit('SET_ROOM_SETTINGS_LOADING', false)
        dispatch('getCurrentRoom')
        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Room settings has been updated!' })
      })
      .catch(() => {
        commit('SET_ROOM_SETTINGS_LOADING', false)
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'Room settings has not been updated!' })
      })
  },

  applyRoomSettingsToRooms({ state, rootState, commit, dispatch }, payload) {
    commit('SET_ROOM_SETTINGS_LOADING', true)

    api.rooms
      .copyRoomSettings(rootState.tvApps.currentApp.id, state.currentRoom.number, payload)
      .then(response => {
        dispatch('getCurrentRoom')

        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Room Settings has been applied!' })
        commit('SET_ROOM_SETTINGS_LOADING', false)
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'Room Settings has not been applied!' })
        commit('SET_ROOM_SETTINGS_LOADING', false)
      })
  },

  applyLightModesToRooms({ state, commit, dispatch }, payload) {
    commit('SET_LIGHT_MODES_LOADING', true)
    var error = false

    Promise.all(
      state.currentRoom.roomSettings.lightModesSettings.modes.forEach(({ id }) => {
        return api.lightModes.assignLightModeToRooms(id, payload)
      })
    )
      .catch(error => {
        error = true
      })
      .finally(() => {
        dispatch('getCurrentRoom')
        if (error) commit('SET_NOTIFICATIONS', { type: 'error', message: 'There was a problem while applying the light modes.' })
        else commit('SET_NOTIFICATIONS', { type: 'success', message: 'Light modes have been applied!' })
        commit('SET_LIGHT_MODES_LOADING', false)
      })
  },

  removeLightMode({ state, commit, dispatch }) {
    commit('SET_LIGHT_MODES_LOADING', true)

    api.lightModes
      .deleteLightMode(state.lightModeToRemove.id)
      .then(response => {
        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Light mode has been removed!' })

        commit('SET_LIGHT_MODE_TO_REMOVE', null)
        commit('SET_LIGHT_MODES_LOADING', false)

        dispatch('getCurrentRoom')
      })
      .catch(() => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'Light mode has not been removed!' })
        commit('SET_LIGHT_MODES_LOADING', false)
      })
  },

  disconnectDevice({ state, rootState, commit, dispatch }) {
    commit('SET_DEVICES_LOADING', true)

    dispatch('devices/disconnectDeviceFromRoom', state.deviceToDisconnect.mac, { root: true })
      .then(response => {
        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Device has been disconnected!' })

        commit('SET_DEVICE_TO_DISCONNECT', null)
        commit('SET_DEVICES_LOADING', false)

        dispatch('getCurrentRoom')
      })
      .catch(() => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: 'Device has not disconnected!' })
        commit('SET_DEVICES_LOADING', false)
      })
  }
}

// Mutations
const mutations = {
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },
  SET_CURRENT_ROOM(state, payload) {
    state.currentRoom = payload

    if (payload) localStorage.setItem('currentRoom', JSON.stringify(payload))
    else localStorage.removeItem('currentRoom')
  },
  SET_PMS_LOADING(state, payload) {
    state.isLoadingGuestInfo = payload
  },
  SET_GENERAL_LOADING(state, payload) {
    state.isLoadingGeneral = payload
  },
  SET_DEVICES_LOADING(state, payload) {
    state.isLoadingDevices = payload
  },
  SET_CHANNELS_LOADING(state, payload) {
    state.isLoadingTvChannels = payload
  },
  SET_ROOM_SETTINGS_LOADING(state, payload) {
    state.isLoadingSmartRoomSettings = payload
  },
  SET_LIGHT_MODES_LOADING(state, payload) {
    state.isLoadingLightningSettings = payload
  },
  SET_DEVICE_TO_DISCONNECT(state, payload) {
    state.deviceToDisconnect = payload
  },
  SET_ASSING_DEVICES_DIALOG(state, payload) {
    state.showAssignDevicesDialog = payload
  },
  SET_APPLY_CHANNELS_LIST_DIALOG(state, payload) {
    state.showApplyChannelListDialog = payload
  },
  SET_APPLY_LIGHT_MODES_DIALOG(state, payload) {
    state.showApplyLightModesDialog = payload
  },
  SET_APPLY_ROOM_SETTINGS_DIALOG(state, payload) {
    state.showApplyRoomSettingsDialog = payload
  },
  SET_LIGHT_MODE_DIALOG(state, payload) {
    state.showLightModeDialog = payload
  },
  SET_IP_DIALOG(state, payload) {
    state.showIpDialog = payload
  },
  SET_AUTH_DIALOG(state, payload) {
    state.showAuthDialog = payload
  },
  SET_LIGHT_MODE_TO_EDIT(state, payload) {
    state.lightModeToEdit = payload
  },
  SET_LIGHT_MODE_TO_REMOVE(state, payload) {
    state.lightModeToRemove = payload
  },
  SET_CHECK_OUT_DIALOG(state, payload) {
    state.showCheckOutDialog = payload
  }
}

export default {
  strict: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
