import Vue from 'vue'
import axios from 'axios'
import api from '@/api'

const CancelToken = axios.CancelToken
const source = CancelToken.source()

const state = {
  loadingItems: false,
  requestedItemsArray: [],
  itemsToUpload: [],
  items: [],
  filteredItems: [],
  selectedItems: [],
  uploadItemProgress: [],
  uploadGeneralItemProgress: {
    loaded: 0,
    total: 0,
    progress: 0
  },
  itemRequestsCounter: 0,
  uploadedItemsCouter: 0,
  uploadingItemsCouter: 0,
  collapseItemUploadModal: false,
  showUploadItemLoader: false,
  showUploadItemModal: false,
  showRemoveItemModal: false,
  showRemoveItemsModal: false
}

const mutations = {
  /**
   * Set item loading state
   *
   * @param {Boolean} payload
   */
  SET_ITEMS_LOADING(state, payload) {
    state.loadingItems = payload
  },

  /**
   * Store items to upload array to the app state.
   *
   * @param {Object, Null} payload
   */
  SET_ITEMS_TO_UPLOAD(state, payload) {
    if (!payload) {
      state.itemsToUpload = []
      return
    }
    state.itemsToUpload.push(payload)
  },

  /**
   * Store items array to the app state.
   *
   * @param {Array} payload
   */
  SET_ITEMS(state, payload) {
    state.items = payload
  },

  /**
   * Store items to filtered array.
   *
   * @param {Array} payload
   */
  SET_FILTERED_ITEMS(state, payload) {
    state.filteredItems = payload
  },

  /**
   * Add item to the filteredItems array.
   *
   * @param {Object} payload
   */
  ADD_FILTERED_ITEMS(state, payload) {
    state.filteredItems.unshift(payload)
  },

  /**
   * Add item to the items array.
   *
   * @param {Object} payload
   */
  ADD_ITEM(state, payload) {
    state.items.unshift(payload)
  },

  /**
   * Edit item
   *
   * @param {Object} payload
   */
  EDIT_ITEM(state, payload) {
    const updateItem = (item, payload) => {
      if (item.id === payload.id) {
        item.name = payload.name
        item.description = payload.description
      }
    }

    state.items.find(item => updateItem(item, payload))
    state.filteredItems.find(item => updateItem(item, payload))
    state.selectedItems.find(item => updateItem(item, payload))
  },

  /**
   * Remove item
   *
   * @param {Number} payload
   */
  REMOVE_ITEM(state, payload) {
    state.items = state.items.filter(item => item.id !== payload)
    state.filteredItems = state.filteredItems.filter(item => item.id !== payload)
    state.selectedItems = state.selectedItems.filter(item => item.id !== payload)
  },

  /**
   * Remove items
   *
   * @param {Array} payload
   */
  REMOVE_ITEMS(state, payload) {
    state.items.forEach(() => {
      state.items = state.items.filter(item => !payload.includes(item.id))
      state.filteredItems = state.filteredItems.filter(item => !payload.includes(item.id))
      state.selectedItems = state.selectedItems.filter(item => !payload.includes(item.id))
    })
  },

  /**
   * Add item to the selectedItems array or remove selected item from the selectedItems array.
   *
   * @param {Object} payload
   */
  SELECT_OR_UNSELECT_ITEM(state, payload) {
    const items = state.selectedItems
    const isAdded = items.find(x => x.id === payload.id)

    if (isAdded) {
      state.selectedItems = items.filter(item => item.id !== payload.id)
    } else {
      // If you can select more the only one item
      if (!state.multiselectable && state.selectable) state.selectedItems = []
      state.selectedItems.push(payload)
    }
  },

  /**
   * Clear selected item from the selectedItems array.
   *
   */
  CLEAR_SELECTED_ITEMS(state) {
    state.selectedItems = []
  },

  /**
   * Set selected items in selectedItems array.
   *
   */
  SET_SELECTED_ITEMS(state, payload) {
    state.selectedItems = payload
  },

  /**
   * Filter items by name
   *
   * @param {String} payload
   */
  FILTER_ITEMS(state, payload) {
    if (!payload || payload === '{}') {
      state.filteredItems = []
    } else {
      state.filteredItems = state.items.filter(item => {
        const name = item.name
        if (name) return name.toLowerCase().includes(payload.toLowerCase())
      })
      // if (rootState.selectedFolder) {
      //   state.filteredItems = state.filteredItems.filter(item => {
      //     let name = item.name
      //     if (name) return name.toLowerCase().includes(payload.toLowerCase())
      //   })
      // } else {
      //   state.filteredItems = state.items.filter(item => {
      //     let name = item.name
      //     if (name) return name.toLowerCase().includes(payload.toLowerCase())
      //   })
      // }
    }
  },

  /**
   * Sort items alphabetically
   *
   * @param {String} payload
   */
  SORT_ITEMS_ALPHABETICALLY(state, payload) {
    if (!payload) return
    const array = state.filteredItems.length ? state.filteredItems : state.items
    state.filteredItems = payload === 'A-Z' ? array.sort((a, b) => (b.name > a.name ? 1 : -1)) : array.sort((a, b) => (b.name < a.name ? 1 : -1))
  },

  /**
   * Sort items alphabetically
   *
   * @param {String} payload
   */
  SORT_ITEMS_BY_CREATION_DATE(state, payload) {
    if (!payload) return
    const array = state.filteredItems.length ? state.filteredItems : state.items
    state.filteredItems = payload === 'Newest' ? array.sort((a, b) => (b.createDate > a.createDate ? 1 : -1)) : array.sort((a, b) => (b.createDate < a.createDate ? 1 : -1))
  },

  /**
   * Set uploadItemProgress state
   *
   * @param {Object, Null} payload
   */
  SET_UPLOAD_ITEM_PROGRESS(state, payload) {
    console.log('SET_UPLOAD_ITEM_PROGRESS', payload, state.uploadGeneralItemProgress.progress)
    if (!payload) {
      state.uploadItemProgress = []
      return
    }
    state.uploadItemProgress.push(payload)
  },

  /**
   * Update item upload progress
   *
   * @param {Object} payload
   */
  UPDATE_UPLOAD_ITEM_PROGRESS(state, payload) {
    state.uploadItemProgress.find(item => {
      if (item.index === payload.index) {
        Vue.set(item, 'loaded', payload.loaded)
        Vue.set(item, 'total', payload.total)
        Vue.set(item, 'progress', Math.round((payload.loaded / payload.total) * 100))
      }
    })
  },

  /**
   * Clear item upload progress
   *
   */
  CLEAR_UPLOAD_ITEM_PROGRESS(state) {
    state.uploadItemProgress = []
  },

  /**
   * Update general items upload progress
   *
   */
  UPDATE_GENERAL_UPLOAD_ITEM_PROGRESS(state) {
    console.log('UPDATE_GENERAL_UPLOAD_ITEM_PROGRESS')
    state.uploadItemProgress.forEach(item => {
      Vue.set(state.uploadGeneralItemProgress, 'loaded', state.uploadGeneralItemProgress.loaded + item.loaded)
      Vue.set(state.uploadGeneralItemProgress, 'total', state.uploadGeneralItemProgress.total + item.total)
    })

    const progress = state.uploadGeneralItemProgress.total ? Math.round((state.uploadGeneralItemProgress.loaded / state.uploadGeneralItemProgress.total) * 100) : 0

    Vue.set(state.uploadGeneralItemProgress, 'progress', progress)
  },

  /**
   * Clear general items upload progress
   *
   */
  CLEAR_GENERAL_UPLOAD_ITEM_PROGRESS(state) {
    state.uploadGeneralItemProgress = {
      loaded: 0,
      total: 0,
      progress: 0
    }
  },

  /**
   * Change uploaded items upload status
   *
   * @param {String} payload
   */
  UPDATE_ITEM_UPLOAD_STATUS(state, payload) {
    const name = payload.name
    const status = payload.status

    state.itemsToUpload.find(item => {
      if (item.name === name) Vue.set(item, 'status', status)
    })
  },

  /**
   * Add new request
   *
   * @param {Object} payload
   */
  ADD_REQUEST_ITEM_TO_REQUEST_ARRAY(state, payload) {
    state.requestedItemsArray.push(payload)
  },

  /**
   * Clear new request array
   *
   */
  CLEAR_REQUEST_ITEM_TO_REQUEST_ARRAY(state) {
    state.requestedItemsArray = []
  },

  /**
   * Count uploaded items
   *
   */
  COUNT_UPLOADED_ITEMS(state) {
    state.uploadedItemsCouter++
  },

  /**
   * Clean uploaded items
   *
   */
  CLEAR_UPLOADED_ITEMS_COUNTER(state) {
    state.uploadedItemsCouter = 0
  },

  /**
   * Count uploading items
   *
   */
  COUNT_UPLOADING_ITEMS(state) {
    state.uploadingItemsCouter++
  },

  /**
   * Clean uploading items
   *
   */
  CLEAR_UPLOADING_ITEMS_COUNTER(state) {
    state.uploadingItemsCouter = 0
  },

  /**
   * Clear items
   *
   */
  CLEAR_ITEMS(state, payload) {
    state.items = []
    state.filteredItems = []
  },

  /**
   * Count item requests
   *
   */
  COUNT_ITEM_REQUESTS(state) {
    state.itemRequestsCounter++
  },

  /**
   * Set collapseItemUploadModal state
   *
   * @param {Boolean} payload
   */
  COLLAPSE_ITEM_UPLOAD_MODAL(state, payload) {
    state.collapseItemUploadModal = payload
  },

  /**
   * Set showUploadItemModal state
   *
   * @param {Boolean} payload
   */
  TOGGLE_UPLOAD_ITEM_MODAL(state, payload) {
    state.showUploadItemModal = payload
    if (payload) state.collapseItemUploadModal = false
  },

  /**
   * Set showUploadItemModal state
   *
   * @param {Boolean} payload
   */
  TOGGLE_UPLOAD_ITEM_LOADER(state, payload) {
    state.showUploadItemLoader = payload
  },

  /**
   * Set showRemoveItemModal state
   *
   * @param {Boolean} payload
   */
  TOGGLE_REMOVE_ITEM_MODAL(state, payload) {
    state.showRemoveItemModal = payload
  },

  /**
   * Set showRemoveItemsModal state
   *
   * @param {Boolean} payload
   */
  TOGGLE_REMOVE_ITEMS_MODAL(state, payload) {
    state.showRemoveItemsModal = payload
  }
}

const actions = {
  /**
   * Fetch items from the server
   */
  fetchItems({ commit, dispatch, rootState }, payload) {
    const currentWorkspaceId = rootState.workspaces.currentWorkspace ? rootState.workspaces.currentWorkspace.id : null

    commit('SET_ITEMS_LOADING', true)

    const type = payload || ''
    api.media
      .getByQuery(currentWorkspaceId, '', '', '', type)
      .then(response => {
        const data = response.data
        commit('SET_ITEMS', data)
        commit('SET_FILTERED_ITEMS', data)
        dispatch('sortItems', 'Newest')
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Items has not been loaded!` })
        console.log('fetchItems: ', error)
      })
      .then(() => {
        commit('SET_ITEMS_LOADING', false)
      })
  },

  /**
   * Fetch items by folder
   *
   * @param {Object} payload
   */
  fetchItemsByFolder({ commit, dispatch, rootState }, payload) {
    const currentWorkspaceId = rootState.workspaces.currentWorkspace ? rootState.workspaces.currentWorkspace.id : null

    if (!payload) return

    api.media
      .getByFolder(currentWorkspaceId, payload.id, payload.mediaType)
      .then(response => {
        const data = response.data
        commit('SET_ITEMS', data)
        commit('SET_FILTERED_ITEMS', data)
        dispatch('sortItems', 'Newest')
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Items has not been loaded!` })
        console.log('fetchItems: ', error)
      })
  },
  /**
   * Fetch item by ID
   */
  fetchItemById({ commit, dispatch }, payload) {
    api.media
      .getById(payload)
      .then(response => {
        const data = response.data
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Item has not been loaded!` })
        console.log('fetchItemById: ', error)
      })
  },

  /**
   * Fetch items by folder
   *
   * @param {Object} payload
   */
  fetchUnsortedItems({ commit, dispatch, rootState }, payload) {
    const currentWorkspaceId = rootState.workspaces.currentWorkspace ? rootState.workspaces.currentWorkspace.id : null
    api.media
      .getUnsortedByFolder(currentWorkspaceId, '')
      .then(response => {
        const data = response.data
        commit('SET_ITEMS', data)
        commit('SET_FILTERED_ITEMS', data)
        dispatch('sortItems', 'Newest')
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Items has not been loaded!` })
        console.log('fetchItems: ', error)
      })
  },

  /**
   * Filter items by folder
   *
   * @param {Number} payload
   */
  filterItemsByFolder({ dispatch }, payload) {
    if (payload === 0) dispatch('fetchItems')
    else if (payload === 1) dispatch('fetchUnsortedItems')
    else dispatch('fetchItemsByFolder', { id: payload, mediaType: '' })
  },

  /**
   * Cancel all upload items requests
   *
   */
  cancelAllItemsRequests() {
    source.cancel('The request has been canceled.')
  },

  /**
   * Create upload item progress callback object for each item
   *
   * @param {Number} payload - files length
   */
  createUploadItemProgress({ commit }, payload) {
    for (let i = 0; i < payload; i++) {
      console.log('createUploadItemProgress', state.uploadGeneralItemProgress.progress, i + state.requestedItemsArray.length + 1, i)
      const index = state.uploadGeneralItemProgress.progress ? i + state.requestedItemsArray.length + 1 : i
      commit('SET_UPLOAD_ITEM_PROGRESS', { index, total: 0, loaded: 0, progress: 0 })
    }
  },

  /**
   * Add new file to the request array
   *
   */
  createNewUploadItemRequest({ commit, dispatch }) {
    console.log('createNewUploadItemRequest')
    const nextItemIndex = state.requestedItemsArray.length
    const items = state.itemsToUpload

    if (items.length > nextItemIndex) {
      commit(
        'ADD_REQUEST_ITEM_TO_REQUEST_ARRAY',
        dispatch('createUploadItemRequest', {
          file: items[nextItemIndex],
          index: nextItemIndex,
          status: 'loading'
        })
      )
    }
  },

  /**
   * Create upload item requests
   *
   * @param {Object} payload
   */
  createUploadItemRequest({ commit, dispatch, getters, rootState }, payload) {
    console.log('createUploadItemRequest', payload)

    if (!payload) return

    const currentWorkspaceId = rootState.workspaces.currentWorkspace ? rootState.workspaces.currentWorkspace.id : null
    const image = ['image/x-png', 'image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/svg+xml']
    const videoTypes = ['video/mp4', 'video/mov', 'video/wmv', 'video/avi', 'video/flv']

    if (image.indexOf(payload.file.type) > -1) {
      return api.media
        .createMediaObject(currentWorkspaceId, payload.file, {
          requestId: `item-${payload.index}`,
          cancelToken: source.token,
          onUploadProgress: progressEvent => {
            commit('UPDATE_UPLOAD_ITEM_PROGRESS', {
              index: payload.index,
              loaded: progressEvent.loaded,
              total: progressEvent.total
            })
            // commit('UPDATE_GENERAL_UPLOAD_ITEM_PROGRESS')
          }
        })
        .then(response => {
          console.log('createUploadItemRequest - response: ', response)
          // commit('ADD_ITEM', response.data)
          commit('ADD_FILTERED_ITEMS', response.data)
          commit('COUNT_UPLOADED_ITEMS')
          commit('COUNT_UPLOADING_ITEMS')
          commit('UPDATE_ITEM_UPLOAD_STATUS', {
            name: payload.file.name,
            status: 'success'
          })
          dispatch('createNewUploadItemRequest')
          if (getters.uploadingItemsCouter === state.itemsToUpload.length) dispatch('uploadItemsFinished')
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            commit('SET_NOTIFICATIONS', {
              type: 'warning',
              message: `The upload of ${payload.file.name} file has been cancelled!`
            })
          } else {
            commit('SET_NOTIFICATIONS', {
              type: 'error',
              message: `Upsss... ${payload.file.name} file has not been uploaded!`
            })
          }
          commit('UPDATE_ITEM_UPLOAD_STATUS', {
            name: payload.file.name,
            status: 'error'
          })
          commit('COUNT_UPLOADING_ITEMS')
          dispatch('createNewUploadItemRequest')
          if (getters.uploadingItemsCouter === state.itemsToUpload.length) dispatch('uploadItemsFinished')
          console.error('createUploadItemRequest: ', error)
        })
    } else {
      const formData = new FormData()

      formData.append('videoFile', payload.file.file)
      formData.append(
        'videoRequest',
        new Blob(
          [
            JSON.stringify({
              name: payload.file.name,
              duration: 10,
              description: payload.file.description,
              type: payload.file.type,
              size: payload.file.size
            })
          ],
          { type: 'application/json' }
        )
      )

      return api.media
        .createVimeoMediaObject(currentWorkspaceId, formData, {
          requestId: `video-${payload.index}`,
          cancelToken: source.token,
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: progressEvent => {
            commit('UPDATE_UPLOAD_ITEM_PROGRESS', {
              index: payload.index,
              loaded: progressEvent.loaded,
              total: progressEvent.total
            })
            // commit('UPDATE_GENERAL_UPLOAD_ITEM_PROGRESS')
          }
        })
        .then(response => {
          console.log('createUploadItemRequest - response: ', response)
          // commit('ADD_ITEM', response.data)
          commit('ADD_FILTERED_ITEMS', response.data)
          commit('COUNT_UPLOADED_ITEMS')
          commit('COUNT_UPLOADING_ITEMS')
          commit('UPDATE_ITEM_UPLOAD_STATUS', {
            name: payload.file.name,
            status: 'success'
          })
          dispatch('createNewUploadItemRequest')
          if (getters.uploadingItemsCouter === state.itemsToUpload.length) dispatch('uploadItemsFinished')
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            commit('SET_NOTIFICATIONS', {
              type: 'warning',
              message: `The upload of ${payload.file.name} file has been cancelled!`
            })
          } else {
            commit('SET_NOTIFICATIONS', {
              type: 'error',
              message: `Upsss... ${payload.file.name} file has not been uploaded!`
            })
          }
          commit('UPDATE_ITEM_UPLOAD_STATUS', {
            name: payload.file.name,
            status: 'error'
          })
          commit('COUNT_UPLOADING_ITEMS')
          dispatch('createNewUploadItemRequest')
          if (getters.uploadingItemsCouter === state.itemsToUpload.length) dispatch('uploadItemsFinished')
          console.error('createUploadItemRequest: ', error)
        })
    }
  },

  /**
   * Create upload item first requests
   *
   * @param {Object} payload
   */
  createFirstItemsToRequest({ commit, dispatch }, payload) {
    if (payload.length === 1) {
      commit('ADD_REQUEST_ITEM_TO_REQUEST_ARRAY', dispatch('createUploadItemRequest', { file: payload[0], index: 0, status: 'loading' }))
    } else {
      const length = payload.length === 2 ? state.itemRequestsCounter + payload.length : state.itemRequestsCounter + 3

      for (let i = 0; i < length; i++) {
        commit('COUNT_ITEM_REQUESTS')
        commit('ADD_REQUEST_ITEM_TO_REQUEST_ARRAY', dispatch('createUploadItemRequest', { file: payload[i], index: i, status: 'loading' }))
      }
    }
  },

  /**
   * Upload many items
   *
   * @param {Array} payload
   */
  uploadItems({ commit, dispatch }, payload) {
    console.log('uploadItems', payload)
    if (!payload) return

    commit('SET_ITEMS_LOADING', true)

    dispatch('createUploadItemProgress', payload.length)
    dispatch('createFirstItemsToRequest', payload)

    const requests = state.requestedItemsArray
    if (requests.length) axios.all(requests).then(axios.spread(() => {}))
  },

  /**
   * Clear upload stuff
   *
   */
  uploadItemsFinished({ commit }) {
    console.log('RESET')
    commit('CLEAR_REQUEST_ITEM_TO_REQUEST_ARRAY')
    commit('CLEAR_UPLOADING_ITEMS_COUNTER')
    commit('CLEAR_UPLOADED_ITEMS_COUNTER')
    commit('CLEAR_UPLOAD_ITEM_PROGRESS')
    commit('CLEAR_GENERAL_UPLOAD_ITEM_PROGRESS')
    commit('COLLAPSE_ITEM_UPLOAD_MODAL', false)
    commit('TOGGLE_UPLOAD_ITEM_MODAL', false)
    commit('SET_ITEMS_LOADING', false)
    commit('SET_ITEMS_TO_UPLOAD', null)
    commit('SET_UPLOAD_ITEM_PROGRESS', null)
  },

  /**
   * Object to edit
   *
   * @param {Object} payload
   */
  editImage({ commit }, payload) {
    if (!payload) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Item has not been edited!` })
      return
    }

    commit('SET_ITEMS_LOADING', true)

    api.media
      .updateImageMediaObject(payload.id, payload.obj)
      .then(response => {
        commit('EDIT_ITEM', response.data)
        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Item edited!' })
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Item has not been edited!` })
        console.log('editItem: ', error)
      })
      .then(() => {
        commit('TOGGLE_EDIT_MEDIUM_MODAL', false)
        commit('SET_ITEMS_LOADING', false)
      })
  },

  /**
   * Object to edit
   *
   * @param {Object} payload
   */
  editVideo({ commit }, payload) {
    if (!payload) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Item has not been edited!` })
      return
    }

    commit('SET_ITEMS_LOADING', true)

    api.media
      .updateVimeoMediaObject(payload.id, payload.obj)
      .then(response => {
        commit('EDIT_ITEM', response.data)
        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Item edited!' })
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Item has not been edited!` })
        console.log('editItem: ', error)
      })
      .then(() => {
        commit('TOGGLE_EDIT_MEDIUM_MODAL', false)
        commit('SET_ITEMS_LOADING', false)
      })
  },

  /**
   * Object to edit
   *
   * @param {Object} payload
   */
  updateDuration({ commit, dispatch }, payload) {
    if (!payload) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Item has not been edited!` })
      return
    }

    commit('SET_ITEMS_LOADING', true)

    api.media
      .updateMediaObjectDuration(payload.playlistId, payload.mediaId, payload.duration)
      .then(response => {
        dispatch('media/fetchItems', null, { root: true })
        commit('EDIT_ITEM', response.data)
        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Item edited!' })
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Item has not been edited!` })
        console.log('editItem: ', error)
      })
      .then(() => {
        commit('TOGGLE_EDIT_MEDIUM_MODAL', false)
        commit('SET_ITEMS_LOADING', false)
      })
  },

  /**
   * Remove item by id.
   *
   * @param {String, Number} payload
   */
  removeItem({ commit }, payload) {
    if (!payload) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Item has not been removed!` })
      return
    }

    commit('SET_ITEMS_LOADING', true)
    commit('appshell/startLoading', false, { root: true })

    api.media
      .deleteMediaObject(payload)
      .then(() => {
        commit('REMOVE_ITEM', payload)
        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Item deleted!' })
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Item has not been removed!` })
        console.log('removeItem: ', error)
      })
      .then(() => {
        commit('TOGGLE_EDIT_MEDIUM_MODAL', false)
        commit('SET_ITEMS_LOADING', false)
        commit('CLEAR_SELECTED_ITEMS')
        commit('appshell/endLoading', false, { root: true })
      })

    commit('TOGGLE_REMOVE_ITEM_MODAL', false)
  },

  /**
   * Remove selected items.
   *
   * @param {Number} payload
   */
  removeItems({ commit }, payload) {
    if (!payload) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Items have not been removed!` })
      return
    }

    const ids = payload.map(obj => obj.id)

    if (!ids.length) {
      commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Items have not been removed!` })
      return
    }

    commit('SET_ITEMS_LOADING', true)
    commit('appshell/startLoading', false, { root: true })

    api.media
      .deleteMediaObjects(ids)
      .then(() => {
        commit('REMOVE_ITEMS', ids)
        commit('SET_NOTIFICATIONS', { type: 'success', message: 'Items deleted!' })
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Items have not been removed - it may be published somewhere...` })
        console.log('removeItems: ', error)
      })
      .then(() => {
        commit('CLEAR_SELECTED_ITEMS')
        commit('SET_ITEMS_LOADING', false)
        commit('TOGGLE_REMOVE_ITEMS_MODAL', false)
        commit('appshell/endLoading', false, { root: true })
      })
  },

  /**
   * Remove all items assign to the folder.
   *
   * @param {Number} payload
   */
  removeItemsFromFolder({ state, commit }, payload) {
    if (!payload) return

    const ids = []
    if (state.items.length) {
      state.items.find(item => {
        if (item.mediaFolder) {
          if (item.mediaFolder.id.toString() === payload.toString()) ids.push(item.id)
        }
      })
    } else {
      state.filteredItems.find(item => {
        if (item.mediaFolder) {
          if (item.mediaFolder.id.toString() === payload.toString()) ids.push(item.id)
        }
      })
    }

    if (!ids.length) return

    api.media
      .deleteMediaObjects(ids)
      .then(() => {
        commit('REMOVE_ITEMS', ids)
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Items have not been removed!` })
        console.log('removeItemsFromFolder: ', error)
      })
      .then(() => {
        commit('CLEAR_SELECTED_ITEMS')
      })
  },

  /**
   * Sort items
   *
   * @param {String} payload
   */
  sortItems({ commit }, payload) {
    if (!payload) return

    commit('SET_SORT_TYPE', payload)

    if (payload === 'A-Z' || payload === 'Z-A') commit('SORT_ITEMS_ALPHABETICALLY', payload)
    else commit('SORT_ITEMS_BY_CREATION_DATE', payload)
  }
}

const getters = {
  loadingItems: state => state.loadingItems,
  itemsToUpload: state => state.itemsToUpload,
  items: state => state.items,
  filteredItems: state => (!state.selectable ? state.filteredItems : state.filteredItems.filter(o => (state.selectableMediaType === 'IMAGE_AND_VIDEO' ? o.mediaType === 'IMAGE' || o.mediaType === 'VIDEO' : o.mediaType === state.selectableMediaType))),
  selectedItems: state => state.selectedItems,
  uploadItemProgress: state => state.uploadItemProgress,
  uploadGeneralItemProgress: state => state.uploadGeneralItemProgress,
  uploadedItemsCouter: state => state.uploadedItemsCouter,
  uploadingItemsCouter: state => state.uploadingItemsCouter,
  collapseItemUploadModal: state => state.collapseItemUploadModal,
  showUploadItemLoader: state => state.showUploadItemLoader,
  showUploadItemModal: state => state.showUploadItemModal,
  showRemoveItemModal: state => state.showRemoveItemModal,
  showRemoveItemsModal: state => state.showRemoveItemsModal
}

export const items = { state, mutations, actions, getters }
