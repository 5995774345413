import axios from 'axios'

export default {
  getChannelById: channelId => axios.get(`/api/v1/tv-app/channels/${channelId}`),
  updateTvChannel: (channelId, data) => axios.put(`/api/v1/tv-app/channels/${channelId}`, data),
  createTvChannel: (listId, data) => axios.post(`/api/v1/tv-app/channels/${listId}`, data),
  deleteChannelFromList: (listId, channelId) => axios.delete(`/api/v1/tv-app/channels/${listId}/${channelId}`),
  getAllCountries: () => axios.get(`/api/v1/tv-app/channels/countries`),
  getAllGenres: () => axios.get(`/api/v1/tv-app/channels/genres`),
  listChannelsAvailableToImport: () => axios.get(`/api/v1/tv-app/channels/import`),
  importChannelsToList: listId => axios.put(`/api/v1/tv-app/channels/import/${listId}`),
  getAllLanguages: () => axios.get(`/api/v1/tv-app/channels/languages`),
  getChannelsByList: listId => axios.get(`/api/v1/tv-app/channels/list/${listId}`),
  updateChannelNumbersInList: listId => axios.put(`/api/v1/tv-app/channels/update-number/${listId}`)
}
