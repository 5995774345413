const device = [
  {
    name: 'Workspace',
    path: 'workspace',
    meta: {
      auth: true,
      permission: []
    },
    components: {
      default: () => import(/* webpackChunkName: "ObjectBtRouterView" */ '@/components/BtRouterView'),
      navigation: () => import(/* webpackChunkName: "ObjectNavigation" */ '@/views/workspace/Navigation')
    },
    children: [
      {
        name: 'WorkspaceDashboard',
        path: ':workspaceId',
        redirect: { name: 'WorkspaceDevices' },
        meta: {
          auth: true,
          permission: []
        },
        component: () => import(/* webpackChunkName: "WorkspaceDashboard" */ '@/views/workspace/Dashboard')
      },
      {
        name: 'WorkspaceDevices',
        path: ':workspaceId/devices',
        meta: {
          auth: true,
          permission: ['ROLE_DEVICE_VIEW', 'ROLE_DEVICE_FULL_MANAGEMENT']
        },
        component: () => import(/* webpackChunkName: "WorkspaceDevices" */ '@/views/workspace/Devices')
      },
      {
        name: 'WorkspaceApps',
        path: ':workspaceId/apps',
        meta: {
          auth: true,
          permission: ['ROLE_TV_APP_VIEW', 'ROLE_DS_APP_VIEW', 'ROLE_APP_FULL_MANAGEMENT']
        },
        component: () => import(/* webpackChunkName: "WorkspaceApps" */ '@/views/workspace/Apps')
      },
      {
        name: 'WorkspaceUsers',
        path: ':workspaceId/users',
        meta: {
          auth: true,
          permission: ['ROLE_USER_FULL_MANAGEMENT', 'ROLE_USER_VIEW']
        },
        component: () => import(/* webpackChunkName: "WorkspaceUsers" */ '@/views/workspace/users/Index')
      },
      {
        name: 'WorkspaceUserSettings',
        path: ':workspaceId/users/:username/general-settings',
        meta: {
          auth: true,
          permission: ['ROLE_USER_FULL_MANAGEMENT', 'ROLE_USER_VIEW']
        },
        component: () => import(/* webpackChunkName: "WorkspaceUserSettings" */ '@/views/workspace/users/Settings')
      }
    ]
  }
]

export default device
