<template lang="pug">
// The component utilizes portal-vue to move it's DOM to the bottom of the document. Otherwise it would
// be possible that another elements show on the top of dialog due to their higher stacking context.

portal(to='dialogs')
  dialog.bt-dialog(:class="classes", :key="name", @click.stop)
    .bt-dialog__overlay(@click="$emit('close')")
    .bt-dialog__inner-flex(:class="{ 'bt-dialog__inner-flex--center': isViewportHighEnough }")

      .bt-dialog__inner-block(ref="block")
        transition(name="fade")
          .bt-dialog__loader(v-show="isLoading")
            bt-loader(:centered="true")
        header.bt-dialog__header(v-if='$slots.header')

          // Mostly a title text
          slot(name='header')

        main.bt-dialog__main
          .bt-dialog__main__inner
            // General content goes here
            question.bt-dialog__icon(v-if="notification === 'question'")
            trash.bt-dialog__icon(v-if="notification === 'delete'")
            floppy-disk.bt-dialog__icon(v-if="notification === 'save'")
            slot

        footer.bt-dialog__footer(v-if="!noFooter")
          bt-button(variant="outline" @click='close', v-if="!noCancel") Close

          // Basically other buttons
          slot(name='footer')

        bt-close(@click="close", v-if="!hideCloseButton")
          svg.icon(v-html="require('@/assets/img/icons/close.svg')")

</template>

<script>
import BtLoader from '@/components/BtLoader/index'
import { mapMutations } from 'vuex'

export default {
  components: {
    FloppyDisk: () => import('@/components/icons/FloppyDisk'),
    Trash: () => import('@/components/icons/Trash'),
    Question: () => import('@/components/icons/Question'),
    BtClose: () => import('@/components/BtClose'),
    BtLoader
  },
  props: {
    /**
     * Additional Classes
     * @type {Object}
     */
    additionalClass: {
      type: Object,
      default: () => {}
    },
    /**
     * Name of dialog
     * @type {String}
     */
    name: {
      default: 'dialog',
      type: String
    },
    /**
     * Set to true for notification or confirmation purposes
     * @type {Boolean, String}
     */
    notification: {
      default: false,
      type: [Boolean, String]
    },
    /**
     * Set to true disable "Cancel" button
     * @type {Boolean}
     */
    noCancel: {
      type: [Boolean],
      default: false
    },
    /**
     * Set to true to remove content paddings
     * @type {Boolean}
     */
    noPadding: {
      default: false,
      type: [Boolean]
    },
    /**
     * Set to true to decrease content paddings
     * @type {Boolean}
     */
    smallPadding: {
      default: false,
      type: [Boolean]
    },
    /**
     * Set to true to remove header and footer padding
     * @type {Object}
     */
    noBorders: {
      default: false,
      type: [Boolean]
    },
    /**
     * Set to true to remove footer
     * @type {Boolean}
     */
    noFooter: {
      default: false,
      type: [Boolean]
    },
    /**
     * Set to true to make dialog fullheight
     * @type {Boolean}
     */
    fullheight: {
      default: false,
      type: [Boolean]
    },
    /**
     * Set to true to append loader
     * @type {Boolean}
     */
    isLoading: {
      type: Boolean,
      default: false
    },
    /**
     * Set to true to make content scrollable
     * @type {Boolean}
     */
    scrollable: {
      type: [Boolean],
      default: false
    },
    /**
     * Set dialog type (contained / wide / fullscreen / notification)
     * @type {String}
     */
    type: {
      type: String,
      default: 'contained'
    },
    /**
     * Set to true to hide close button in right top corner
     * @type {Boolean}
     */
    hideCloseButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      // this property is to set delays on open/close changes, so animations could run before
      // the component disappears from the DOM
      isOpenDelay: false,
      isViewportHighEnough: false
    }
  },
  computed: {
    classes() {
      return this._.merge(
        {
          'bt-dialog--contained': this.type === 'contained',
          'bt-dialog--wide': this.type === 'wide',
          'bt-dialog--notification': this.type === 'notification' || this.notification,
          'bt-dialog--no-padding': this.noPadding,
          'bt-dialog--small-padding': this.smallPadding,
          'bt-dialog--scrollable': this.scrollable,
          'bt-dialog--no-borders': this.noBorders,
          'bt-dialog--fullscreen': this.type === 'fullscreen',
          'bt-dialog--fullheight': this.fullheight
        },
        this.additionalClass
      )
    }
  },
  watch: {
    isOpen(value) {
      setTimeout(() => {
        this.isOpenDelay = value
      })
    }
  },
  created() {
    this.$on('trigger-open', () => {
      this.open()
    })

    this.$on('trigger-close', () => {
      this.close()
    })
    this.TOGGLE_POPUP(null)
  },
  mounted() {
    this.$nextTick(() => {
      const block = this.$refs.block
      if (block) {
        const blockStyle = block.currentStyle || window.getComputedStyle(block)
        const blockMargin = Number(blockStyle.marginTop.replace('px', '')) + Number(blockStyle.marginBottom.replace('px', ''))

        if (block.clientHeight + blockMargin < window.innerHeight) {
          this.isViewportHighEnough = true
        }
      }
    })
  },
  beforeDestroy() {
    this.TOGGLE_POPUP(null)
  },
  methods: {
    ...mapMutations('appshell', ['TOGGLE_POPUP']),
    close() {
      this.isOpen = false
      this.$emit('close')
    },
    open() {
      this.isOpen = true
      this.$emit('open')
    },
    submit() {
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss">
.bt-dialog {
  $block: &;

  @include reset;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include fullheight;
  z-index: 200;
  overflow: auto;

  &--scrollable,
  &--fullheight,
  &--fullscreen {
    overflow: hidden;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include fullheight;
    z-index: 1;
    background-color: rgba($color--global, 0.3);
    @include fullheight;
  }

  &__inner-flex {
    @include reset;
    display: flex;
    position: relative;
    height: 100%;
    justify-content: center;
    align-items: flex-start;

    &--center {
      padding-top: 0;
      align-items: center;
    }

    @include media('<=screen-sm') {
      padding: 0;
    }
  }

  &__inner-block {
    @include reset;
    @include bright-slab;

    position: relative;
    border-radius: $radius--intensive;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    margin: $rhythm * 3;

    #{$block}--contained:not(#{$block}--fullheight):not(#{$block}--fullscreen):not(#{$block}--scrollable):not(#{$block}--no-padding):not(#{$block}--small-padding) & {
      padding: $rhythm * 3 0;
    }

    #{$block}__loader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-color: rgba(#fff, 0.5);
    }
  }

  &__header {
    @include reset;
    @include font--heading--3;

    border-radius: $radius--intensive $radius--intensive 0 0;
    background: $color--white;
    padding: 0 $rhythm * 3;
    height: $dialog-header-height;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-bottom: 0;

    > * {
      margin-bottom: 0;

      &:not(:first-child) {
        margin-left: 1.5rem;
      }
    }

    small {
      font-size: 0.6em;
      margin-left: $rhythm * 2;
      padding-left: $rhythm * 2;
      border-left: 1px solid $color--line;
      color: $color--muted;
    }

    #{$block}--contained & {
      justify-content: center;
    }

    #{$block}--scrollable:not(#{$block}--no-borders) &,
    #{$block}--fullscreen:not(#{$block}--no-borders) &,
    #{$block}--fullheight:not(#{$block}--no-borders) &,
    #{$block}--wide:not(#{$block}--no-borders) & {
      border-bottom: 1px solid $color--grey--light;
    }

    @include media('<=screen-sm') {
      padding-right: $rhythm * 5;
    }
  }

  &__main {
    position: relative;

    &__inner {
      padding: $rhythm * 3;

      @at-root #{$block}--no-padding & {
        padding: 0 !important;
      }

      @at-root #{$block}--fullheight & {
        height: calc((var(--vh, 1vh) * 100) - #{$dialog-header-height} - #{$dialog-footer-height} - #{$rhythm * 6});
      }

      @at-root #{$block}--scrollable & {
        max-height: calc((var(--vh, 1vh) * 100) - #{$dialog-header-height} - #{$dialog-footer-height} - #{$rhythm * 6});
        overflow: auto;
      }

      @at-root #{$block}--contained:not(#{$block}--no-padding):not(#{$block}--small-padding) & {
        max-width: 420px;
        margin: 0 auto;
        min-height: 10vh;
      }
    }

    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }

    #{$block}--centered & {
      max-width: 500px;
      margin: 0 auto;
      padding: 0 $rhythm * 3;
    }
  }

  &__icon {
    width: $rhythm * 12;
    height: $rhythm * 12;
    margin-bottom: $rhythm * 4;
  }

  &__footer {
    @include reset;

    background: $color--white;
    border-radius: 0 0 $radius--intensive $radius--intensive;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    padding: 0 $rhythm * 3;
    height: $dialog-footer-height;
    flex-shrink: 0;

    #{$block}--contained & {
      justify-content: center;
    }

    #{$block}--scrollable:not(#{$block}--no-borders) &,
    #{$block}--fullscreen:not(#{$block}--no-borders) &,
    #{$block}--fullheight:not(#{$block}--no-borders) &,
    #{$block}--wide:not(#{$block}--no-borders) & {
      border-top: 1px solid $color--grey--light;
    }

    @include media('<=screen-sm') {
      padding: $rhythm * 2;
    }
  }

  &__button {
    @include reset;
    @include button;

    & + & {
      margin-left: $rhythm;
    }

    &--primary {
      @include button--primary;
    }
  }

  &__close {
    @include reset;
    position: absolute;
    right: $rhythm * 1.5;
    top: $rhythm * 1.5;
    cursor: pointer;
    display: inline-block;
    width: auto;
    @include transition--primary;

    svg {
      fill: $color--grey;
      width: $rhythm * 1.75;
      height: $rhythm * 1.75;
      display: block;
    }

    &:hover,
    &:focus {
      transform: rotate(90deg);
    }
  }

  &--wide {
    #{$block}__inner-flex {
      padding: calc(#{$navbar-height-lg} + #{$rhythm * 3} + 3vw) 8vw 3vw;
      flex-direction: column;

      #{$block}__inner-block {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: none;

        #{$block}__main {
          flex: 1 1 auto;
          overflow: auto;
        }
      }
    }
  }

  &--narrow {
    #{$block}__header {
      padding: $rhythm * 2;
    }

    #{$block}__main {
      padding: $rhythm * 2;
    }

    #{$block}__footer {
      padding: $rhythm * 2 $rhythm * 2;
    }

    #{$block}__inner-block {
      max-width: $rhythm * 60;
      border-radius: $radius;
    }
  }

  &--notification {
    #{$block}__inner-block {
      max-width: 600px;
    }
    #{$block}__main {
      text-align: center;
    }
  }

  &--fullscreen {
    #{$block}__inner-flex {
      flex-direction: column;

      #{$block}__inner-block {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        width: calc(100% - #{2 * $rhythm});
        max-width: none;
        margin: $rhythm;
        height: calc((var(--vh, 1vh) * 100) - #{$rhythm * 2});

        #{$block}__main {
          flex: 1 1 auto;
        }
      }
    }
  }
}
</style>
