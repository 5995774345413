import axios from 'axios'

export default {
  fetchHotels: () => axios.get('/api/v1/bhs/hotels'),
  getAllHotelInformation: hotelId => axios.get(`/api/v1/bhs/hotels/${hotelId}`),
  createHotel: data => axios.post('/api/v1/bhs/hotel', data),
  updateHotel: (hotelId, data) => axios.put(`/api/v1/bhs/hotel/${hotelId}`, data),
  createRoomsStructure: (hotelId, structures) => axios.post(`/api/v2/bhs/cms/rooms/structure/${hotelId}`, { structures }),
  changeTransitionTime: (hotelId, transitionTime) => axios.put(`/api/v1/bhs/hotel/${hotelId}/transition?transitionTime=` + transitionTime),
  fetchRooms: hotelId => axios.get('/api/v1/bhs/room?hotelId=' + hotelId),
  updateRoom: (hotelId, number, data) => axios.put(`/api/v1/bhs/room/${hotelId}/${number}`, data),
  changeDeviceLocalisation: (hotelId, mac, roomNumber) => axios.put(`/api/v1/bhs/cms/device/localisation/${hotelId}?mac=${mac}&roomNumber=${roomNumber}`),
  createDeviceLocalisation: (hotelId, mac, roomNumber) => axios.put(`/api/v1/bhs/cms/device/undefined/localisation/${hotelId}?mac=${mac}&roomNumber=${roomNumber}`),
  addNewUndefinedDeviceToHotel: (hotelId, sixDigitsKey) => axios.put(`/api/v1/bhs/cms/init?hotelId=${hotelId}&sixDigitsKey=${sixDigitsKey}`),
  disconnectDevice: (hotelId, mac) => axios.put(`/api/v1/bhs/cms/device/disconnect/${hotelId}?mac=${mac}`),
  initNewDevice: data => axios.post('/api/v1/bhs/room/signage/init', data),
  getAllDevicesForHotel: hotelId => axios.get(`/api/v1/bhs/cms/device/${hotelId}`),
  updateLoxonaData: (mac, data) => axios.put(`/api/v1/bhs/cms/device/${mac}`, data),
  // uploadLogo: (base64) => axios.post(`/api/v1/bhs/hotel/logo`, { content: base64 }),
  uploadLogo: (hotelId, url) => axios.post(`/api/v1/bhs/hotel/${hotelId}/logo`, { url: url }),
  uploadBase64: base64 => axios.post('/zermatt/upload/base64', { content: base64 }),
  addBackgroundPhoto: (hotelId, photo) => axios.post(`/api/v1/bhs/hotel/${hotelId}/background-photos`, photo),
  deleteBackgroundPhoto: (hotelId, photoId) => axios.delete(`/api/v1/bhs/hotel/${hotelId}/background-photos/${photoId}`),
  getGallery: hotelId => axios.get(`/api/v1/bhs/gallery/query/${hotelId}`),
  addPhotoToGallery: (hotelId, data) => axios.post(`/api/v1/bhs/gallery/command/${hotelId}`, data),
  deletePhotoFromGallery: (hotelId, photoId) => axios.delete(`/api/v1/bhs/gallery/command/${hotelId}/${photoId}`),
  updateDescription: (hotelId, photoId, description) => axios.put(`/api/v1/bhs/gallery/command/${hotelId}/${photoId}`, { description }),
  createRoom: (hotelId, data) => axios.post(`/api/v1/bhs/room/${hotelId}`, data),
  deleteRoom: (hotelId, roomNr) => axios.delete(`/api/v1/bhs/room/${hotelId}/${roomNr}`),
  getAllInfoPages: () => axios.get('/api/v1/info-pages/'),
  getAllInfoPagesByHotelId: hotelId => axios.get(`/api/v1/info-pages/hotel/${hotelId}`),
  createInfoPage: (hotelId, data) => axios.post(`/api/v1/info-pages/${hotelId}`, data),
  getInfoPageById: id => axios.get(`/api/v1/info-pages/${id}`),
  deleteInfoPage: (hotelId, id) => axios.delete(`/api/v1/info-pages/${hotelId}/${id}`),
  updateInfoPage: (id, data) => axios.patch(`/api/v1/info-pages/${id}`, data),
  deleteDeviceFromHotel: mac => axios.delete(`/api/v1/bhs/cms/device/${mac}`),
  deleteDevices: macs => axios.delete('/api/v1/bhs/cms/device', { data: macs }),
  deleteHotel: hotelId => axios.delete(`/api/v1/bhs/hotel/${hotelId}`),
  deleteRooms: (hotelId, roomNumbers) => axios.delete(`/api/v1/bhs/room/${hotelId}`, { data: roomNumbers }),
  sendAction: (mac, action) => axios.put(`/api/v1/bhs/device-management?mac=${mac}&action=${action}`),
  sendActions: (macs, action) => axios.put(`/api/v1/bhs/device-management/devices?action=${action}&macs=${macs}`),
  lightMode: {
    create: (hotelId, data) => axios.post(`/api/v1/bhs/room/loxone/${hotelId}`, data),
    update: (hotelId, data) => axios.put(`/api/v1/bhs/room/loxone/${hotelId}`, data),
    delete: lightModeId => axios.delete(`/api/v1/bhs/room/loxone/${lightModeId}`),
    assignToRooms: (lightModeId, data) => axios.put(`/api/v1/bhs/room/loxone/assign/${lightModeId}`, data),
    removeFromRooms: (lightModeId, data) => axios.put(`/api/v1/bhs/room/loxone/remove/${lightModeId}`, data)
  },
  roomGroups: {
    createRoomGroup: (hotelId, data) => axios.post(`/api/v1/bhs/room-groups/${hotelId}`, data),
    getById: roomGroupId => axios.get(`/api/v1/bhs/room-groups/${roomGroupId}`),
    getByHotelId: hotelId => axios.get(`/api/v1/bhs/room-groups/hotel/${hotelId}`),
    updateRoomGroup: (roomGroupId, data) => axios.put(`/api/v1/bhs/room-groups/${roomGroupId}`, data),
    deleteRoomGroup: (roomGroupId, deleteRooms) => axios.delete(`/api/v1/bhs/room-groups/${roomGroupId}?deleteRooms=${deleteRooms}`),
    assignRoomsToGroup: (roomGroupId, roomIds) => axios.post(`/api/v1/bhs/room-groups/assign/${roomGroupId}`, roomIds),
    removeRoomsFromGroup: (roomGroupId, roomIds) => axios.delete(`/api/v1/bhs/room-groups/remove/${roomGroupId}`, { data: roomIds })
  },
  roomSettings: {
    get: (hotelId, roomNumber) => axios.get(`/api/v1/bhs/room/settings/${hotelId}/${roomNumber}`),
    update: (hotelId, roomNumber, settings) => axios.put(`/api/v1/bhs/room/settings/${hotelId}/${roomNumber}`, settings),
    updateLightModes: (hotelId, roomNumber, settings) => axios.put(`/api/v1/bhs/room/settings/light-modes/${hotelId}/${roomNumber}`, settings),
    updateAirCondition: (hotelId, roomNumber, settings) => axios.put(`/api/v1/bhs/room/settings/air-condition/${hotelId}/${roomNumber}`, settings),
    updateTemperature: (hotelId, roomNumber, settings) => axios.put(`/api/v1/bhs/room/settings/temperature/${hotelId}/${roomNumber}`, settings)
  }
}
