/**
 * For more info go there: https://github.com/alexsasharegan/vue-functional-data-merge#readme
 */
import { mergeData } from 'vue-functional-data-merge'
import '@/assets/scss/_grid/_row.scss'

const props = {
  tag: {
    type: String,
    default: 'div'
  },
  margin: {
    type: Boolean,
    default: false
  },
  align: {
    type: String,
    default: null
  },
  gutters: {
    type: String,
    default: 'md'
  }
}

export default {
  name: 'Row',
  functional: true,
  props,
  render(h, { props, data, children }) {
    const aligns = props.align ? props.align.split(' ') : null
    const classes = []

    if (aligns) {
      aligns.forEach(item => classes.push(`bt-row--${item}`))
    }

    if (props.margin) classes.push('bt-row--margin')
    classes.push(`bt-row--gutters-${props.gutters}`)

    const componentData = {
      staticClass: 'bt-row',
      class: classes
    }

    return h(props.tag, mergeData(data, componentData), children)
  }
}
