const signIn = [
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "SignIn" */ '@/views/signin/SignIn')
  }
  // {
  // 	path: '/signup',
  // 	name: 'SignUp',
  // 	component: () => import('@/views/signin/SignUp')
  // },
  // {
  // 	path: '/signup-activate',
  // 	name: 'SignUpActivate',
  // 	component: () => import('@/views/signin/SignUpActivate')
  // },
  // {
  // 	path: '/reset-password',
  // 	redirect: '/reset-password/email',
  // 	name: 'ResetPassword',
  // 	component: () => import('@/views/signin/ResetPassword'),
  // 	children: [
  // 		{
  // 			path: 'email',
  // 			name: 'ResetPasswordEmail',
  // 			component: () => import('@/views/signin/ResetPasswordEmail')
  // 		},
  // 		{
  // 			path: 'new',
  // 			name: 'ResetPasswordNew',
  // 			component: () => import('@/views/signin/ResetPasswordNew')
  // 		}
  // 	]
  // }
]

export default signIn
