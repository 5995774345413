export const formFields = {
  data() {
    return {
      localValue: this.value
    }
  },
  props: {
    id: String,
    name: String,
    label: String,
    labelInline: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Object],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validSuccess: {
      type: [Object, Boolean, String],
      default: false
    },
    validError: {
      type: [Object, Boolean, String],
      default: false
    }
  },
  watch: {
    value(value) {
      this.localValue = value
    },
    localValue(value) {
      this.$emit('input', value)
    }
  },
  computed: {
    showErrorMessage() {
      return !!this.$slots['error-message'] && this.validError
    },
    showSuccessMessage() {
      return !!this.$slots['success-message'] && this.validSuccess
    }
  }
}
