import api from '@/api'

const state = {
  user: null,
  showEmailSentModal: false,
  showEmailConfirmationModal: false,
  notifications: null,
  shouldFetchUser: false
}

const mutations = {
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },
  SET_SHOULD_FETCH_USER(state, payload) {
    state.shouldFetchUser = payload
  },
  TOGGLE_EMAIL_CONFIRMATION_MODAL(state, payload) {
    state.showEmailConfirmationModal = payload
  },
  TOGGLE_EMAIL_SENT_MODAL(state, payload) {
    state.showEmailSentModal = payload
  }
}

const actions = {
  fetchUser({ commit }) {
    api.user
      .fetchUser()
      .then(response => {
        commit('SET_USER', response.data)
      })
      .catch(error => {
        console.log('fetchUser: ', error.message)
      })
  },

  updateUser({ commit }, payload) {
    if (!state.user || !payload) return

    api.user
      .editProfile(state.user.username, payload)
      .then(response => {
        commit('SET_USER', response.data)
        commit('SET_SHOULD_FETCH_USER', true)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `User data has been updated!` })
      })
      .catch(error => {
        console.log('updateUser: ', error.message)
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... User data has not been updated!` })
      })
  },

  updateUserContactInformation({ commit }, payload) {
    console.log(state.user, payload)
    if (!state.user || !payload) return

    commit('appshell/startLoading', true, { root: true })

    api.user
      .editUserContactInformation(state.user.username, payload)
      .then(response => {
        commit('SET_USER', response.data)
        commit('SET_SHOULD_FETCH_USER', true)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `User data has been updated!` })
        commit('appshell/startLoading', false, { root: true })
      })
      .catch(error => {
        console.log('updateUser: ', error.message)
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... User data has not been updated!` })
        commit('appshell/startLoading', false, { root: true })
      })
  },

  updateUserInformation({ commit }, payload) {
    if (!state.user || !payload) return

    commit('appshell/startLoading', true, { root: true })

    api.user
      .editUserInformation(state.user.username, payload)
      .then(response => {
        commit('SET_USER', response.data)
        commit('SET_SHOULD_FETCH_USER', true)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `User data has been updated!` })
        commit('appshell/startLoading', false, { root: true })
      })
      .catch(error => {
        console.log('updateUser: ', error.message)
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... User data has not been updated!` })
        commit('appshell/startLoading', false, { root: true })
      })
  },

  updateUserEmail({ commit }, payload) {
    if (!state.user || !payload) return

    commit('appshell/startLoading', true, { root: true })

    api.user
      .editUserEmail(state.user.username, payload)
      .then(response => {
        commit('SET_USER', response.data)
        commit('SET_SHOULD_FETCH_USER', true)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `User data has been updated!` })
        commit('appshell/startLoading', false, { root: true })
      })
      .catch(error => {
        console.log('updateUser: ', error.message)
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... User data has not been updated!` })
        commit('appshell/startLoading', false, { root: true })
      })
  },

  updateThumbnail({ commit, dispatch }, payload) {
    if (!payload) return

    api.liveStream
      .uploadImage({ content: payload })
      .then(response => {
        dispatch('updateUser', {
          address: {
            street: state.user.address.street,
            number: state.user.address.number,
            city: state.user.address.city,
            zipCode: state.user.address.zipCode
          },
          email: state.user.email,
          firstName: state.user.firstName,
          lastName: state.user.lastName,
          gender: state.user.gender,
          phone: state.user.phone,
          thumbnail: response.data,
          website: state.user.website
        })
      })
      .catch(error => {
        console.log('updateThumbnail: ', error.message)
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... User thumbnail has not been updated!` })
      })
  },

  checkPassword({ commit }, payload) {
    if (!payload) return

    api.user
      .checkPassword(payload)
      .then(response => {
        if (response.data) {
          commit('TOGGLE_EMAIL_CONFIRMATION_MODAL', false)
          commit('TOGGLE_EMAIL_SENT_MODAL', true)
          commit('SET_NOTIFICATIONS', { type: 'success', message: `This is valid password!` })
        } else {
          commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... This is not correct password!` })
        }
      })
      .catch(error => {
        console.log('checkPassword: ', error.message)
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... Something went wrong!` })
      })
  },

  changePassword({ commit }, payload) {
    if (!payload) return

    commit('appshell/startLoading', true, { root: true })

    api.user
      .editPassword(payload.oldPassword, payload.newPassword)
      .then(response => {
        commit('SET_SHOULD_FETCH_USER', true)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `The password has been updated!` })
        commit('appshell/startLoading', false, { root: true })
      })
      .catch(error => {
        console.log('changePasword: ', error.message)
        commit('SET_NOTIFICATIONS', { type: 'error', message: `Upsss... The password has not been updated!` })
        commit('appshell/startLoading', false, { root: true })
      })
  }
}

const getters = {
  user(state) {
    return state.user
  },
  showEmailSentModal(state) {
    return state.showEmailSentModal
  },
  showEmailConfirmationModal(state) {
    return state.showEmailConfirmationModal
  },
  notifications(state) {
    return state.notifications
  },
  shouldFetchUser(state) {
    return state.shouldFetchUser
  }
}

export default { namespaced: true, state, getters, actions, mutations }
