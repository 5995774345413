import axios from 'axios'

import bhs from './bhs'
import ds from './ds'
import media from './media'
import user from './user'
import infoPages from './infoPages'
import folders from './folders'
import users from './users'
import workspaces from './workspaces'
import tvApps from './tvApps'
import channelLists from './channelLists'
import devices from './devices'
import initDevice from './initDevice'
import appDeviceManagement from './appDeviceManagement'
import channels from './channels'
import regions from './regions'
import rooms from './rooms'
import lightModes from './lightModes'

export default {
  hotelSuite: bhs,
  ds,
  media,
  user,
  infoPages,
  folders,
  users,
  workspaces,
  tvApps,
  channelLists,
  devices,
  initDevice,
  appDeviceManagement,
  channels,
  regions,
  rooms,
  lightModes
}
