import axios from 'axios'

export default {
  createTvChannelList: (appId, data) => axios.post(`/api/v1/tv-app/channel-list/${appId}`, data),
  getById: listId => axios.get(`/api/v1/tv-app/channel-list/${listId}`),
  deleteTvChannelList: listId => axios.delete(`/api/v1/tv-app/channel-list/${listId}`),
  updateTvChannelList: (listId, data) => axios.patch(`/api/v1/tv-app/channel-list/${listId}`, data),
  getByAppId: appId => axios.get(`/api/v1/tv-app/channel-list/app/${appId}`),
  enableTvChannelList: (appId, listId) => axios.put(`/api/v1/tv-app/channel-list/enable/${appId}/${listId}`),
  assignTvChannelListToRooms: (listId, roomNumbers) => axios.put(`/api/v1/tv-app/channel-list/room/assign/${listId}`, roomNumbers),
  exportTvChannelListToHotelTvApp: (listId, hotelTvAppId) => axios.put(`/api/v1/tv-app/channel-list/export/${listId}?hotelTvAppId=${hotelTvAppId}`)
}
