import api from '@/api'
import router from '../../router'

const state = {
  workspaces: [],
  currentWorkspace: JSON.parse(localStorage.getItem('currentWorkspace')) || null,
  flatWorkspaces: null,
  workspaceDraggingState: false,
  showDeleteWorkspaceDialog: false,
  notifications: null
}

const mutations = {
  SET_WORKSPACES(state, payload) {
    state.workspaces = payload
  },

  SET_FLAT_WORKSPACES(state, payload) {
    state.flatWorkspaces = payload
  },

  SET_CURRENT_WORKSPACES(state, payload) {
    state.currentWorkspace = payload
    if (payload) localStorage.setItem('currentWorkspace', JSON.stringify(payload))
    else localStorage.removeItem('currentWorkspace')
  },

  TOGGLE_CHECKBOX(state, payload) {
    if (!state.flatWorkspaces) return

    state.flatWorkspaces = state.flatWorkspaces.map((el) => {
      if (el.hasOwnProperty('id')) {
        if (el.id === payload.id) el.selected = payload.selected
      } else {
        if (el.mac === payload.id) el.selected = payload.selected
      }

      return el
    })
  },

  TOGGLE_ALL_CHECKBOXES(state, payload) {
    if (!state.flatWorkspaces) return

    const checkAll = (places, selected) => {
      places.forEach((el) => {
        el.selected = selected
        if (el.subPlaces.length) checkAll(el.subPlaces, payload.selected)
      })
    }

    state.flatWorkspaces = state.flatWorkspaces.map((el) => {
      if (el.hasOwnProperty('id')) {
        if (el.id === payload.id) {
          el.selected = payload.selected
          if (el.subPlaces.length) checkAll(el.subPlaces, payload.selected)
        }
      } else {
        if (el.mac === payload.id) el.selected = payload.selected
      }

      return el
    })
  },

  TOGGLE_DELETE_WORKSPACE_DIALOG(state, payload) {
    state.showDeleteWorkspaceDialog = payload
  },

  TOGGLE_WORKSPACE_DRAG_STATE(state, payload) {
    state.workspaceDraggingState = payload
  },

  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  }
}

const actions = {
  fetchWorkspaces({ rootState, commit }) {
    commit('appshell/setDataFetchingState', true, { root: true })

    api.workspaces
      .getPlaceStructure()
      .then((response) => {
        const workspaces = response.data

        if (!state.workspaces.length && rootState.user.data.authority === 'CONTENT_EDITOR') {
          const firstWorkspace = workspaces[0]

          commit('SET_CURRENT_WORKSPACES', firstWorkspace)
          router.push({
            name: 'WorkspaceApps',
            params: { workspaceId: firstWorkspace.id }
          })
        }

        commit('SET_WORKSPACES', workspaces)
        commit('appshell/setDataFetchingState', false, { root: true })
      })
      .catch((error) => {
        commit('appshell/setDataFetchingState', false, { root: true })
        console.log('fetchWorkspaces: ', error)
      })
  },

  getPlaceById({ commit }, payload) {
    api.workspaces
      .getPlace(payload)
      .then((response) => {
        commit('SET_CURRENT_WORKSPACES', response.data)
      })
      .catch((error) => {
        console.log('getPlaceById: ', error)
      })
  },

  addWorkspace({ commit, dispatch }, payload) {
    api.workspaces
      .addNewPlace(payload)
      .then(() => {
        dispatch('fetchWorkspaces')
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: `The new place has been added!`
        })
      })
      .catch((error) => {
        console.log('addWorkspace: ', error)
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: `The new place has not been added!`
        })
      })
  },

  addSubWorkspace({ commit, dispatch }, payload) {
    api.workspaces
      .addNewSubPlace(payload.parentId, payload.name)
      .then(() => {
        dispatch('fetchWorkspaces')
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: `The new place has been added!`
        })
      })
      .catch((error) => {
        console.log('addSubWorkspace: ', error)
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: `The new place has not been added!`
        })
      })
  },

  renameWorkspace({ commit, dispatch }, payload) {
    if (!payload) return

    api.workspaces
      .updatePlace(payload.id, { name: payload.name })
      .then(() => {
        dispatch('fetchWorkspaces')
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: 'The name has been updated!'
        })
      })
      .catch((error) => {
        this.renameFailed()
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: 'The name has not been updated!'
        })
        console.log('renameWorkspace: ', error)
      })
  },

  removeWorkspace({ commit, dispatch }, payload) {
    commit('TOGGLE_DELETE_WORKSPACE_DIALOG', false)

    api.workspaces
      .deletePlace(payload)
      .then(() => {
        dispatch('fetchWorkspaces')
        commit('SET_NOTIFICATIONS', {
          type: 'success',
          message: 'The workspace has been deleted!'
        })
      })
      .catch((error) => {
        commit('SET_NOTIFICATIONS', {
          type: 'error',
          message: 'The workspace has not been deleted!'
        })
        console.log('removeWorkspace: ', error)
      })
  },

  updateWorkspaces({ commit }, payload) {
    const places = JSON.parse(JSON.stringify(payload))
    const createPayload = (places) => {
      places.forEach((place) => {
        Object.getOwnPropertyNames(place).forEach((key) => {
          if (key === 'id') place.placeId = place[key]
          if (key !== 'subPlaces') delete place[key]
        })

        if (place.subPlaces.length) createPayload(place.subPlaces)
      })

      return places
    }

    const result = createPayload(places)

    api.workspaces
      .editPlaceStructure(result)
      .then((response) => {
        commit('SET_WORKSPACES', response.data)
      })
      .catch((error) => {
        console.log('updateWorkspaces: ', error)
      })

    commit('TOGGLE_WORKSPACE_DRAG_STATE', false)
  },

  flatObject({ commit }, payload) {
    if (!payload) return

    const result = []
    const flatten = (input) => {
      if (!input) return

      const stack = [...input]

      while (stack.length) {
        const next = stack.pop()

        if (Array.isArray(next)) {
          stack.push(...next)
        } else {
          result.push(next)
          if (next.hasOwnProperty('devices')) flatten(next.devices)
          if (next.hasOwnProperty('subPlaces')) flatten(next.subPlaces)
        }
      }
      return result.reverse()
    }

    flatten(payload)

    commit('SET_FLAT_WORKSPACES', result)
  }
}

const getters = {
  workspaces(state) {
    return state.workspaces
  },
  flatWorkspaces(state) {
    return state.flatWorkspaces
  },
  currentWorkspace(state) {
    return state.currentWorkspace
  },
  currentWorkspaceId(state) {
    return state.currentWorkspace ? state.currentWorkspace.id : null
  },
  workspaceDraggingState(state) {
    return state.workspaceDraggingState
  },
  isWorkspaceChecked:
    ({ flatWorkspaces }) =>
    (id) => {
      if (!flatWorkspaces) return false
      const workspace = flatWorkspaces.find((el) => el.id === id)
      return workspace.hasOwnProperty('selected') ? workspace.selected : false
    },
  showDeleteWorkspaceDialog(state) {
    return state.showDeleteWorkspaceDialog
  },
  notifications(state) {
    return state.notifications
  }
}

export default { namespaced: true, state, getters, actions, mutations }
