<template lang="pug">
.bt-field(:class="[{ 'field--error' : validError }, { 'field--success' : validSuccess }, { 'field--center' : center }]")

  label.bt-field__label(
    :class="{ 'bt-field__label--inline' : labelInline }"
    :for="id"
    v-show="!hideLabel"
    v-if="label"
  )
    | {{ label }} <slot name="label"></slot>

  .bt-field__input-container(:class="{ 'bt-field__input-container--inline' : labelInline }")
    span.bt-field__input-icon(v-if="showIcon")
      slot(name="icon")
    textarea.bt-field__input.bt-field__input--textarea(
      v-if="textarea"
      :class="[{ 'bt-field__input--success' : validSuccess }, { 'bt-field__input--error' : validError }, { 'bt-field__input--disabled' : disabled }, { 'bt-field__input--icon' : showIcon }]"
      :id="id"
      :name="name"
      :placeholder="placeholder"
      :value="localValue"
      :type="type"
      :disabled="disabled"
      @input="input"
      :maxlength="maxlength"
      @blur="$emit('blur', $event.target.value)"
    )
    input.bt-field__input(
      v-else
      :class="[{ 'bt-field__input--success' : validSuccess }, { 'bt-field__input--error' : validError }, { 'bt-field__input--disabled' : disabled }, { 'bt-field__input--icon' : showIcon }, { 'bt-field__input--large' : large }, { 'bt-field__input--digit' : digit }]"
      :id="id"
      :name="name"
      :placeholder="placeholder"
      :value="localValue"
      :type="type"
      :disabled="disabled"
      :min="min"
      :max="max"
      :maxlength="maxlength"
      @input="input"
      @blur="$emit('blur', $event.target.value)"
    )

  span.bt-field__msg(:class="`bt-field__msg--error`" v-show="showErrorMessage")
    slot(name="error-message")
  span.bt-field__msg(:class="`bt-field__msg--success`" v-show="showSuccessMessage")
    slot(name="success-message")
</template>

<script>
import { formFields } from '@/mixins/form'

const validErrorTypes = ['error', 'success', 'worning', 'info']
const validInputTypes = ['text', 'password', 'email', 'number', 'url', 'tel', 'search', 'range', 'color', 'date', 'time', 'datetime', 'datetime-local', 'month', 'week', 'hidden']

export default {
  name: 'BtInputField',
  mixins: [formFields],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    placeholder: String,
    textarea: {
      type: Boolean,
      default: false
    },
    errorType: {
      type: String,
      validator: (value) => validErrorTypes.includes(value)
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => {
        if (value === 'radio' || value === 'checkbox') {
          console.log('Please, use different component for radio and checkbox types.')
          return false
        }
        return validInputTypes.includes(value)
      }
    },
    large: Boolean,
    digit: Boolean,
    center: Boolean,
    min: Number,
    max: Number,
    maxlength: Number
  },
  computed: {
    showIcon() {
      return !!this.$slots['icon']
    }
  },
  watch: {
    localValue(val) {
      this.localValue = val
      this.$emit('input', this.type === 'number' ? Number(this.localValue) : this.localValue)
    }
  },
  methods: {
    input(event) {
      this.localValue = event.target.value
      this.$emit('input', this.type === 'number' ? Number(this.localValue) : this.localValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.bt-field {
  @include clearfix;
  position: relative;
  margin-bottom: $rhythm;

  // &--success {}

  // &--error {}

  &--center {
    text-align: center;
  }

  &__label {
    @include reset;
    @include font--global;
    font-weight: 600;
    white-space: nowrap;
    margin-bottom: $rhythm;
    width: max-content;

    &--inline {
      line-height: 50px;
      margin-right: $rhythm;
      margin-bottom: 0;
      float: left;
    }
  }

  &__input-icon {
    & {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 14px;
      transform: translate3d(0, -50%, 0);
      width: $rhythm * 2;
      height: $rhythm * 2;
      fill: $color--grey;
      font-size: 11px;
      line-height: 16px;

      .icon {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &__input-container {
    position: relative;
    background-color: $color--white;

    &--inline {
      float: left;
    }
  }

  &__input {
    @include reset;
    @include form-field;
    font-size: 15px;
    width: 100%;
    min-width: 80px;
    height: 50px;
    z-index: 1;
    position: relative;
    background-color: transparent;

    &::placeholder {
      color: $color--grey--dark;
    }

    &:focus {
      border-color: $color--primary;
    }

    &--textarea {
      min-height: 170px;
    }

    &--large {
      height: 80px;
      font-size: $base * 1.5;
    }

    &--digit {
      width: 120px;
      display: inline-block;
      text-align: center;
    }

    &--icon {
      padding-left: $rhythm * 5;
    }

    &--disabled {
      background: $color--grey--light;
      cursor: default;
      pointer-events: none;
    }

    &--success {
      border-color: $color--green;

      &:focus {
        border-color: $color--green;
      }
    }

    &--error {
      border-color: $color--red;

      &:focus {
        border-color: $color--red;
      }
    }
  }

  &__msg {
    font-size: 0.85rem;

    &--error {
      color: $color--red;
    }

    &--success {
      color: $color--green;
    }
  }
}
</style>
