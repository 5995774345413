import _ from 'lodash'
import router from '@/router'

// State
const state = {
  data: {
    apps: []
  },
  activeAppID: localStorage.getItem('activeAppID') || null,
  devices: [],
  activeDeviceID: 0,
  newDevice: {},
  isLoggedIn: !!localStorage.getItem('token'),
  isAuthenticated: localStorage.getItem('auth_token_default') || null
}

// Gettetrs
const getters = {
  // getAllDevices: state => state.devices,
  // getDevice: state => id => {
  // 	return _.find(state.devices, { 'id': id })
  // },
  // getNewDevice: state => state.newDevice,
  // getActiveDevice: state => _.find(state.devices, { 'id': state.activeDeviceID }),
  user: (state) => state.data,
  userRoles: (state) => state.data.roles,
  userAuthority: (state) => state.data.authority,
  getData: (state) => state.data,
  getAllApps: (state) => state.data.apps,
  getActiveApp: (state) => {
    if (state.activeAppID === null) return state.data.apps[0] || {}
    else return _.find(state.data.apps, { uniqueAppId: state.activeAppID }) || {}
  },
  getActiveAppID: (state, getters) => {
    return getters.getActiveApp.uniqueAppId || ''
  },
  getActiveAppName: (state, getters) => {
    return getters.getActiveApp.name || ''
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  }
}

// Actions
const actions = {
  changeActiveApp({ commit, getters, rootGetters }, id) {
    commit('setActiveApp', id)
    if (getters['getActiveAppName'] === 'bhs') {
      router.push({ name: 'Rooms' })
      if (_.isEmpty(rootGetters['hotels/getSelectedHotel'])) {
        commit('hotels/setSelectedHotel', rootGetters['hotels/getHotels'][0], {
          root: true
        })
      }
    } else {
      router.push({ name: 'Dashboard' })
    }
  }
}

// Mutations
const mutations = {
  // addDevice (state, item) {
  // 	let device = item
  // 	device.id = state.devices.length
  // 	state.devices.push(device)
  // 	state.newDevice = {}
  // 	console.log(state.devices)
  // },
  // setNewDeviceProperty (state, data) {
  // 	Object.assign(state.device, data)
  // },
  // setActiveDeviceID (state, id) {
  // 	state.activeDeviceID = id
  // },
  setActiveApp(state, id) {
    state.activeAppID = id
    window.localStorage.setItem('activeAppID', id)
  },
  setUserData(state, data) {
    state.data = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
