// State
const state = {
  selectedHotel: JSON.parse(window.localStorage.getItem('selectedHotel')) || {},
  hotels: []
}

// Gettetrs
const getters = {
  getSelectedHotel: state => state.selectedHotel,
  getHotels: state => state.hotels
}

// Actions
const actions = {
  // ...
}

// Mutations
const mutations = {
  setSelectedHotel(state, data) {
    state.selectedHotel = data
    window.localStorage.setItem('selectedHotel', JSON.stringify(data))
  },
  setHotels(state, data) {
    state.hotels = data
  }
}

export default {
  strict: true,
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
