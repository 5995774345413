Event.prototype.composedPath =
  Event.prototype.composedPath ||
  function() {
    let element = this.target || null
    const pathArr = [element]

    if (!element || !element.parentElement) {
      return []
    }

    while (element.parentElement) {
      element = element.parentElement
      pathArr.unshift(element)
    }

    return pathArr
  }
