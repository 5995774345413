import axios from 'axios'

export default {
  auth: data => axios.post('auth', data),
  refresh: data => axios.get('refresh'),
  // updateData: (data) => axios.put('edit', data),
  // updatePassword: (oldPassword, newPassword) => axios.put(`edit/password?oldPassword=${oldPassword}&newPassword=${newPassword}`),
  // fetchData: () => axios.get('profile'),
  // checkPassword: (data) => axios.post('/checkPassword', data),

  fetchUser: () => axios.get('/api/v1/user-profile'),
  editProfile: (username, data) => axios.put(`/api/v1/user-profile/${username}/edit`, data),
  validatePasswordResetTokenAndChangeNewPassword: (username, token, data) => axios.post(`/api/v1/user-profile/${username}/password/${token}`, data),
  checkPassword: data => axios.post('/api/v1/user-profile/password/check', data),
  editPassword: (oldPassword, newPassword) => axios.put(`/api/v1/user-profile/password/edit?oldPassword=${oldPassword}&newPassword=${newPassword}`),
  resetPassword: data => axios.post('/api/v1/user-profile/password/reset', data),
  editUserContactInformation: (username, data) => axios.put(`/api/v1/user-profile/${username}/edit/contact-information`, data),
  editUserInformation: (username, data) => axios.put(`/api/v1/user-profile/${username}/edit/user-information`, data),
  editUserEmail: (username, data) => axios.put(`/api/v1/user-profile/${username}/edit/email`, data)
}
