import axios from 'axios'

export default {
  getAllRooms: (appId) => axios.get(`/api/v2/bhs/room/${appId}`),
  getRoom: (appId, roomNumber) => axios.get(`/api/v2/bhs/room/${appId}/${roomNumber}`),
  createRoom: (appId, data) => axios.post(`/api/v2/bhs/room/${appId}`, data),
  createRoomsStructure: (appId, data) => axios.post(`/api/v1/bhs/rooms/structure/${appId}`, data),
  deleteRooms: (appId, numbers) => axios.delete(`/api/v2/bhs/room/${appId}`, numbers),
  deleteRoom: (appId, roomNumber) => axios.delete(`/api/v2/bhs/room/${appId}/${roomNumber}`),
  getRoomSettings: (appId, roomNumber) => axios.get(`/api/v2/bhs/room/settings/${appId}/${roomNumber}`),
  patchRoom: (appId, number, data) => axios.patch(`/api/v2/bhs/room/${appId}/${number}`, data),
  assignDeviceToRoom: (appId, mac, room) => axios.put(`api/v1/bhs/device/localisation/${appId}?mac=${mac}&roomNumber=${room}`),
  removeDeviceFromRoom: (mac) => axios.delete(`/api/v1/tv-app/device-management/room/${mac}`),
  getDevicesWithoutRoom: (appId) => axios.get(`/api/v1/tv-app/device-management/${appId}/room`),
  updateAuth: (appId, roomNumber, data) => axios.put(`/api/v2/bhs/room/settings/auth/${appId}/${roomNumber}`, data),
  copyRoomSettings: (appId, roomNumber, data) => axios.put(`/api/v2/bhs/room/settings/copy/${appId}/${roomNumber}`, data),
  updateGuestsInfo: (appId, roomNumber, data) => axios.put(`/api/v1/bhs/cms/pms/${appId}/room/${roomNumber}`, data),
  checkInGuest: (appId, roomNumber, data) => axios.post(`/api/v1/bhs/cms/pms/${appId}/room/${roomNumber}/check-in`, data),
  checkOutGuest: (appId, roomNumber) => axios.post(`/api/v1/bhs/cms/pms/${appId}/room/${roomNumber}/check-out`)
}
