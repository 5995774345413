import Vue from 'vue'
import Vuex from 'vuex'
import undoRedo from 'undo-redo-vuex'
import user from './modules/user'
import users from './modules/users'
import hotels from './modules/hotels'
import appshell from './modules/appshell'
import dsBuilder from './modules/dsBuilder'
import dsBuilderApp from './modules/dsBuilderApp'
import infoPages from './modules/infoPages'
import media from './modules/media'
import workspaces from './modules/workspaces'
import tvApps from './modules/tvApps'
import devices from './modules/devices'
import account from './modules/account'
import rooms from './modules/rooms'
import regions from './modules/regions'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    undoRedo({
      paths: [
        {
          namespace: 'infoPages',
          ignoreMutations: ['setCategory', 'setScreenshot', 'markAsDraft', 'publish']
        },
        {
          namespace: 'dsBuilderApp',
          ignoreMutations: []
        }
      ]
    })
  ],
  modules: {
    appshell,
    user,
    users,
    workspaces,
    hotels,
    dsBuilder,
    dsBuilderApp,
    infoPages,
    media,
    tvApps,
    devices,
    account,
    rooms,
    regions
  }
})

export default store
