// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

// Setup is to decouple integrations from configurations
import './setup/router'
import './setup/axios'
import './setup/validation'
import './setup/auth'
import './setup/lodash'
import './setup/notifications'
import './setup/polyfills'
// Mixins
import './mixins/global/canUserDoIt'
import './mixins/global/getImageUrl'
// Global CSS styles
import '@/assets/scss/style.scss'
import './registerServiceWorker'

import { Column, Container, Row } from '@/components/BtGrid/index.js'

// Application shell
import App from './App'

// GLobal components
import BtButton from '@/components/BtButton/index.js'
import BtDialog from '@/components/BtDialog/index.js'
import BtPopup from '@/components/BtPopup/index.js'
import BtSelect from '@/components/BtSelect/index.js'
import BtInputField from '@/components/BtInputField/index.js'
import ButtonLoader from '@/components/ButtonLoader'
import Card from '@/components/Card'
import Datetime from 'vue-datetime'
import Icon from 'vue-awesome/components/Icon'
import PortalVue from 'portal-vue'
import { Settings } from 'luxon'
import SyncStores from 'sync-stores'

// Third-party dependencies
import Vue from 'vue'
import VueClazyLoad from 'vue-clazy-load'
import VueMoment from 'vue-moment'
import api from './api'

// Services
import router from './router'
import store from './store'

Vue.use(PortalVue)
Settings.defaultLocale = 'en'
Vue.use(VueMoment)
Vue.use(Datetime)
Vue.use(VueClazyLoad)
Vue.use(SyncStores)

Vue.component('Icon', Icon)
Vue.component('ButtonLoader', ButtonLoader)
Vue.component('Card', Card)
Vue.component('BtContainer', Container)
Vue.component('BtRow', Row)
Vue.component('BtCol', Column)
Vue.component('BtSelect', BtSelect)
Vue.component('BtInputField', BtInputField)
Vue.component('BttDialog', BtDialog)
Vue.component('BtPopup', BtPopup)
Vue.component('BtButton', BtButton)

Vue.prototype.$api = api
Vue.config.productionTip = false

Vue.directive('autofocus', {
  inserted: function (el) {
    el.focus()
  }
})

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        event.stopPropagation()
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
    el.clickOutsideEvent = null
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

window.addEventListener('resize', () => {
  vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})
