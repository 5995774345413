import api from '@/api'

// State
const state = {
  topLevelRegions: null,
  childRegions: {}
}

// Gettetrs
const getters = {
  topLevelRegions: state => state.topLevelRegions
}

// Actions
const actions = {
  getTopLevelRegions({ commit }) {
    return new Promise((resolve, reject) => {
      api.regions
        .getTopLevelRegions()
        .then(response => {
          commit('SET_TOP_LEVEL_REGIONS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getChildRegions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.regions
        .getChildRegions(payload.id)
        .then(response => {
          commit('SET_TOP_LEVEL_REGIONS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

// Mutations
const mutations = {
  SET_TOP_LEVEL_REGIONS(state, payload) {
    state.topLevelRegions = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
