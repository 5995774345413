export default [
  {
    path: '/info-pages/wizard',
    name: 'InfoPagesWizard',
    meta: {
      auth: true,
      permission: ['ROLE_TV_APP_VIEW', 'ROLE_DS_APP_VIEW']
    },
    component: () => import(/* webpackChunkName: "InfoPagesWizard" */ '@/views/builder/bhs/Wizard')
  },
  {
    path: '/info-pages/builder',
    name: 'InfoPagesBuilder',
    meta: {
      auth: true,
      permission: ['ROLE_TV_APP_VIEW', 'ROLE_DS_APP_VIEW', 'ROLE_APP_CREATE_EDIT']
    },
    component: () => import(/* webpackChunkName: "InfoPagesBuilder" */ '@/views/builder/bhs/Builder')
  },
  {
    path: '/info-pages/info-page/:id',
    name: 'InfoPagesBuilderEdit',
    props: true,
    meta: {
      auth: true,
      permission: ['ROLE_TV_APP_VIEW', 'ROLE_DS_APP_VIEW', 'ROLE_APP_CREATE_EDIT']
    },
    component: () => import(/* webpackChunkName: "InfoPagesBuilderEdit" */ '@/views/builder/bhs/Builder')
  }
]
