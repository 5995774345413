import api from '@/api'
import Vue from 'vue'

const state = {
  tvApps: [],
  currentApp: JSON.parse(localStorage.getItem('currentApp')) || null,
  showRemoveAppModal: false,
  deviceToRemoveFromApp: null,
  notifications: null,
  devicesWithApp: [],
  publishing: false,
  saving: false,
  showExportAppsModal: false,
  accessCode: null,
  appsWithDevices: [],
  regions: []
}

const mutations = {
  SET_TV_APPS(state, payload) {
    state.tvApps = payload
  },

  SET_CURRENT_APP(state, payload) {
    state.currentApp = payload

    if (payload) localStorage.setItem('currentApp', JSON.stringify(payload))
    else localStorage.removeItem('currentApp')
  },

  TOGGLE_REMOVE_APP_MODAL(state, payload) {
    state.showRemoveAppModal = payload
  },

  SET_DEVICE_TO_REMOVE_FROM_APP(state, payload) {
    state.deviceToRemoveFromApp = payload
  },

  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },

  SET_ENTERTAINMENT(state, { index, payload }) {
    state.currentApp.entertainment[index].enabled = payload
  },

  SET_DEVICES_WITH_APP(state, payload) {
    state.devicesWithApp = payload
  },

  SET_SAVING(state, payload) {
    state.saving = payload
  },

  SET_PUBLISHING(state, payload) {
    state.publishing = payload
  },

  TOGGLE_EXPORT_APP_MODAL(state, payload) {
    state.showExportAppsModal = payload
  },

  SET_ACCESS_CODE(state, payload) {
    state.accessCode = payload
  },

  SET_APP_WITH_DEVICES(state, payload) {
    state.appsWithDevices = payload
  },

  UPDATE_FIELD(state, payload) {
    Vue.set(state.currentApp, payload.field, payload.value)
    localStorage.setItem('currentApp', JSON.stringify(state.currentApp))
  },

  SET_REGIONS(state, payload) {
    state.regions = payload
  }
}

const actions = {
  fetchTvAppsByPlaceId({ commit, rootState }) {
    const currentWorkspaceId = rootState.workspaces.currentWorkspace ? rootState.workspaces.currentWorkspace.id : null

    return new Promise((resolve, reject) => {
      if (!currentWorkspaceId) reject()

      commit('appshell/startSectionLoading', null, { root: true })

      api.tvApps
        .getAppsInPlace(currentWorkspaceId)
        .then(response => {
          console.log('fetchTvAppsByPlaceId - response: ', response.data)
          commit('SET_TV_APPS', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          resolve()
        })
        .catch(error => {
          console.log('fetchTvAppsByPlaceId: ', error)
          commit('appshell/setDataFetchingState', true, { root: true })
          commit('appshell/endSectionLoading', null, { root: true })
          reject()
        })
    })
  },

  getCurrentApp({ commit }, payload) {
    const id = payload || state.currentApp.id

    commit('appshell/startSectionLoading', null, { root: true })

    return new Promise((resolve, reject) => {
      api.tvApps
        .getTvAppById(id)
        .then(response => {
          console.log('getCurrentApp - response: ', response.data)
          commit('SET_CURRENT_APP', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          resolve()
        })
        .catch(error => {
          console.log('getCurrentApp: ', error)
          commit('appshell/endSectionLoading', null, { root: true })
          commit('appshell/setDataFetchingState', true, { root: true })
          reject()
        })
    })
  },

  createTvApp({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const currentWorkspaceId = rootState.workspaces.currentWorkspace ? rootState.workspaces.currentWorkspace.id : null

      if (!payload || !currentWorkspaceId) {
        commit('SET_NOTIFICATIONS', { type: 'error', message: `The new app has not been added!` })
        reject()
      }

      commit('SET_SAVING', true)
      api.tvApps
        .createTvApp(currentWorkspaceId, payload)
        .then(response => {
          console.log('createTvApp: ', response.data)
          commit('SET_NOTIFICATIONS', { type: 'success', message: `The new app has been added!` })
          commit('SET_SAVING', false)
          resolve(response.data)
        })
        .catch(error => {
          console.log('createTvApp: ', error)
          commit('SET_NOTIFICATIONS', { type: 'error', message: `The new app has not been added!` })
          commit('SET_SAVING', false)
          reject()
        })
    })
  },

  createAppDraft({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload) reject()

      commit('SET_SAVING', true)
      api.tvApps
        .createTvAppDraft(state.currentApp.id, payload)
        .then(response => {
          commit('SET_CURRENT_APP', response.data)
          commit('dsBuilderApp/SET_APP', response.data, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: `Draft app saved and created!` })
          commit('SET_SAVING', false)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_NOTIFICATIONS', { type: 'error', message: error.response.data.message })
          commit('SET_SAVING', false)
          reject()
        })
    })
  },

  updateApp({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload) reject()

      commit('SET_SAVING', true)
      api.tvApps
        .updateTvApp(state.currentApp.id, payload)
        .then(response => {
          console.log(response.data)
          commit('SET_CURRENT_APP', response.data)
          commit('SET_NOTIFICATIONS', { type: 'success', message: `App updated` })
          commit('SET_SAVING', false)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_NOTIFICATIONS', { type: 'error', message: error.response.data.message })
          commit('SET_SAVING', false)
          reject()
        })
    })
  },

  publishApp({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_PUBLISHING', true)
      api.tvApps
        .publishTvApp(state.currentApp.id)
        .then(response => {
          commit('SET_CURRENT_APP', response.data)
          commit('dsBuilderApp/SET_APP', response.data, { root: true })
          commit('SET_NOTIFICATIONS', { type: 'success', message: `App published` })
          commit('SET_PUBLISHING', false)
          resolve(response.data)
        })
        .catch(error => {
          commit('SET_NOTIFICATIONS', { type: 'error', message: error.response.data.message })
          commit('SET_PUBLISHING', false)
          reject()
        })
    })
  },

  renameApp({ commit }, payload) {
    if (!payload) return

    api.tvApps
      .updateTvAppName(payload.id, payload.name)
      .then(response => {
        commit('SET_CURRENT_APP', response.data)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `The app has been renamed!` })
      })
      .catch(error => {
        console.log('removeApp: ', error)
        commit('SET_NOTIFICATIONS', { type: 'error', message: `The app has not been renamed!` })
      })
  },

  removeApp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload) reject()

      api.tvApps
        .deleteApp(payload)
        .then(() => {
          resolve()
          commit('TOGGLE_REMOVE_APP_MODAL', false)
          commit('SET_NOTIFICATIONS', { type: 'success', message: `The new app has been deleted!` })
        })
        .catch(error => {
          reject()
          console.log('removeApp: ', error)
          commit('SET_NOTIFICATIONS', { type: 'error', message: `The new app has not been deleted!` })
        })
    })
  },

  exportApps({ commit }, payload) {
    if (!payload) return

    commit('TOGGLE_EXPORT_APP_MODAL', false)

    api.tvApps
      .exportApp(payload.currentAppId, payload.workspaceId)
      .then(() => {
        commit('SET_NOTIFICATIONS', { type: 'success', message: `The app has been exported!` })
      })
      .catch(error => {
        console.log('exportApps: ', error)
        commit('SET_NOTIFICATIONS', { type: 'error', message: `The app has not been exported!` })
      })
  },

  updateAppMenuContent({ commit, state, dispatch }, payload) {
    if (!payload) return

    commit('SET_SAVING', true)
    api.tvApps
      .updateTvAppMenuContent(state.currentApp.id, payload.type, payload.data)
      .then(() => {
        commit('SET_NOTIFICATIONS', { type: 'success', message: `Content updated!` })
        commit('SET_SAVING', false)
        dispatch('getCurrentApp')
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: error.response.data.message })
        commit('SET_SAVING', false)
      })
  },

  updateAppEntertainment({ commit, state }, payload) {
    if (!payload) return

    commit('SET_SAVING', true)
    api.tvApps
      .updateTvAppEntertainment(state.currentApp.id, payload)
      .then(() => {
        // commit('SET_CURRENT_APP', response.data)
        commit('SET_NOTIFICATIONS', { type: 'success', message: `Entertainment updated` })
        commit('SET_SAVING', false)
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: error.response.data.message })
        commit('SET_SAVING', false)
      })
  },

  updateSingleEntertainmentApp({ commit, state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (!payload) reject('No payload...')

      commit('SET_SAVING', true)
      api.tvApps
        .updateTvAppEntertainmentById(state.currentApp.id, payload.id, payload)
        .then(() => {
          commit('SET_NOTIFICATIONS', { type: 'success', message: `Entertainment app updated` })
          commit('SET_SAVING', false)
          dispatch('getCurrentApp')
          resolve()
        })
        .catch(error => {
          commit('SET_NOTIFICATIONS', { type: 'error', message: error.response.data.message })
          commit('SET_SAVING', false)
          reject(error)
        })
    })
  },

  removeDeviceFromApp({ commit, state, dispatch }, payload) {
    if (!payload) return

    api.tvApps
      .removeDeviceFromApp(state.currentApp.id, payload)
      .then(() => {
        commit('SET_NOTIFICATIONS', { type: 'success', message: `Device detached!` })
        dispatch('getDevicesWithApp')
      })
      .catch(error => {
        commit('SET_NOTIFICATIONS', { type: 'error', message: error.response.data.message })
      })
  },

  getDevicesWithApp({ commit, state }) {
    if (!state.currentApp) return

    commit('appshell/startSectionLoading', null, { root: true })

    api.tvApps
      .getDevicesWithApp(state.currentApp.id)
      .then(response => {
        commit('SET_DEVICES_WITH_APP', response.data)
        commit('appshell/endSectionLoading', null, { root: true })
      })
      .catch(error => {
        console.error('getDevicesWithApp', error)
        commit('appshell/endSectionLoading', null, { root: true })
        commit('appshell/setDataFetchingState', true, { root: true })
      })
  },

  getAppsWithDevices({ commit, state, rootState }, payload) {
    return new Promise((resolve, reject) => {
      if (!state.currentApp) reject()

      commit('appshell/startSectionLoading', null, { root: true })

      api.tvApps
        .getAppsWithDevices(rootState.workspaces.currentWorkspace.id, payload.appType, payload.status)
        .then(response => {
          commit('SET_APP_WITH_DEVICES', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          resolve()
        })
        .catch(error => {
          console.error('getAppsWithDevices', error)
          commit('appshell/endSectionLoading', null, { root: true })
          commit('appshell/setDataFetchingState', true, { root: true })
          reject()
        })
    })
  },

  getAllRegions({ commit }) {
    return new Promise((resolve, reject) => {
      commit('appshell/startSectionLoading', null, { root: true })

      api.regions
        .getAllRegions()
        .then(response => {
          commit('SET_REGIONS', response.data)
          commit('appshell/endSectionLoading', null, { root: true })
          resolve()
        })
        .catch(error => {
          console.error('getAppsWithDevices', error)
          commit('appshell/endSectionLoading', null, { root: true })
          commit('appshell/setDataFetchingState', true, { root: true })
          reject()
        })
    })
  }
}

const getters = {
  tvApps: state => state.tvApps,
  currentApp: state => state.currentApp,
  showRemoveAppModal: state => state.showRemoveAppModal,
  deviceToRemoveFromApp: state => state.deviceToRemoveFromApp,
  notifications: state => state.notifications,
  devicesWithApp: state => state.devicesWithApp,
  saving: state => state.saving,
  publishing: state => state.publishing,
  showExportAppsModal: state => state.showExportAppsModal,
  accessCode: state => state.accessCode,
  appsWithDevices: state => state.appsWithDevices,
  regions: state => state.regions
}

export default { namespaced: true, state, getters, actions, mutations }
