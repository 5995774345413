/**
 * For more info go there: https://github.com/alexsasharegan/vue-functional-data-merge#readme
 */
import { mergeData } from 'vue-functional-data-merge'
import '@/assets/scss/_grid/_column.scss'

const sizes = ['xs-1', 'xs-2', 'xs-3', 'xs-4', 'xs-5', 'xs-6', 'xs-7', 'xs-8', 'xs-9', 'xs-10', 'xs-11', 'xs-12', 'sm-1', 'sm-2', 'sm-3', 'sm-4', 'sm-5', 'sm-6', 'sm-7', 'sm-8', 'sm-9', 'sm-10', 'sm-11', 'sm-12', 'md-1', 'md-2', 'md-3', 'md-4', 'md-5', 'md-6', 'md-7', 'md-8', 'md-9', 'md-10', 'md-11', 'md-12', 'lg-1', 'lg-2', 'lg-3', 'lg-4', 'lg-5', 'lg-6', 'lg-7', 'lg-8', 'lg-9', 'lg-10', 'lg-11', 'lg-12']

const props = {
  tag: {
    type: String,
    default: 'div'
  },
  noPadding: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'xs-12',
    validator: value => {
      const propSizes = value ? value.split(' ') : ['xs-12']
      return propSizes.some(items => sizes.includes(items))
    }
  },
  offset: {
    type: String,
    default: null,
    validator: value => {
      const propSizes = value ? value.split(' ') : null
      return propSizes.some(items => sizes.includes(items))
    }
  }
}

export default {
  name: 'Column',
  functional: true,
  props,
  render(h, { props, data, children }) {
    const sizes = props.size ? props.size.split(' ') : ['xs-12']
    const offsets = props.offset ? props.offset.split(' ') : null
    const classes = []

    sizes.forEach(item => classes.push(`bt-col--${item}`))

    if (offsets) offsets.forEach(item => classes.push(`bt-col--offset-${item}`))

    if (props.noPadding) classes.push('bt-col--no-padding')

    const componentData = {
      staticClass: 'bt-col',
      class: classes
    }

    return h(props.tag, mergeData(data, componentData), children)
  }
}
